<div class="container-fluid mt-5 mb-5">
    <div class="row">
        <div class="container">
            <div class="row">
                <h1 class="text-heading text-bold text-blue text-bigger mt-2 mb-4">Ricerca <span class="title-underliner">intorno a te</span></h1>
            </div>
        </div>
    </div>
    <div class="row">
        <widget-search-map></widget-search-map>
    </div>
</div>
