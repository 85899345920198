<div class="box-component container">
    <div *ngIf="result$ | async; else elseTemplate">
        <div class="row text-center">
            <div class="col-12 login-logo text-center">
                <img alt="Logo-Perifelicitta" src="assets/images/logo.png" />
            </div>
            <h1 class="col-12 text-success">
                <fa-icon prefix="far" icon="thumbs-up"></fa-icon>
            </h1>
            <p class="col-12 text-success">
                {{ message$ | async }}
            </p>
            <div class="col-12">
                <button type="button" class="btn btn-primary" [routerLink]="['/login']">Vai al Login</button>
            </div>
        </div>
    </div>
    <ng-template #elseTemplate>
        <div class="col-12 login-logo text-center">
            <img alt="Logo-Perifelicitta" src="assets/images/logo.png" />
        </div>
        <h1 class="col-12 text-danger">
            <fa-icon icon="exclamation-triangle"></fa-icon>
        </h1>
        <p class="col-12 text-danger">
            {{ message$ | async }}
        </p>
    </ng-template>
</div>
