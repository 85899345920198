// @angular
import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// External libs
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

// App Model
import { Campaign } from 'src/app/models/campaign.model';
import { User } from 'src/app/models/user.model';
import { CustomError } from 'src/app/models/custom-error.model';
import { UserRole } from 'src/app/models/user-role.model';

// App Services
import { BaseService } from './base.service';

@Injectable()
export class CampaignService extends BaseService {
    private campaignCache: { [id: string]: Campaign } = {};

    @Output() currentEditCampaign$: BehaviorSubject<Campaign> = new BehaviorSubject<Campaign>(null);

    constructor(private http: HttpClient) {
        super(http);
    }

    public getAll(): Observable<Campaign[]> {
        const url = `${this.baseUrl}campaign`;
        return this.http.get<Campaign[]>(url);
    }

    public getById(campaignId: string): Observable<Campaign> {
        const url = `${this.baseUrl}campaign/${campaignId}`;
        return this.http.get<Campaign>(url).pipe(
            tap((c) => {
                this.currentEditCampaign$.next(c);
            })
        );
    }

    public fetchById(campaignId: string): Observable<Campaign> {
        const url = `${this.baseUrl}campaign/${campaignId}`;
        return this.http
            .get<Campaign>(url)
            .map((c) => {
                this.currentEditCampaign$.next(c);
                this.campaignCache[c.id] = c;
                return c;
            })
            .share();
    }

    /*
    public getByUser(userid: string): Observable<Campaign[]> {
        const url = `${this.baseUrl}campaign/user/${userid}`;
        return this.http.get<Campaign[]>(url);
    }

    public update(campaign: Campaign): Observable<Campaign> {
        if (!campaign) {
			throw Observable.throw('Informazioni sulla campagna non valide');
		}
        const url = `${this.baseUrl}campaign/update`;
        return this.http.post<Campaign>(url, campaign);
    }
	*/
    public create(campaign: Campaign): Observable<Campaign> {
        if (!campaign) {
            throw Observable.throw('Informazioni sulla campagna non valide');
        }
        const url = `${this.baseUrl}campaign/create`;
        return this.http.post<Campaign>(url, campaign);
    }

    public delete(id: string): Observable<boolean> {
        if (!id) {
            throw Observable.throw('ID della campagna non valido');
        }
        const url = `${this.baseUrl}campaign/delete/${id}`;        
        return this.http.get<boolean>(url);
    }
}
