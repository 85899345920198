// @angular
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

// External libs
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

// App Helpers
import { environment } from 'src/environments/environment';
import { RolesEnum } from 'src/app/helpers/roles.enum';

// App Models
import { Company } from 'src/app/models/company.model';
import { User } from 'src/app/models/user.model';

// App Services
import { UserService } from 'src/app/services/resources/user.service';
import { CompanyService } from 'src/app/services/resources/company.service';
import { AuthService } from 'src/app/services/resources/auth.service';

@Component({
    selector: 'company-picker',
    templateUrl: './company-picker.component.html',
    styleUrls: ['./company-picker.component.css'],
})
export class CompanyPickerComponent implements OnInit, OnDestroy {
    @Input() companies: Array<Company> = [];
    isAdmin: boolean;
    currentUser: User;
    wait: boolean = false;

    // Subscriptions
    private subscriptions: Subscription = new Subscription();

    constructor(
        private router: Router,
        private userService: UserService,
        private activeModal: NgbActiveModal,
        private authService: AuthService,
        private titleService: Title,
        private companyService: CompanyService
    ) {}

    ngOnInit(): void {
        console.log('CompanyPickerComponent - ngOnInit!!');
        this.titleService.setTitle(`${environment.appName} - Scegli la cooperativa`);
        this.currentUser = this.userService.currentUser$.getValue();
        this.isAdmin = this.currentUser.roles.some((ur) => ur.role.name === RolesEnum.Administrator);
        this.wait = true;

        if (this.isAdmin) {
            const companySub = this.companyService.getAll().subscribe((companies) => {
                this.companies = companies;
                this.wait = false;
            });
            this.subscriptions.add(companySub);
        } else {
            const byUserSub = this.companyService.getByUser(this.currentUser.id).subscribe((companies) => {
                this.companies = [
                    ...new Set(
                        companies
                            .map((c) => c.id)
                            .map((id) => {
                                return companies.find((a) => a.id === id);
                            })
                    ),
                ];
                this.wait = false;
            });
            this.subscriptions.add(byUserSub);
        }
    }

    setCompanyId(id: string) {
        this.authService.setCompanyId(id);
        this.router.navigate(['area-riservata/company', id]);
        this.activeModal.close('dismiss');
    }

    setAdmin() {
        this.authService.setIsAdmin(RolesEnum.Administrator);
        this.activeModal.dismiss('');
    }

    public closeAction(reason: string) {
        this.activeModal.dismiss(reason);
    }

    filterCompany(ev, minlength) {
        const filter = ev.target.value.toUpperCase();
        const els = document.querySelectorAll<HTMLElement>('.single-coop-login-selection');
        if (filter.length >= minlength || filter == '') {
            for (let i = 0; i < els.length; i++) {
                let name = els[i].getElementsByTagName('span')[0].innerHTML;
                if (name.toUpperCase().indexOf(filter) !== -1) {
                    els[i].classList.remove('hide');
                } else {
                    els[i].classList.add('hide');
                }
            }
        }
    }

    ngOnDestroy(): void {
        console.log('CompanyPickerComponent - ngOnDestroy!');
        this.subscriptions.unsubscribe();
    }
}
