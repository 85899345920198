import { BehaviorSubject } from 'rxjs';
// @angular
import { Component, OnInit, HostListener, AfterViewInit, ChangeDetectionStrategy, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';

// App Helpers
import { slider, fader, smoothCollapse } from './helpers/animations';

// App Services
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { AuthService } from 'src/app/services/resources/auth.service';

// Aos per animazioni scroll
import * as AOS from 'aos';

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    animations: [slider, fader, smoothCollapse],
})
export class AppComponent implements OnInit {
    isLoggedIn = false;

    constructor(private navigationService: NavigationService, private authService: AuthService, private route: Router) {}

    ngOnInit() {
        this.authService.init();
        this.isLoggedIn = this.authService.isLoggedIn;

        this.authService.loginStatusUpdates.subscribe((value) => {
            this.isLoggedIn = value;
        });

        this.navigationService.init();

        AOS.init({
            delay: 50, // valori tra 0 e 3000, scatti di 50ms
            easing: 'ease-in-out', // easing delle animazioni
        });
    }

    getAnimation(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
    }
}
