<loading class="main-loading-bar"></loading>

<div class="text-light bg-dark text-center h4 d-block position-fixed d-sm-none dimension-bar">XS BLOCK</div>
<div class="text-light bg-dark text-center h4 d-none position-fixed d-sm-block d-md-none dimension-bar">SM BLOCK</div>
<div class="text-light bg-dark text-center h4 d-none position-fixed d-md-block d-lg-none dimension-bar">MD BLOCK</div>
<div class="text-light bg-dark text-center h4 d-none position-fixed d-lg-block d-xl-none dimension-bar">LG BLOCK</div>
<div class="text-light bg-dark text-center h4 d-none position-fixed d-xl-block dimension-bar">XL BLOCK</div>

<menu></menu>
<main class="position-relative main-container" [@routeAnimations]="getAnimation(outlet)">
    <router-outlet #outlet="outlet"></router-outlet>
</main>
<footer></footer>
