import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-search-widget-company',
    templateUrl: './search-widget-company.component.html',
    styleUrls: ['./search-widget-company.component.css'],
})
export class SearchWidgetCompanyComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
