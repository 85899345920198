import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { FileService } from '../services/resources/file.service';
import { Company } from './company.model';

export class CompanyDetail extends Company {
    logoBase64$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(base: Company, form: FormGroup, private fileService: FileService) {
        super(form);
        Object.assign(this, base);
        if (base.logoFileId) {
            this.fileService.getImageThumbnail(base.logoFileId).subscribe((url) => {
                this.logoBase64$.next(url);
            });
        }
    }
}
