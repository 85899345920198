export class FileUploaderConfig {
    maxFileSize: number; // Expressed in MB
    multiple: boolean;
    autoUpload: boolean;
    resetUpload: boolean;
    formatsAllowed: string[];
    showSelectButton: boolean;
    showUploadButton: boolean;
    showCancelButton: boolean;
    showProgressBar: boolean;
    permission: { cancelUpload: boolean; download: boolean; delete: boolean };
    hasDescription: boolean;

    constructor() {
        this.multiple = false;
        this.maxFileSize = 20; // Expressed in MB
        this.autoUpload = true;
        this.formatsAllowed = ['.docx', '.doc', '.xlsx', '.xls', '.pptx', '.ppt', '.rtf', '.pdf', '.txt'];
        this.showSelectButton = true;
        this.showUploadButton = true;
        this.showCancelButton = false;
        this.showProgressBar = false;
        this.resetUpload = true;
        this.permission = { cancelUpload: true, download: true, delete: true };
        this.hasDescription = false;
    }
}
