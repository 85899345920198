<div class="container-fluid bg-lightblue">
    <div class="row">
        <div class="container mt-5 pt-5 mb-5 pb-5">
            <div class="row">
                <div class="col-lg-6 order-last order-lg-first">
                    <h1 class="text-heading text-bold text-blue text-bigger mt-2 mb-4" data-aos="fade-up" data-aos-delay="100">Collaborazione Efficace</h1>
                    <p class="mt-2" data-aos="fade-up" data-aos-delay="150">Insieme.coop è il nuovo strumento per una collaborazione efficace e veloce tra le cooperative.</p>

                    <div class="shadow-default mt-3 p-3 network-infos-wrapper bg-white" data-aos="fade-up" data-aos-delay="200">
                        <p class="mt-3 mb-3 text-bold text-bigger text-blue">Accedi all’area riservata per:</p>
                        <li class="list-group-item">Descrivere i servizi e i prodotti offerti dalle cooperative</li>
                        <li class="list-group-item">Geolocalizzare al meglio le opportunità e i servizi</li>
                        <li class="list-group-item">Condividere post su temi ed argomenti d’interesse</li>
                        <li class="list-group-item">Presentare idee e nuovi progetti alla Comunità</li>

                        <button
                            type="button"
                            class="btn btn-main angle-bottom-sx shadow-default mt-4"
                            data-aos="fade-up"
                            data-aos-delay="300"
                            routerLink="/login"
                            *ngIf="!isLogged"
                        >
                            <fa-icon icon="user-circle" class="mr-3"></fa-icon>
                            <span class="text-uppercase">Area Riservata</span>
                        </button>
                    </div>
                </div>

                <div class="col-lg-6">
                    <img src="/assets/images/IllustrazioneHome_Cooperazione.svg" class="img-fluid image-description" alt="Immagine Iniziativa" data-aos="fade-down" />
                </div>
            </div>
        </div>
    </div>
</div>
