// @angular
import { Component, Input, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

// External libs
import { Subscription } from 'rxjs';

// App Helpers
import { RolesEnum } from 'src/app/helpers/roles.enum';
import { CompanyDetail } from 'src/app/models/company-detail.model';

// App Services
import { AuthService } from 'src/app/services/resources/auth.service';
import { FileService } from 'src/app/services/resources/file.service';

@Component({
    selector: 'quick-company-info',
    templateUrl: './quick-company-info.component.html',
    styleUrls: ['./quick-company-info.component.scss'],
})
export class QuickCompanyInfoComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input('companyInfo') company: CompanyDetail;
    @Input('companyId') companyId?: string;
    @Input('onlyAvatar') onlyAvatar?: boolean;

    thumbnail: any;
    showTrash: boolean = false;

    private subscription: Subscription = new Subscription();

    constructor(private fileService: FileService, private changeDetectorRef: ChangeDetectorRef, private sanitizer: DomSanitizer, private authService: AuthService) {}

    ngOnInit() {
        //console.log('QuickCompanyInfoComponent - ngOnInit!!!');
        if (this.company) {
            if (this.company.logoFileId) {
                const imageSub = this.fileService.getImageThumbnail(this.company.logoFileId).subscribe((data) => {
                    let objUrl = 'data:image/jpeg;base64,' + data;
                    this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(objUrl);
                });
                this.subscription.add(imageSub);
            }

            if (this.authService.currentUser) {
                if (this.isInRole(RolesEnum.Author) || this.isInRole(RolesEnum.Editor) || this.isInRole(RolesEnum.Cooperator) || this.isInRole(RolesEnum.Administrator)) {
                    this.showTrash = true;
                }
            } else {
                this.showTrash = false;
            }
        }
    }

    ngAfterViewInit(): void {
        //console.log('QuickCompanyInfoComponent - ngAfterViewInit!!!');
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        //console.log('QuickCompanyInfoComponent - ngOnDestroy!!!');
        this.subscription.unsubscribe();
    }

    public isInRole(role: string): boolean {
        let result = false;
        result = this.authService.currentUser.roles.some((r) => r.role.name === role);
        return result;
    }

    getImage(url: string): SafeUrl {
        if (url && url != '') {
            return this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + url);
        } else {
            return '';
        }
    }
}
