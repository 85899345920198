// @angular
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

// External libs
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// App Services
import { UserService } from 'src/app/services/resources/user.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'reset-password-modal',
    templateUrl: './reset-password-modal.component.html',
    styleUrls: ['./reset-password-modal.component.css'],
})
export class ResetPasswordModalComponent implements OnInit, OnDestroy {
    @Input() username: string;

    form: FormGroup;

    get controls() {
        return this.form.controls;
    }

    private subscription: Subscription = new Subscription();

    constructor(private userService: UserService, private activeModal: NgbActiveModal, private notification: NotificationService) {}

    ngOnInit(): void {
        console.log('ResetPasswordModalComponent ngOnInit!');

        this.form = new FormGroup({
            username: new FormControl(this.username, Validators.required, null),
        });
    }

    public confirmAction() {
        if (this.form.invalid) {
            return;
        }

        const sub = this.userService.sendResetPassword(this.form.value.username).subscribe(
            () => {
                this.notification.success('Ti è stata inviata una e-mail contenente un link per resettare la password.', 'E-mail inviata');
                this.activeModal.close();
            },
            () => {
                this.notification.error('', 'Utente non trovato');
            }
        );
        this.subscription.add(sub);
    }

    public closeAction(reason: string) {
        this.activeModal.dismiss(reason);
    }

    ngOnDestroy(): void {
        console.log('ResetPasswordModalComponent ngOnDestroy!');
        this.subscription.unsubscribe();
    }
}
