import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'initiative',
    templateUrl: './initiative.component.html',
    styleUrls: ['./initiative.component.css'],
})
export class InitiativeComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
