<div class="container-fluid">
    <!-- BANNER IN STILE NATALIZIO - Commento il codice perchè potremmo riutilizzare lo spazio banner in altre occasioni -->
    <!-- <div class="container-lg xmas-banner py-2 d-flex flex-row align-items-center justify-content-start position-relative">
        <img src="/assets/images/xmas-img.png" height="160px" class="animate__animated animate__slideInDown xmas-img animate__delay-1s" alt="">
        <img src="/assets/images/small-xmas.png" class="animate__animated animate__slideInDown small-xmas animate__delay-1s animate__slow" alt="">
        <img src="/assets/images/big-xmas.png" class="animate__animated animate__slideInDown big-xmas animate__delay-2s animate__slower" alt="">
        <div class="xmas-text text-center">
            <h2 class="text-heading text-blue animate__animated animate__slideInUp">Offerte e promozioni per il Natale disponibili nella sezione News</h2>
            <a href="/news" type="button" class="btn btn-main mt-3 xmas-btn animate__animated animate__slideInUp position-relative">
                <span class="text-uppercase pl-2 pr-2">vai alle news</span>
                <img src="/assets/images/lights-btn.png" alt="">
            </a>
        </div>
    </div> -->

    <div id="homeNewsCarousel" class="carousel slide row shadow-default" data-ride="carousel" *ngIf="news">
        <carousel class="container-fluid pr-0" style="" [isAnimated]="true" [noPause]="false" [pauseOnFocus]="true" [interval]="5000">
            <slide *ngFor="let n of news; let i = index" class="container-fluid pr-0">
                <div class="carousel-inner row ml-auto">
                    <div class="news-title shadow-default mt-n3 mt-md-0" data-aos="fade-right" data-aos-duration="600">
                        <div class="news-content">
                            <h1 class="text-heading text-bold text-blue">{{ n.title }}</h1>
                            <p>{{ n.summary }}</p>
                        </div>
                        <div class="news-info d-flex flex-row align-items-center justify-content-between mt-2">
                            <span class="news-date text-spacing text-smaller text-grey text-bold">{{ n.date | date: 'shortDate' }}</span>

                            <a [href]="n.url" target="_blank">
                                <button type="button" class="btn btn-main angle-bottom-sx">
                                    <span class="text-capitalize pl-2 pr-2">Leggi</span>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div
                        class="col-lg-7 offset-lg-5 offset-md-2 col-md-10 news-image"
                        data-aos="fade-left"
                        data-aos-delay="100"
                        data-aos-duration="600"
                        *ngIf="n.image$ | async as image; else noImage"
                        [style.backgroundImage]="image"
                    ></div>

                    <ng-template #noImage>
                        <div
                            class="offset-md-5 col-md-7 news-image"
                            data-aos="fade-left"
                            data-aos-delay="100"
                            data-aos-duration="600"
                            style="background-image: url(/assets/images/news-noimage.png)"
                        ></div>
                    </ng-template>
                </div>
            </slide>
        </carousel>
    </div>
    <div class="d-none d-md-block text-center wrapper-scroll-image animate__animated animate__fadeInUp animate__delay-1s">
        <a class="nav-link text-blue" [routerLink]="['/home']" [class.active]="true" fragment="productservices">
            <img src="/assets/images/scroll.svg" class="mt-2 mb-2" width="35px" alt="Scroll down" />
        </a>
    </div>
</div>
