// @angular
import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// External libs
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

// App Model
import { Convention } from 'src/app/models/convention.model';
import { Union } from 'src/app/models/union.model';
import { Federation } from 'src/app/models/federation.model';

// App Services
import { BaseService } from './base.service';

@Injectable()
export class ConventionService extends BaseService {
    private conventionCache: { [id: string]: Convention } = {};

    @Output() currentEditConvention$: BehaviorSubject<Convention> = new BehaviorSubject<Convention>(null);

    constructor(private http: HttpClient) {
        super(http);
    }

    public getAll(): Observable<Convention[]> {
        const url = `${this.baseUrl}convention`;
        return this.http.get<Convention[]>(url);
    }

    public getById(id: string): Observable<Convention> {
        const url = `${this.baseUrl}convention/${id}`;
        return this.http.get<Convention>(url).pipe(
            tap((c) => {
                this.currentEditConvention$.next(c);
            })
        );
    }

    public fetchById(id: string): Observable<Convention> {
        const url = `${this.baseUrl}convention/${id}`;
        return this.http
            .get<Convention>(url)
            .map((c) => {
                this.currentEditConvention$.next(c);
                this.conventionCache[c.id] = c;
                return c;
            })
            .share();
    }

    public getByCompany(companyId: string): Observable<Convention[]> {
        const url = `${this.baseUrl}convention/company/${companyId}`;
        return this.http.get<Convention[]>(url);
    }
    /*
    public getByUser(userid: string): Observable<Convention[]> {
        const url = `${this.baseUrl}convention/user/${userid}`;
        return this.http.get<Convention[]>(url);
    }
*/
    public update(convention: Convention): Observable<Convention> {
        if (!convention) {
            throw Observable.throw('Informazioni sulla convenzione non valide');
        }
        const url = `${this.baseUrl}convention/update`;
        return this.http.post<Convention>(url, convention);
    }

    public create(convention: Convention): Observable<Convention> {
        if (!convention) {
            throw Observable.throw('Informazioni sulla convenzione non valide');
        }
        const url = `${this.baseUrl}convention/create`;
        return this.http.post<Convention>(url, convention);
    }

    public getUnions(): Observable<Union[]> {
        const url = `${this.baseUrl}convention/unions`;
        return this.http.get<Union[]>(url);
    }

    public getFederations(): Observable<Federation[]> {
        const url = `${this.baseUrl}convention/federations`;
        return this.http.get<Federation[]>(url);
    }
}
