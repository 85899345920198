// @angular
import { Injectable, OnDestroy } from '@angular/core';

// External libs
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

// App Helpers
import { RolesEnum } from 'src/app/helpers/roles.enum';

// App Services
import { AuthService } from '../resources/auth.service';
import { UserService } from 'src/app/services/resources/user.service';
import { User } from 'src/app/models/user.model';
import { distinct, distinctUntilChanged, filter, take } from 'rxjs/operators';
import { CompanyPickerComponent } from 'src/app/components/company-picker/company-picker.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

export class NavigationPreferences {
    companyId: string;
}

const COMPANY_ID = 'companyId';

@Injectable()
export class NavigationService {
    currentUser: User;
    public currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    private openFilters$: BehaviorSubject<boolean>;
    private showMenu: BehaviorSubject<boolean>;
    private subscriptions: Subscription = new Subscription();

    constructor(private authService: AuthService, private modalService: NgbModal, private userService: UserService, private router: Router, private activeRoute: ActivatedRoute) {
        this.showMenu = new BehaviorSubject<boolean>(true);
        this.openFilters$ = new BehaviorSubject<boolean>(false);
    }

    public init() {
        console.log('sto chiamando init del service');
        const sub = this.userService.currentUser$.pipe(filter((u) => u != null)).subscribe((user) => {
            console.log('Login User!!!!!');

            const companyId = this.authService.getCurrentCompany();
            if (companyId) {
                this.authService.companyId$.next(companyId);
            }

            const isAdmin = this.authService.getIsAdmin();

            if (isAdmin) {
                this.authService.isAdministrator$.next(isAdmin);
            }

            if (user && !companyId && !isAdmin) {
                let rolesCompanyIds = [];
                user.roles.forEach((ur) => {
                    if (ur.companies.length) {
                        rolesCompanyIds = rolesCompanyIds.concat(ur.companies);
                        rolesCompanyIds = [...new Set(rolesCompanyIds)];
                    }
                });

                const isAdmin = user.roles.some((ur) => ur.role.name === RolesEnum.Administrator);

                if (isAdmin) {
                    console.log('sto facendo il redirect');
                    const modalRef = this.modalService.open(CompanyPickerComponent, {
                        backdrop: 'static',
                        size: 'lg',
                        keyboard: false,
                    });
                    modalRef.result.then();
                } else if (rolesCompanyIds.length > 1) {
                    console.log('sto facendo il redirect');
                    const modalRef = this.modalService.open(CompanyPickerComponent, {
                        backdrop: 'static',
                        size: 'lg',
                        keyboard: false,
                    });
                    modalRef.result.then();
                } else {
                    const companyId = rolesCompanyIds[0];
                    this.authService.setCompanyId(companyId);
                    this.router.navigate(['/area-riservata/company', companyId, 'info']);
                }
            }
        });
        this.subscriptions.add(sub);
    }

    public isAdministrator() {
        const user = this.authService.currentUser;
        return user && user.roles && user.roles.some((r) => r.role.name === RolesEnum.Administrator);
    }

    public isAuthor() {
        const user = this.authService.currentUser;
        return user && user.roles && user.roles.some((r) => r.role.name === RolesEnum.Author);
    }

    public isEditor(companyid: string) {
        const user = this.authService.currentUser;
        return user && user.roles && user.roles.some((r) => r.role.name === RolesEnum.Editor && r.companies.some((c) => c == companyid));
    }

    public isAssociate() {
        const user = this.authService.currentUser;
        return user && user.roles && user.roles.some((r) => r.role.name === RolesEnum.Associate);
    }

    public isCompanyEdit(companyid: string) {
        const user = this.authService.currentUser;
        return user && user.roles && user.roles.some((r) => r.role.name === RolesEnum.Author && r.companies.some((c) => c == companyid));
    }

    public setShowMenu(value: boolean) {
        this.showMenu.next(value);
    }

    public getOpenFilters() {
        return this.openFilters$;
    }

    public setOpenFilters(value: boolean) {
        this.openFilters$.next(value);
    }
}
