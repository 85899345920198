// @angular
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { MapInfoWindow } from '@angular/google-maps';
import { FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

// External libs
import { fromEventPattern, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import MarkerClusterer, { MarkerClustererOptions } from '@google/markerclustererplus';
import Marker = google.maps.Marker;

// App Helpers
import { environment } from 'src/environments/environment';

// App Models
import { CompanyAddress } from 'src/app/models/company-address.model';
import { Product } from 'src/app/models/product.model';
import { CompanySearch } from 'src/app/models/company-search.model';
import { Company } from 'src/app/models/company.model';

// App Services
import { NotificationService } from 'src/app/services/notification.service';
import { CompanyService } from 'src/app/services/resources/company.service';
import { ProductService } from 'src/app/services/resources/product.service';

@Component({
    selector: 'widget-search-map',
    templateUrl: './widget-search-map.component.html',
    styleUrls: ['./widget-search-map.component.scss'],
})
export class WidgetSearchMapComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;
    @ViewChild('addresstext') addressText: any;
    @Input() searchedCompanies?: Company[];

    companies: Company[];
    products: Product[];
    allSelected: { [id: number]: boolean } = {};

    data: any;

    searchForm: FormGroup;

    map: google.maps.Map;
    infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow();

    autocompleteInput: string;
    queryWait: boolean;

    searchMarker: google.maps.Marker;

    place_markers: Marker[] = [];

    place: google.maps.places.PlaceResult;

    zoom = 11;
    center: google.maps.LatLngLiteral;
    markers = [];

    private subscription: Subscription = new Subscription();

    constructor(private notification: NotificationService, private companyService: CompanyService, private productService: ProductService) {
        this.data = {};
        this.data.isAllSelected = false;
        this.data.isAllCollapsed = true;
    }

    ngOnInit() {
        console.log('WidgetSearchMapComponent - ngOnInit!!!');

        const criteria: CompanySearch = new CompanySearch(null);

        const companySub = this.companyService.searchToScroll(criteria).subscribe((companies) => {
            this.companies = companies;
            this.initMap();
        });
        this.subscription.add(companySub);

        const productSub = this.productService.getAll().subscribe((products) => {
            this.products = products;
            this.data.ParentChildchecklist = products.map((p) => {
                return {
                    id: p.id,
                    value: p.name,
                    isSelected: false,
                    isClosed: true,
                    childList: p.productServices.map((s) => {
                        return {
                            id: s.id,
                            parent_id: p.id,
                            value: s.name,
                            isSelected: s.selected,
                        };
                    }),
                };
            });
        });
        this.subscription.add(productSub);
    }

    ngAfterViewInit(): void {
        console.log('WidgetSearchMapComponent - ngAfterViewInit!!!');
        this.initMap();
    }

    ngOnDestroy(): void {
        // console.log('WidgetSearchMapComponent - ngOnDestroy!!!');
        this.subscription.unsubscribe();
    }

    public initMap(): void {
        this.map = new google.maps.Map(document.getElementById('map'), {
            center: this.center,
            zoom: this.zoom,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            maxZoom: 18,
            minZoom: 3,
            disableDoubleClickZoom: true,
            zoomControl: true,
        });

        if (!this.place) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.center = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        this.map.setCenter(this.center);
                        this.searchMarker = new google.maps.Marker({
                            position: this.center,
                            map: this.map,
                            title: '',
                            icon: '/assets/images/spotlight-poi2.png',
                        });
                    },
                    (error) => {
                        console.log('User not allow localization:', error);
                        // 41.899375,12.4975386
                        this.center = {
                            lat: environment.default_latitude,
                            lng: environment.default_longitude,
                        };
                        this.map.setZoom(11);
                        this.map.setCenter(this.center);
                        this.searchMarker = new google.maps.Marker({
                            position: this.center,
                            map: this.map,
                            title: '',
                            icon: '/assets/images/spotlight-poi2.png',
                        });
                    }
                );
            }
        } else {
            this.searchMarker = new google.maps.Marker({
                position: this.center,
                map: this.map,
                title: '',
                icon: '/assets/images/spotlight-poi2.png',
            });

            if (this.place.geometry.viewport) {
                this.map.fitBounds(this.place.geometry.viewport);
            } else {
                this.map.setCenter(this.place.geometry.location);
                this.map.setZoom(15);
            }

            this.searchMarker.setPosition(this.place.geometry.location);
        }

        const dragend = fromEventPattern(
            (handler) => {
                return google.maps.event.addListener(this.map, 'dragend', handler);
            },
            (handler, signal) => {
                return google.maps.event.removeListener(signal);
            }
        );

        let sub = dragend.pipe(debounceTime(400)).subscribe(() => this.drawMap());
        this.subscription.add(sub);

        const zoomChanged = fromEventPattern(
            (handler) => {
                console.log('addListener bounds_changed');
                return google.maps.event.addListener(this.map, 'zoom_changed', handler);
            },
            (handler, signal) => {
                console.log('removeListener bounds_changed');
                return google.maps.event.removeListener(signal);
            }
        );

        sub = zoomChanged.pipe(debounceTime(400)).subscribe(() => this.drawMap());
        this.subscription.add(sub);

        this.place_markers = [];

        this.companies?.forEach((rp) => {
            const start = performance.now();
            if (rp.companyAddresses && rp.companyAddresses.length > 0) {
                const address: CompanyAddress = rp.companyAddresses.find((ca) => ca.isMain);
                const myLatLng = new google.maps.LatLng(address.latitude, address.longitude);

                const marker = new google.maps.Marker({
                    position: myLatLng,
                    map: this.map,
                    title: rp.name,
                });

                this.place_markers = this.place_markers.concat(marker);
                console.log('placeMarker', this.place_markers);

                const openInfoWindow = fromEventPattern(
                    (handler) => {
                        return google.maps.event.addListener(marker, 'click', handler);
                    },
                    (handler, signal) => {
                        return google.maps.event.removeListener(signal);
                    }
                );

                const sub = openInfoWindow.subscribe(() => {
                    const strContent = WidgetSearchMapComponent.createMapInfoWindow(rp, address);
                    console.log(strContent);

                    this.infoWindow.setContent(strContent);
                    this.infoWindow.setOptions({ disableAutoPan: false });
                    this.infoWindow.open(this.map, marker);
                });

                this.subscription.add(sub);
            }
        });

        // with maxZoom I set when, in terms of zoom, the clusterer should stop; with gridSize I define the size of clusters
        const markerCluster = new MarkerClusterer(this.map, this.place_markers, {
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
            maxZoom: 16,
            gridSize: 80, //default is 60
        });

        const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement, {
            componentRestrictions: { country: 'IT' },
            types: ['geocode'],
        });

        const placeChanged = fromEventPattern(
            (handler) => {
                return google.maps.event.addListener(autocomplete, 'place_changed', handler);
            },
            (handler, listener) => {
                return google.maps.event.removeListener(listener);
            }
        );

        sub = placeChanged.pipe(debounceTime(400)).subscribe(() => {
            const start = performance.now();
            const geocoder = new google.maps.Geocoder();
            this.searchMarker.setVisible(false);
            this.place = autocomplete.getPlace();
            geocoder.geocode({ address: this.place.formatted_address }, (results, status) => {
                if (status === 'OK') {
                    this.searchMarker = new google.maps.Marker({
                        map: this.map,
                        icon: '/assets/images/spotlight-poi2.png',
                    });
                } else {
                    this.notification.warning('Seleziona un indirizzo tra quelli proposti', 'Ricerca Indirizzo');
                }
            });

            if (!this.place.geometry) {
                return;
            }

            if (this.place.geometry.viewport) {
                this.map.fitBounds(this.place.geometry.viewport);
            } else {
                this.map.setCenter(this.place.geometry.location);
                this.map.setZoom(15);
            }

            this.searchMarker.setPosition(this.place.geometry.location);
            this.searchMarker.setVisible(true);
        });
        this.subscription.add(sub);

        const closeInfoWindow = fromEventPattern(
            (handler) => {
                return google.maps.event.addListener(this.map, 'click', handler);
            },
            (handler, listener) => {
                return google.maps.event.removeListener(listener);
            }
        );

        sub = closeInfoWindow.subscribe(() => {
            const start = performance.now();
            this.infoWindow.close();
        });
        this.subscription.add(sub);
    }

    public drawMap(): void {
        const start = performance.now();
        const bounds = this.map.getBounds();
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
    }

    public deleteMarkers(markers: google.maps.Marker[]): void {
        markers.forEach((m) => {
            m.setVisible(false);
        });
    }

    public search(): void {
        const start = performance.now();
        const criteria: CompanySearch = new CompanySearch(null);
        const selected: any[] = this.data.ParentChildchecklist.map((x) => {
            return x.childList.filter((s) => s.isSelected);
        }).reduce((a, b) => a.concat(b));

        criteria.productServices = [];
        selected.forEach((p) => {
            criteria.productServices.push(p.id);
        });
        criteria.keyword = '';
        criteria.certificationId = null;
        criteria.employeesId = null;
        criteria.provinceId = null;
        criteria.revenueId = null;
        criteria.territoryId = null;
        criteria.sortOrder = [];
        const ricercaSub = this.companyService.searchToScroll(criteria).subscribe(
            (result) => {
                this.companies = result;
                this.initMap();
            },
            (response: HttpErrorResponse) => {
                this.notification.error(response.error.message, 'Errore nella ricerca');
            }
        );
        this.subscription.add(ricercaSub);
    }

    // Click event on parent checkbox
    parentCheck(parentObj) {
        for (let i = 0; i < parentObj.childList.length; i++) {
            parentObj.childList[i].isSelected = parentObj.isSelected;
        }
        this.search();
    }

    // Click event on child checkbox
    childCheck(parentObj, childObj) {
        parentObj.isSelected = childObj.every(function (itemChild: any) {
            return itemChild.isSelected == true;
        });
        this.search();
    }

    // Expand/Collapse event on each parent
    expandCollapse(obj) {
        obj.isClosed = !obj.isClosed;
    }

    private static createMapInfoWindow(company: Company, address: CompanyAddress): string {
        let strWindow: string;
        strWindow =
            '<div class="container">' +
            '<div class="row">' +
            '<div class="col col-12">' +
            '<a class="text-blue mb-4 text-bold" href="/company/' +
            company.id +
            '"><h4>' +
            company.name +
            '</h4></a>' +
            '<div class="row mt-3">' +
            '<div class="col col-12">' +
            '<div class="row"><div class="col-12"><span class="text-blue text-bold text-small">Indirizzo:</span>&nbsp;&nbsp;<span class="text-small">' +
            address.address +
            ' ' +
            address.zipCode +
            ' - ' +
            address.municipality.name +
            ' (' +
            address.municipality.province.abbreviation +
            ')</span></div></div>' +
            '<div class="row mt-2"><div class="col-12"><span class="text-blue text-bold text-small">Tel:</span>&nbsp;&nbsp;<span class="text-small">' +
            (company.telephone ? company.telephone : '-') +
            '</span></div></div>' +
            '<div class="row mt-2"><div class="col-12"><span class="text-blue text-bold text-small">Email:</span>&nbsp;&nbsp;<span class="text-small">' +
            (company.email ? company.email : '-') +
            '</span></div></div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>';
        return strWindow;
    }

    public searchMap(event: any): void {
        const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement, {
            componentRestrictions: { country: 'IT' },
            types: ['geocode'],
        });

        console.log(autocomplete.getPlace());
    }

    public setCenter(event: any): void {
        this.addressText.nativeElement.value = null;
        this.place = null;
        this.initMap();
    }
}
