<div class="container-fluid container-xl wrapper-risultati">
    <div class="row">
        <!-- WRAPPER RESULTS -->
        <div class="col-lg-8 mt-3 mb-5 mb-lg-0">
            <div class="row">
                <div class="col-12 mt-2 mb-3 d-flex flex-column flex-md-row align-items-center align-items-m-start justify-content-between">
                    <div>
                        <p class="text-muted mb-0 text-smaller" data-aos="fade-up">Prodotti e Servizi</p>
                        <h2 class="text-uppercase text-blue" data-aos="fade-up" data-aos-delay="150">Risultati</h2>
                    </div>
                    <div data-aos="fade-right" data-aos-delay="150" class="buttons-wrapper d-flex flex-row align-items-center justify-content-center justify-content-md-end">
                        <button
                            type="button"
                            [ngClass]="{ active: showList }"
                            class="
                                btn btn-main
                                d-flex
                                flex-row
                                align-items-center
                                justify-content-center
                                angle-bottom-sx angle-top-dx angle-bottom-dx
                                button-list
                                results-view-button
                            "
                            (click)="viewList()"
                        >
                            <fa-icon icon="th-list"></fa-icon>
                            <span class="text-uppercase text-smaller d-inline-block ml-3 d-md-none">LISTA</span>
                        </button>
                        <input id="addresstext" type="text" class="form-control text-center custom-input" #addresstext [ngClass]="{ show: showList }" />
                        <button
                            type="button"
                            [ngClass]="{ active: !showList }"
                            class="btn btn-main d-flex flex-row align-items-center justify-content-center angle-top-dx angle-top-sx angle-bottom-sx button-map results-view-button"
                            (click)="viewMap()"
                        >
                            <fa-icon icon="map-marked-alt"></fa-icon>
                            <span class="text-uppercase text-smaller d-inline-block ml-3 d-md-none">MAPPA</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 views-wrapper-results">
                    <ng-container *ngIf="wait == true || (companies && companies.length > 0); else elseTemplate">
                        <div
                            [hidden]="!showList"
                            class="results-switcher list-results search-results"
                            infinite-scroll
                            [infiniteScrollDistance]="2"
                            [infiniteScrollThrottle]="throttle"
                            (scrolled)="onScrollDown()"
                        >
                            <div
                                data-aos="fade-up"
                                data-aos-delay="100"
                                class="single-search-result shadow-default angle-bottom-sx mb-5 pl-3 pr-2 py-2 position-relative"
                                *ngFor="let c of companies"
                            >
                                <a class="cursor-pointer text-heading d-flex flex-row align-items-center justify-content-between" (click)="showCompany(c.id)">
                                    <span class="text-uppercase text-blue mb-0 text-ellipsis mw-99">{{ c.name }}</span>
                                    <fa-icon
                                        [icon]="['fas', 'angle-right']"
                                        class="p-0 pl-1 d-flex flex-row align-items-center justify-content-center single-search-result-icon btn btn-main h1 mb-0"
                                    ></fa-icon>
                                </a>
                                <div class="single-search-result-categories">
                                    <!-- {{coopService.value?.length ? coopService.value[0]?.name : 'Seleziona prodotto o servizio'}}
                                    <span *ngIf="coopService.value?.length > 1" class="example-additional-selection">
                                        (+{{coopService.value.length - 1}} {{coopService.value?.length === 2 ? 'altro' : 'altri'}} )
                                    </span> -->

                                    <ng-container *ngIf="c.productServices.length <= 3; else otherService">
                                        <span
                                            class="
                                                mr-3
                                                px-2
                                                py-1
                                                single-search-result-category
                                                bg-blue
                                                text-white text-smaller
                                                bg-blue
                                                text-uppercase
                                                angle-top-sx
                                                border-round-pill
                                            "
                                            *ngFor="let p of c.productServices"
                                        >
                                            {{ p.name }}
                                        </span>
                                    </ng-container>
                                    <ng-template #otherService>
                                        <span
                                            class="
                                                mr-3
                                                px-2
                                                py-1
                                                single-search-result-category
                                                text-ellipsis
                                                bg-blue
                                                text-white text-smaller
                                                bg-blue
                                                text-uppercase
                                                angle-top-sx
                                                border-round-pill
                                            "
                                        >
                                            {{ c.productServices[0].name }}
                                        </span>
                                        <span
                                            class="
                                                mr-3
                                                px-2
                                                py-1
                                                single-search-result-category
                                                text-ellipsis
                                                bg-blue
                                                text-white text-smaller
                                                bg-blue
                                                text-uppercase
                                                angle-top-sx
                                                border-round-pill
                                            "
                                        >
                                            {{ c.productServices[1].name }}
                                        </span>
                                        <span
                                            class="
                                                mr-3
                                                px-2
                                                py-1
                                                single-search-result-category
                                                text-ellipsis
                                                bg-blue
                                                text-white text-smaller
                                                bg-blue
                                                text-uppercase
                                                angle-top-sx
                                                border-round-pill
                                            "
                                        >
                                            {{ c.productServices[2].name }}
                                        </span>

                                        <span
                                            class="
                                                mr-3
                                                px-2
                                                py-1
                                                single-search-result-category
                                                text-ellipsis
                                                bg-blue
                                                text-white text-smaller
                                                bg-blue
                                                text-uppercase
                                                angle-top-sx
                                                border-round-pill
                                            "
                                        >
                                            + {{ c.productServices.length - 3 }}
                                        </span>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <div class="row" id="no-companies-found-list" [hidden]="!showList" data-aos="fade-up" data-aos-delay="100">
                            <div class="col-12">
                                <p class="text-center text-danger text-bigger mt-5 pt-5 w-75 mx-auto">Non sono state trovate aziende con i criteri di ricerca selezionati.</p>
                            </div>
                        </div>
                    </ng-template>
                    <div class="results-switcher maps-results" [hidden]="showList">
                        <div id="map"></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- SIDEBAR -->
        <div
            id="widget-sidebar-search-wrapper"
            class="
                col-lg-4
                order-first order-lg-last
                bg-blue
                sidebar-wrapper
                pt-3 pt-md-5
                pb-md-2
                pt-lg-4
                pb-0 pb-lg-4
                mb-5 mb-lg-0
                border-round-pill
                angle-bottom-dx angle-top-dx angle-top-sx
                shadow-default
            "
        >
            <widget-search-sidebar id="widget-sidebar-search" [criteria]="criteria" (search)="onSearch($event)"></widget-search-sidebar>
        </div>
    </div>
</div>
<loading-spinner [isLoading]="wait"></loading-spinner>
