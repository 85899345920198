<div class="container mt-5 mb-5" id="login-page">
    <h2 class="text-blue text-heading text-big text-center mt-1 mb-4">Reset Password</h2>

    <ng-container *ngIf="message">
        <hr class="mt-1" />
        <p class="text-danger text-center">{{ message }}</p>
    </ng-container>

    <form class="form col-md-6 mx-auto" role="form" autocomplete="off" novalidate="" [formGroup]="form" (ngSubmit)="onSubmit()">
        <input type="hidden" name="token" formControlName="token" />

        <div class="form-row">
            <div class="form-group col-12">
                <label for="input-old-password" class="text-green">Vecchia password</label>
                <input
                    id="input-old-password"
                    name="oldPassword"
                    formControlName="oldPassword"
                    class="form-control custom-input"
                    type="password"
                    placeholder="Vecchia password"
                    required
                    block-copy-paste
                    [ngClass]="{ 'is-invalid': controls.oldPassword.errors }"
                />
            </div>

            <div class="mt-4 form-group col-12">
                <label for="input-new-password" class="text-blue">Nuova password</label>
                <input
                    id="input-new-password"
                    name="newPassword"
                    formControlName="newPassword"
                    class="form-control custom-input"
                    type="password"
                    placeholder="Nuova password"
                    required
                    block-copy-paste
                    [ngClass]="{ 'is-invalid': controls.newPassword.errors }"
                />
            </div>

            <div class="form-group col-12">
                <label for="input-confirm-new-password" class="text-blue">Conferma password</label>
                <input
                    id="input-confirm-new-password"
                    name="confirmNewPassword"
                    formControlName="confirmNewPassword"
                    class="form-control custom-input"
                    type="password"
                    placeholder="Conferma password"
                    required
                    block-copy-paste
                    [ngClass]="{ 'is-invalid': controls.confirmNewPassword.errors }"
                />
            </div>

            <p class="text-danger text-center" *ngIf="controls.confirmNewPassword.errors?.notMatch">Attenzione: le due password non sono uguali.</p>

            <p class="pl-4 mt-4 text-bold text-grey mb-0">
                <strong>La password deve soddisfare i seguenti criteri di sicurezza:</strong>
            </p>
            <ul class="pl-5">
                <li class="text-smaller text-grey">almeno 6 caratteri</li>
                <li class="text-smaller text-grey">una lettera minuscola <span class="text-bold text-dark text-smaller">(a-z)</span></li>
                <li class="text-smaller text-grey">una lettera maiuscola <span class="text-bold text-dark text-smaller">(A-Z)</span></li>
                <li class="text-smaller text-grey">un numero <span class="text-bold text-dark text-smaller">(0-9)</span></li>
                <li class="text-smaller text-grey">
                    un carattere speciale
                    <span class="text-bold text-dark text-smaller">( ! * # @ . , - _ )</span>
                </li>
            </ul>
        </div>

        <div class="text-center mt-4">
            <button type="submit" class="btn btn-main angle-bottom-sx">
                <fa-icon icon="key" class="mr-3"></fa-icon>
                <span class="text-uppercase">CAMBIA PASSWORD</span>
            </button>
        </div>
    </form>
</div>
