<button type="button" class="btn btn-main bg-green shadow-default" (click)="openModal(template)">
    <fa-icon prefix="fa" icon="info-circle" class="d-inline-block mr-2"></fa-icon>
    <span class="text-uppercase"> Significato Ruoli </span>
</button>

<ng-template #template>
    <div id="call-modal">
        <div class="modal-header">
            <h2 class="modal-title text-heading text-blue">Significato Ruoli</h2>
            <button type="button" class="close call-close" aria-label="Close" (click)="modalRef.dismiss()">
                <span class="text-grey" aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p class="text-bigger text-blue text-center">Autore:</p>
            <div class="text-big text-grey text-center">
                <ul>
                    <li>è il proprietario della scheda cooperativa</li>
                    <li>può autorizzare (altri autori/editori/collaboratori/soci) sia sulle sue schede che sulle collaborazioni di cui la sua cooperativa è proprietaria</li>
                    <li>può creare e modificare sia la scheda che le collaborazioni per conto della cooperativa assegnata/scelta</li>
                    <li>può cancellare tutte le collaborazioni di cui la sua cooperativa è proprietaria</li>
                </ul>
            </div>

            <p class="text-bigger text-blue text-center">Collaboratore:</p>
            <div class="text-big text-grey text-center">
                <ul>
                    <li>può creare collaborazioni per conto della cooperativa assegnata/scelta</li>
                    <li>può modificare la scheda cooperativa e le collaborazioni assegnate/create</li>
                    <li>NON può assegnare/cancellare autorizzazioni a meno che non sia autore della collaborazione stessa</li>
                </ul>
            </div>

            <p class="text-bigger text-blue text-center">Editore:</p>
            <div class="text-big text-grey text-center">
                <ul>
                    <li>può creare collaborazioni per conto della cooperativa assegnata/scelta</li>
                    <li>può modificare la scheda cooperativa e le collaborazioni assegnate/create</li>
                    <li>può autorizzare (editori/collaboratori/soci) a gestire le schede cooperative assegnate e le collaborazioni assegnate/create</li>
                    <li>NON può cancellare le collaborazioni a meno che non sia autore della collaborazione stessa</li>
                </ul>
            </div>

            <p class="text-bigger text-blue text-center">Socio:</p>
            <div class="text-big text-grey text-center">
                <ul>
                    <li>può creare collaborazioni per conto della cooperativa assegnata/scelta</li>
                    <li>può modificare le collaborazioni da lui create</li>
                    <li>NON può assegnare/cancellare autorizzazioni a meno che non sia autore della collaborazione stessa</li>
                </ul>
            </div>
        </div>
    </div>
</ng-template>
