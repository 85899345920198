import { Directive, Input, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[tooltip]',
})
export class TooltipDirective implements OnInit {
    @Input('tooltip') title: string;

    constructor(private el: ElementRef) {}

    ngOnInit() {
        this.el.nativeElement.setAttribute('tooltip', this.title);
    }
}
