import { User } from './user.model';
import { Role } from './role.model';
import { FormGroup } from '@angular/forms';

export class UserRole {
    id: number;
    user: User;
    userId: string;
    role: Role;
    roleId: string;
    companies: string[];
    cooperations: string[];

    constructor(form: FormGroup) {
        if (form) {
            this.id = form.value.id;
            this.userId = form.value.userId;
            this.roleId = form.value.roleId;
        }
    }
}
