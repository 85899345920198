// @angular
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
    selector: 'loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.css'],
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {
    @Input() isLoading: boolean = false;

    ngOnInit(): void {}
    ngOnDestroy(): void {}
}
