// @angular
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

// App Models
import { Document } from 'src/app/models/document.model';

// App Services
import { AuthService } from 'src/app/services/resources/auth.service';
@Component({
    selector: 'document-item',
    templateUrl: './document-item.component.html',
    styleUrls: ['./document-item.component.css'],
})
export class DocumentItemComponent implements OnInit {
    @Input() document: Document;
    viewMore = false;

    constructor(private router: Router, private authService: AuthService) {}

    ngOnInit() {}

    public toggleCardInfo($event: MouseEvent) {
        $event.stopImmediatePropagation();
        this.viewMore = !this.viewMore;
    }

    public download($event: MouseEvent): void {
        $event.stopImmediatePropagation();
        //this.documentService.download(this.document.ID, this.document.file.name);
    }

    public share($event: MouseEvent): void {
        $event.stopImmediatePropagation();
        this.router.navigate(['/documents/edit', this.document.ID, 'users']);
    }

    public isOwner(document: Document): boolean {
        return null; //this.documentService.checkPermission(document);
    }
}
