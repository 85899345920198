<div class="container-fluid bg-blue" id="contacts">
    <div class="row">
        <div class="container mt-5 pt-5 mb-5 pb-5">
            <div class="row" data-aos="fade-up" data-aos-delay="200">
                <div class="col-lg-10">
                    <div class="row">
                        <!-- CONTATTI -->
                        <div class="col-lg-5 footer-contacts">
                            <p class="text-white text-bold mb-2 text-uppercase">Contatti</p>
                            <ul class="list-unstyled">
                                <li><span class="text-white d-inline-block mr-3"> tel. </span> <a class="text-white" href="tel:06112233">+39 035 285511</a></li>
                                <li><span class="text-white d-inline-block mr-3"> mail </span> <a class="text-white" href="mailto:bergamo@confcooperative.it">bergamo@confcooperative.it</a></li>
                            </ul>
                        </div>

                        <!-- ESPLORA MOBILE -->
                        <div class="footer-explore mt-5 mobile d-block d-lg-none px-3">
                            <p class="text-white text-bold mb-2 text-uppercase">Esplora</p>
                            <ul class="ml-4">
                                <li class="text-white"><a [routerLink]="['/home']">Home</a></li>
<!--                                <li><a (click)="smoothScrollFooter('news')" [routerLink]="['/home']" fragment="news">News</a></li>-->
<!--                                <li><a (click)="smoothScrollFooter('productservices')" [routerLink]="['/home']" fragment="productservices">Prodotti e servizi</a></li>-->
<!--                                <li><a (click)="smoothScrollFooter('initiative')" [routerLink]="['/home']" fragment="initiative">L'iniziativa</a></li>-->
<!--                                <li><a (click)="smoothScrollFooter('aroundme')" [routerLink]="['/home']" fragment="aroundme">Intorno a me</a></li>-->
<!--                                <li><a (click)="smoothScrollFooter('network')" [routerLink]="['/home']" fragment="network">La Rete</a></li>-->
                                <li class="text-white"><a (click)="smoothScrollFooter('contacts')" [routerLink]="['/home']" fragment="contacts">Contatti</a></li>
                            </ul>
                        </div>

                        <!-- LOGO FOOTER -->
                        <div class="col-lg-7 order-first order-lg-last mb-4 mb-md-1 text-center d-flex flex-column flex-lg-row justify-content-around  footer-logo">
                          <a class="mb-5" href="https://www.bergamo.confcooperative.it/" target="_blank"><img src="assets/images/Logo_CCI_Bergamo_web_BIANCO.svg" height="50px" alt="Logo Bergamo" /></a>
                          <a href="https://www.brescia.confcooperative.it/" target="_blank"><img src="assets/images/Logo_CCI_Brescia_web_BIANCO.svg" height="50px" alt="Logo Brescia" /></a>

                        </div>
                    </div>

                    <!-- DISCLAIMER -->
                    <div class="col-12 pr-0 pl-0 mt-5 footer-copyright text-center text-lg-left text-white">
                        <p class="mb-2 text-white text-small">Copyright 2022 © Tutti i diritti sono riservati</p>
                        <p class="mb-2">
                            <a class="d-inline-block mr-3 text-white" href="https://www.iubenda.com/privacy-policy/94157452"> Privacy </a>|
                            <a class="d-inline-block mr-3 text-white" href="https://www.iubenda.com/privacy-policy/94157452/cookie-policy"> Cookie </a>|

                            <a class="d-inline-block mr-3 ml-3 text-white" href="https://www.node.coop" target="_blank"> Credits </a> |
                            <ng-container *ngIf="isLoggedIn; else elseTemplate">
                                <a class="d-inline-block mr-3 ml-3 cursor-pointer text-white" (click)="logOut()"> Logout </a>
                            </ng-container>
                            <ng-template #elseTemplate>
                                <a class="d-inline-block mr-3 ml-3 text-white" [routerLink]="['/login']" routerLinkActive="active"> Login </a>
                            </ng-template>
                        </p>
                    </div>
                    <div *ngIf="isIE">
                        <p class="mb-2 text-grey text-small">
                            ATTENZIONE: stai utilizzando Internet Explorer. È consigliabile utilizzare browser moderni come Firefox, Chrome o Edge per utilizzare al meglio le
                            funzionalità del sito.
                        </p>
                    </div>
                </div>

                <!-- ESPLORA DESKTOP -->
                <div class="col-lg-2 footer-explore desktop d-none d-lg-block">
                    <div class="d-flex ml-4">
                        <div class="ml-auto mr-0 d-flex flex-column">
                            <p class="text-white text-uppercase text-bold mb-2 d-flex flex-column">Esplora</p>
                            <ul class="ml-4">
                                <li><a class="text-white" [routerLink]="['/home']">Home</a></li>
<!--                                <li><a (click)="smoothScrollFooter('news')" [routerLink]="['/news']">News</a></li>-->
<!--                                <li><a (click)="smoothScrollFooter('productservices')" [routerLink]="['/home']" fragment="productservices">Prodotti e servizi</a></li>-->
<!--                                <li><a (click)="smoothScrollFooter('initiative')" [routerLink]="['/home']" fragment="initiative">L'iniziativa</a></li>-->
<!--                                <li><a (click)="smoothScrollFooter('aroundme')" [routerLink]="['/home']" fragment="aroundme">Intorno a me</a></li>-->
<!--                                <li><a (click)="smoothScrollFooter('network')" [routerLink]="['/home']" fragment="network">La Rete</a></li>-->
                                <li><a class="text-white" (click)="smoothScrollFooter('contacts')" [routerLink]="['/home']" fragment="contacts">Contatti</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
