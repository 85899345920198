import { Guid } from 'guid-typescript';

import { User } from './user.model';

export class FileInfo implements File {
    ID: number;

    guid: string;
    name: string;
    extension: string;
    mimeType: string;
    size: number;
    lastModified: number;
    interface: File;

    created: Date;
    createdBy: string;
    createdByUser: User;

    type: string;

    constructor() {
        this.guid = Guid.create().toString();
    }
    arrayBuffer(): Promise<ArrayBuffer> {
        throw new Error('Method not implemented.');
    }
    stream(): ReadableStream<any> {
        throw new Error('Method not implemented.');
    }
    text(): Promise<string> {
        throw new Error('Method not implemented.');
    }

    slice(start?: number, end?: number, contentType?: string): Blob {
        throw new Error('Method not implemented.');
    }

    from(obj: any) {
        Object.assign(this, obj);
        return this;
    }

    get icon(): string {
        const type = this.type || this.mimeType;
        let icon = 'file';
        switch (type) {
            case 'application/pdf':
                icon = 'file-pdf';
                break;
            case 'image/jpeg':
            case 'image/jpg':
            case 'image/png':
            case 'image/gif':
            case 'image/tiff':
            case 'image/svg+xml':
            case 'image/webp':
                icon = 'file-image';
                break;
            case 'application/zip':
            case 'application/x-rar-compressed':
            case 'application/x-7z-compressed':
            case 'application/gzip':
            case 'application/x-bzip':
            case 'application/x-bzip2':
            case 'application/x-freearc':
                icon = 'file-archive';
                break;
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/vnd.oasis.opendocument.text':
            case 'application/x-abiword':
                icon = 'file-word';
                break;
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.oasis.opendocument.spreadsheet':
            case 'text/csv':
                icon = 'file-excel';
                break;
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            case 'application/vnd.oasis.opendocument.presentation':
                icon = 'file-powerpoint';
                break;
            case 'application/rtf':
            case 'text/plain':
                icon = 'file-alt';
                break;
            case 'video/x-msvideo':
            case 'video/mpeg':
            case 'video/ogg':
            case 'video/mp2t':
            case 'video/webm':
            case 'video/3gpp':
            case 'video/3gpp2':
                icon = 'file-video';
                break;
            case 'audio/aac':
            case 'audio/mpeg':
            case 'audio/ogg':
            case 'audio/wav':
            case 'audio/webm':
            case 'audio/3gpp':
            case 'audio/3gpp2':
                icon = 'file-audio';
                break;
            default:
                break;
        }

        return icon;
    }
}
