// @angular
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationExtras, UrlTree } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';

// External libs
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// App Helpers
import { environment } from 'src/environments/environment';

// App Services
import { AuthService, AuthCredentials } from 'src/app/services/resources/auth.service';

// App Components
import { ResetPasswordModalComponent } from '../reset-password-modal/reset-password-modal.component';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
    message: string;
    description: string;
    authForm: FormGroup;
    passwordFieldType: 'text' | 'password' = 'password';

    private redirectUrl: UrlTree | string;

    // Subscriptions
    private subscriptions: Subscription = new Subscription();

    constructor(private router: Router, private titleService: Title, private modalService: NgbModal, private authService: AuthService) {
        this.redirectUrl = this.authService.redirectUrl ? this.router.parseUrl(this.authService.redirectUrl) : '/';
    }

    ngOnInit(): void {
        this.authForm = new FormGroup({
            username: new FormControl(null, Validators.required, null),
            password: new FormControl(null, Validators.required, null),
            isPersistent: new FormControl(true, [Validators.nullValidator], null),
        });

        this.titleService.setTitle(`${environment.appName} - Login`);

        const sub = this.authService.loginStatusUpdates.subscribe((login) => {
            if (login) {
                // Set our navigation extras object
                // that passes on our global query params and fragment
                const navigationExtras: NavigationExtras = {
                    queryParamsHandling: 'preserve',
                    preserveFragment: true,
                };
            }
        });
        this.subscriptions.add(sub);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public toggleShowPassword(): void {
        if (this.passwordFieldType === 'password') {
            this.passwordFieldType = 'text';
        } else {
            this.passwordFieldType = 'password';
        }
    }

    public resetPassword(): void {
        this.message = '';
        this.description = '';

        const modalRef = this.modalService.open(ResetPasswordModalComponent);
        modalRef.componentInstance.username = this.authForm.value.username;
        modalRef.result.then(
            () => {},
            (reason) => {
                console.log(`Dismissed: ${reason}`);
            }
        );
    }

    public login(): void {
        this.message = '';
        this.description = '';
        // tslint:disable-next-line: max-line-length
        const credentials = new AuthCredentials(this.authForm.value.username, this.authForm.value.password, this.authForm.value.isPersistent);

        const sub = this.authService.login(credentials).subscribe(
            () => {
                console.log('AuthService Login!!!!!');
                this.authService.setModalitaSito('private');
                console.log('Commento prima della subscribe utente');
            },

            (reason: HttpErrorResponse) => {
                this.message = 'Impossibile effettuare il login.';
                if (reason.error.hasOwnProperty('error_description')) {
                    this.description = `${reason.error.error_description}`;
                }
            }
        );
        this.subscriptions.add(sub);
    }
}
