// @angular
import { ModuleWithProviders, NgModule } from '@angular/core';

// App Services
import { ErrorsService } from './navigation/errors.service';
import { NavigationService } from './navigation/navigation.service';
import { NotificationService } from './notification.service';
import { AuthService } from './resources/auth.service';
import { CertificationService } from './resources/certification.service';
import { CompanyService } from './resources/company.service';
import { FileService } from './resources/file.service';
import { MunicipalityService } from './resources/municipality.service';
import { ProductService } from './resources/product.service';
import { ProductServiceService } from './resources/productservice.service';
import { ProvinceService } from './resources/province.service';
import { RegionService } from './resources/region.service';
import { RoleService } from './resources/role.service';
import { UserService } from './resources/user.service';
import { TagService } from './resources/tag.service';
import { TerritoryService } from './resources/territory.service';
import { ConsortiumService } from './resources/consortium.service';
import { CooperationService } from './resources/cooperation.service';
import { EmployeesService } from './resources/employees.service';
import { RevenueeService } from './resources/revenuee.service';
import { CampaignService } from './resources/campaign.service';
import { CampaignMasksService } from './resources/campaign-masks.service';
import { CompanyRequestMasksService } from './resources/company-request-masks.service';
import { ConventionService } from './resources/convention.service';
import { ConventionRequestService } from './resources/convention-request.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [],
})
export class ServicesModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ServicesModule,
            providers: [
                NavigationService,
                ErrorsService,
                AuthService,
                NotificationService,
                UserService,
                RoleService,
                RegionService,
                ProvinceService,
                MunicipalityService,
                ProductServiceService,
                CertificationService,
                ProductService,
                CompanyService,
                FileService,
                TagService,
                TerritoryService,
                ConsortiumService,
                CooperationService,
                EmployeesService,
                RevenueeService,
                CampaignService,
                CampaignMasksService,
                CompanyRequestMasksService,
                ConventionService,
                ConventionRequestService
            ],
        };
    }
}
