// @angular
import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// External libs
import { Observable } from 'rxjs';

// App Models
import { Product } from 'src/app/models/product.model';

// App Services
import { BaseService } from './base.service';

@Injectable()
export class ProductService extends BaseService {
    constructor(private http: HttpClient) {
        super(http);
    }

    public getAll(): Observable<Product[]> {
        const url = `${this.baseUrl}product`;
        return this.http.get<Product[]>(url);
    }

    public getById(id: number): Observable<Product> {
        const url = `${this.baseUrl}product/${id}`;
        return this.http.get<Product>(url);
    }
}
