// @angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, CanActivateChild, Router, RouterStateSnapshot, NavigationExtras, Route } from '@angular/router';

// App Services
import { AuthService } from 'src/app/services/resources/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        console.log('canActivate: ', route, state);
        const url: string = state.url;

        const check = this.checkRoles(route, url);
        if (!check) {
        }

        return check;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        console.log('canActivateChild: ', route, state);
        return this.canActivate(route, state);
    }

    canLoad(route: Route): boolean {
        console.log('canLoad: ', route);
        const url = `/${route.path}`;

        return this.checkLogin(url);
    }

    checkLogin(url: string): boolean {
        if (this.authService.isLoggedIn) {
            return true;
        }

        // Store the attempted URL for redirecting
        this.authService.redirectUrl = url;

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {};

        // Navigate to the login page with extras
        this.router.navigate(['/login'], navigationExtras);
        return false;
    }

    checkRoles(route: ActivatedRouteSnapshot, url: string): boolean {
        console.log('this.authService.isLoggedIn: ', this.authService.isLoggedIn);
        if (this.authService.isLoggedIn) {
            const user = this.authService.currentUser;
            console.log('user: ', user);
            if (route.data.roles instanceof Array) {
                console.log('route.data.roles: ', route.data.roles);
                const dataRoles = route.data.roles as string[];
                console.log('dataRoles: ', dataRoles);
                if (!dataRoles.some((r) => user.roles.some((ur) => ur.role.name === r))) {
                    console.log('RUOLO NON TROVATO!!');
                    return false;
                }
            }

            return true;
        }

        // Store the attempted URL for redirecting
        this.authService.redirectUrl = url;

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {};

        // Navigate to the login page with extras
        this.router.navigate(['/home'], navigationExtras);
        return false;
    }
}
