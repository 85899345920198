import { FormGroup } from '@angular/forms';

import { User } from './user.model';
import { FileInfo } from './file-info.model';

export class Document {
    ID: number;
    fileID: number;
    file: FileInfo;
    name: string;
    description: string;

    created: Date;
    createdBy: string;
    createdByUser: User;

    constructor(form: FormGroup) {
        if (form) {
            this.ID = form.value.ID;
            this.fileID = form.value.fileID;
            this.name = form.value.name;
            this.description = form.value.description;
        }
    }
}
