import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/resources/user.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'network',
    templateUrl: './network.component.html',
    styleUrls: ['./network.component.css'],
})
export class NetworkComponent implements OnInit, OnDestroy {
    isLogged: boolean;
    private subscription: Subscription = new Subscription();

    constructor(private userService: UserService) {}

    ngOnInit(): void {
        console.log('NetworkComponent ngOnInit!');
        const sub = this.userService.currentUser$.asObservable().subscribe((value) => {
            if (value) this.isLogged = true;
        });
        this.subscription.add(sub);
    }

    ngOnDestroy(): void {
        console.log('NetworkComponent ngOnDestroy!');
        this.subscription.unsubscribe();
    }
}
