// @angular
import { HttpClient } from '@angular/common/http';
import { Injectable, Output } from '@angular/core';

// External libs
import { BehaviorSubject, Observable } from 'rxjs';

// App Models
import { Cooperation } from 'src/app/models/cooperation.model';
import { CooperationFilter } from 'src/app/models/cooperation-filter';
import { CooperationService as CooperationProductService } from 'src/app/models/cooperation-service.model';

// App Services
import { BaseService } from './base.service';
import { tap } from 'rxjs/operators';
import { GenericPager } from 'src/app/models/generic-pager.model';
import { CooperationApprove } from 'src/app/models/cooperation-approve.model';
import { CooperationQuerySize } from 'src/app/models/cooperation-query-size.model';
import { CooperationCompany } from 'src/app/models/cooperation-company.model';
import { UserRole } from 'src/app/models/user-role.model';
import { CooperationList } from 'src/app/models/cooperation-list.model';
import { CooperationDelete } from 'src/app/models/cooperation-delete.model';
import { Company } from 'src/app/models/company.model';

@Injectable()
export class CooperationService extends BaseService {
    @Output() currentEditCooperation$: BehaviorSubject<Cooperation> = new BehaviorSubject(null);

    constructor(private http: HttpClient) {
        super(http);
    }

    public getById(id: string): Observable<Cooperation> {
        const url = `${this.baseUrl}cooperation/${id}`;
        return this.http.get<Cooperation>(url).pipe(
            tap((cooperation) => {
                this.currentEditCooperation$.next(cooperation);
            })
        );
    }

    public getAll(): Observable<Cooperation[]> {
        const url = `${this.baseUrl}cooperation`;
        return this.http.get<Cooperation[]>(url);
    }

    public getModeratingCoop(query: CooperationQuerySize): Observable<GenericPager<Cooperation>> {
        return this.http.post<GenericPager<Cooperation>>(this.baseUrl + 'cooperation/moderating', query);
    }

    public create(coop: Cooperation) {
        return this.http.post<Cooperation>(this.baseUrl + 'cooperation/create', coop);
    }

    public editCooperation(coop: Cooperation) {
        return this.http.post<Cooperation>(this.baseUrl + 'cooperation/edit/' + coop.id, coop);
    }

    public GetAllByRoles(query: CooperationList): Observable<Cooperation[]> {
        const url = `${this.baseUrl}cooperation/all`;
        return this.http.post<Cooperation[]>(url, query);
    }

    public search(filter: CooperationFilter) {
        return this.http.post<Array<Cooperation>>(this.baseUrl + 'cooperation/search', filter);
    }

    public approve(coopData: CooperationApprove) {
        return this.http.post<any>(this.baseUrl + 'cooperation/approve', coopData);
    }

    public getPartnerCompanies(cooperationid: string): Observable<CooperationCompany[]> {
        const url = `${this.baseUrl}cooperation/${cooperationid}/partnercompanies`;
        return this.http.get<CooperationCompany[]>(url);
    }

    public updatePartnerCompanies(cooperationid: string, cooperationCompanies: Company[]): Observable<CooperationCompany[]> {
        const url = `${this.baseUrl}cooperation/${cooperationid}/partnercompany`;
        return this.http.post<CooperationCompany[]>(url, cooperationCompanies);
    }

    public getProductServices(cooperationid: string): Observable<CooperationProductService[]> {
        const url = `${this.baseUrl}cooperation/${cooperationid}/productservices`;
        return this.http.get<CooperationProductService[]>(url);
    }

    public getUsers(cooperationid: string): Observable<UserRole[]> {
        const url = `${this.baseUrl}cooperation/${cooperationid}/users`;
        return this.http.get<UserRole[]>(url);
    }

    public updateUsers(cooperationid: string, users: UserRole[]): Observable<UserRole[]> {
        const url = `${this.baseUrl}cooperation/${cooperationid}/users`;
        return this.http.post<UserRole[]>(url, users);
    }

    public sendForApproval(coop: Cooperation): Observable<any> {
        const url = `${this.baseUrl}cooperation/sendForApproval`;
        return this.http.post(url, coop);
    }

    public deleteCooperation(coopDelete: CooperationDelete): Observable<any> {
        const url = `${this.baseUrl}cooperation/delete`;
        return this.http.post(url, coopDelete);
    }
}
