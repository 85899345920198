<div class="container-fluid container-lg mt-5 mb-5">
    <!-- TITOLO -->
    <h1 class="text-heading text-bold text-blue text-bigger mb-4 text-left text-lg-center">Dashboard delle collaborazioni</h1>

    <!-- CONTAINER COLLABORAZIONI -->
    <div class="cooperation-dashboard-results row" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
        <!-- SINGOLA COLLABORAZIONE -->
        <ng-container *ngIf="moderatingCooperation.length; else noResult">
            <div class="col-md-6 col-lg-4 mb-4 single-cooperation" *ngFor="let coop of moderatingCooperation">
                <div class="d-flex flex-row align-items-center shadow-default border-round-pill angle-bottom-sx py-2 px-3 h-100">
                    <!-- TIPOLOGIA COOPERATION -->
                    <div class="typology-image h-100" [ngStyle]="{ backgroundImage: 'url(/assets/images/cooperations/' + cooperationTypeImage[coop.typeId] + '.svg' }"></div>

                    <!-- COOPERATION CONTENT -->
                    <div class="content-cooperation-wrapper pl-3 d-flex flex-column align-items-start justify-content-between h-100">
                        <a [routerLink]="['/area-riservata/cooperations/' + coop.id + '/info']">
                            <div class="text-info-cooperation-wrapper">
                                <p class="text-blue text-bold text-big mb-2">{{ coop.title }}</p>
                                <p class="text-grey text-smaller mb-0" [innerHTML]="minimizeDescription(coop.description)"></p>
                            </div>
                        </a>
                        <button type="button" class="btn btn-main d-inline-block mt-4 mb-0" (click)="approve(coop.id)">
                            <fa-icon prefix="fa" class="d-inline-block mr-2" icon="check"></fa-icon>
                            <span class="text-uppercase text-smaller">Approva</span>
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #noResult>
            <div class="col-12 text-center align-content-center">
                <h3 class="text-heading text-bold text-blue mb-3 text-center">Non ci sono collaborazioni da approvare.</h3>
            </div>
        </ng-template>
    </div>
</div>
