// @angular
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';

// Exrternal libs
import { fromEventPattern, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import MarkerClusterer from '@google/markerclustererplus';
import Marker = google.maps.Marker;

// App Helpers
import { environment } from 'src/environments/environment';

// App Modules
import { Company } from 'src/app/models/company.model';
import { CompanySearch } from 'src/app/models/company-search.model';
import { CompanyAddress } from 'src/app/models/company-address.model';

// App Services
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/resources/auth.service';
import { CompanyService } from 'src/app/services/resources/company.service';
import { ProductService } from 'src/app/services/resources/product.service';
import { PagerParams } from 'src/app/models/pager-params.model';

@Component({
    selector: 'company-result',
    templateUrl: './company-result.component.html',
    styleUrls: ['./company-result.component.scss'],
})
export class CompanyResultComponent implements OnInit, OnDestroy {
    @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;
    @ViewChild('addresstext') addressText: any;

    array: any = [];
    companies: Company[] = [];
    sum: number = 0;
    throttle = 1000;

    pageParams: PagerParams = new PagerParams();

    productServiceId: number;
    isPublic: Boolean;
    showList: boolean = true;

    criteria: CompanySearch;

    autocompleteInput: string;
    queryWait: boolean;

    searchMarker: google.maps.Marker;
    place_markers: Marker[] = [];
    place: google.maps.places.PlaceResult;

    map: google.maps.Map;
    infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow();

    zoom = 8;
    center: google.maps.LatLngLiteral;

    totalOccurrence: number;
    wait: boolean = false;

    private subscription: Subscription = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private titleService: Title,
        private notification: NotificationService,
        public companyService: CompanyService,
        private productService: ProductService
    ) {}

    ngOnInit(): void {
        console.log('CompanyResultComponent - ngOnInit!!!');
        const modeSite = this.authService.getModalitaSito();
        this.isPublic = modeSite != 'private';
        this.wait = true;

        const routesub = this.route.url.subscribe((url) => {
            console.log('Url:', url);
            if (url && url.length > 0) {
                if (url[0].path == 'productservice') {
                    console.log('Servizio: ', url[1].path);
                    this.productServiceId = +url[1].path;
                    this.createInstanceCompanySearch();
                    this.criteria.productServices.push(this.productServiceId);
                    console.log('SearchParams:', this.criteria);
                    this.searchCompanyPaginated();
                } else if (url[0].path == 'product') {
                    console.log('Prodotto: ', url[1].path);
                    let productId: number = +url[1].path;
                    const productSub = this.productService.getById(productId).subscribe((product) => {
                        this.createInstanceCompanySearch();
                        this.criteria.productServices = product.productServices.map((list) => list.id);
                        console.log('SearchParams:', this.criteria);
                        this.searchCompanyPaginated();
                    });
                    this.subscription.add(productSub);
                }
            } else {
                console.log('Ricerca tramite form.');

                this.criteria = this.companyService.currentCriteria$.getValue();
                if (!this.criteria) {
                    this.createInstanceCompanySearch();
                }
                const sub = this.companyService.searchToTable(this.pageParams, this.criteria).subscribe((data) => {
                    this.totalOccurrence = data.totalRows;
                    this.companies = data.rows;
                    this.wait = false;
                });
                this.subscription.add(sub);
            }

            this.initMap();
        });
        this.subscription.add(routesub);

        this.titleService.setTitle(`${environment.appName} - Cooperative Ricerca Risultati`);
    }

    ngOnDestroy(): void {
        console.log('CompanyResultComponent - ngOnDestroy!!!');
        this.subscription.unsubscribe();
    }

    onScrollDown() {
        if (this.pageParams.pageSize < this.totalOccurrence) {
            this.pageParams.pageSize += this.pageParams.pageSize;
            this.searchCompanyPaginated();
        }
    }

    createInstanceCompanySearch() {
        this.criteria = new CompanySearch(null);
        this.criteria.productServices = [];
        this.criteria.certificationId = null;
        this.criteria.keyword = null;
        this.criteria.employeesId = null;
        this.criteria.id = null;
        this.criteria.provinceId = null;
        this.criteria.revenueId = null;
        this.criteria.territoryId = null;
        this.criteria.sortOrder = null;
    }

    searchCompanyPaginated() {
        const sub = this.companyService.searchToTable(this.pageParams, this.criteria).subscribe((data) => {
            this.totalOccurrence = data.totalRows;
            this.companies = data.rows;
            this.wait = false;
        });
        this.subscription.add(sub);
    }

    showCompany(companyId: string) {
        if (companyId) {
            if (!this.isPublic) {
                this.router.navigate(['/area-riservata/company', companyId]);
            } else {
                this.router.navigate(['/company', companyId]);
            }
        } else {
            this.notification.error('Azienda Id non valido.', 'Errore Azienda dettaglio');
        }
    }

    viewList() {
        this.addressText.nativeElement.value = '';
        this.searchMarker = null;
        this.showList = true;
    }

    viewMap() {
        this.addressText.nativeElement.value = '';
        this.searchMarker = null;
        this.showList = false;
        this.initMap();
    }

    public initMap(): void {
        this.map = new google.maps.Map(document.getElementById('map'), {
            center: this.center,
            zoom: this.zoom,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            maxZoom: 18,
            minZoom: 3,
            disableDoubleClickZoom: true,
            zoomControl: true,
        });

        if (!this.place) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.center = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        this.map.setCenter(this.center);
                        this.searchMarker = new google.maps.Marker({
                            position: this.center,
                            map: this.map,
                            title: '',
                            icon: '/assets/images/spotlight-poi2.png',
                        });
                    },
                    (error) => {
                        console.log('User not allow localization:', error);
                        // 41.899375,12.4975386
                        this.center = {
                            lat: environment.default_latitude,
                            lng: environment.default_longitude,
                        };
                        this.map.setZoom(11);
                        this.map.setCenter(this.center);
                        this.searchMarker = new google.maps.Marker({
                            position: this.center,
                            map: this.map,
                            title: '',
                            icon: '/assets/images/spotlight-poi2.png',
                        });
                    }
                );
            }
        } else {
            this.searchMarker = new google.maps.Marker({
                position: this.center,
                map: this.map,
                title: '',
                icon: '/assets/images/spotlight-poi2.png',
            });

            if (this.place.geometry.viewport) {
                this.map.fitBounds(this.place.geometry.viewport);
            } else {
                this.map.setCenter(this.place.geometry.location);
                this.map.setZoom(15);
            }

            this.searchMarker.setPosition(this.place.geometry.location);
        }

        const centerControlDiv = document.createElement('div');
        this.centerControl(centerControlDiv, this.map);

        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(centerControlDiv);

        const dragend = fromEventPattern(
            (handler) => {
                return google.maps.event.addListener(this.map, 'dragend', handler);
            },
            (handler, signal) => {
                return google.maps.event.removeListener(signal);
            }
        );

        let sub = dragend.pipe(debounceTime(400)).subscribe(() => this.drawMap());
        this.subscription.add(sub);

        const zoomChanged = fromEventPattern(
            (handler) => {
                return google.maps.event.addListener(this.map, 'zoom_changed', handler);
            },
            (handler, signal) => {
                return google.maps.event.removeListener(signal);
            }
        );

        sub = zoomChanged.pipe(debounceTime(400)).subscribe(() => this.drawMap());
        this.subscription.add(sub);

        this.place_markers = [];

        this.companies.forEach((rp) => {
            const start = performance.now();
            if (rp.companyAddresses && rp.companyAddresses.length > 0) {
                const address: CompanyAddress = rp.companyAddresses.find((ca) => ca.isMain);
                const myLatLng = new google.maps.LatLng(address.latitude, address.longitude);

                const marker = new google.maps.Marker({
                    position: myLatLng,
                    map: this.map,
                    title: rp.name,
                });

                this.place_markers = this.place_markers.concat(marker);

                const openInfoWindow = fromEventPattern(
                    (handler) => {
                        return google.maps.event.addListener(marker, 'click', handler);
                    },
                    (handler, signal) => {
                        return google.maps.event.removeListener(signal);
                    }
                );

                const sub = openInfoWindow.subscribe(() => {
                    const strContent = this.createMapInfoWindow(rp, address);

                    this.infoWindow.setContent(strContent);
                    this.infoWindow.setOptions({ disableAutoPan: false });
                    this.infoWindow.open(this.map, marker);
                });

                this.subscription.add(sub);
            }
        });

        const markerCluster = new MarkerClusterer(this.map, this.place_markers, {
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
            maxZoom: 16,
            gridSize: 80, //default is 60
        });

        const autocomplete = new google.maps.places.Autocomplete(this.addressText?.nativeElement, {
            componentRestrictions: { country: 'IT' },
            types: ['geocode'],
        });

        const placeChanged = fromEventPattern(
            (handler) => {
                return google.maps.event.addListener(autocomplete, 'place_changed', handler);
            },
            (handler, listener) => {
                return google.maps.event.removeListener(listener);
            }
        );

        sub = placeChanged.pipe(debounceTime(400)).subscribe(() => {
            const start = performance.now();
            const geocoder = new google.maps.Geocoder();
            this.searchMarker.setVisible(false);
            this.place = autocomplete.getPlace();
            geocoder.geocode({ address: this.place.formatted_address }, (results, status) => {
                if (status === 'OK') {
                    this.searchMarker = new google.maps.Marker({
                        map: this.map,
                        icon: '/assets/images/spotlight-poi2.png',
                    });
                } else {
                    this.notification.warning('Seleziona un indirizzo tra quelli proposti', 'Ricerca Indirizzo');
                }
            });

            if (!this.place.geometry) {
                return;
            }

            if (this.place.geometry.viewport) {
                this.map.fitBounds(this.place.geometry.viewport);
            } else {
                this.map.setCenter(this.place.geometry.location);
                this.map.setZoom(15);
            }

            this.searchMarker.setPosition(this.place.geometry.location);
            this.searchMarker.setVisible(true);
        });
        this.subscription.add(sub);

        const closeInfoWindow = fromEventPattern(
            (handler) => {
                return google.maps.event.addListener(this.map, 'click', handler);
            },
            (handler, listener) => {
                return google.maps.event.removeListener(listener);
            }
        );

        sub = closeInfoWindow.subscribe(() => {
            const start = performance.now();
            this.infoWindow.close();
        });
        this.subscription.add(sub);
    }

    public drawMap(): void {
        const start = performance.now();
        const bounds = this.map.getBounds();
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
    }

    deleteMarkers(markers: google.maps.Marker[]) {
        markers.forEach((m) => {
            m.setMap(null);
        });
    }

    createMapInfoWindow(company: Company, address: CompanyAddress): string {
        let strWindow: string;
        strWindow =
            '<div class="container">' +
            '<div class="row">' +
            '<div class="col col-12">' +
            '<a class="text-blue mb-4 text-bold" href="/company/' +
            company.id +
            '"><h4>' +
            company.name +
            '</h4></a>' +
            '<div class="row mt-3">' +
            '<div class="col col-12">' +
            '<div class="row"><div class="col-12"><span class="text-blue text-bold text-small">Indirizzo:</span>&nbsp;&nbsp;<span class="text-small">' +
            address.address +
            ' ' +
            address.zipCode +
            ' - ' +
            address.municipality.name +
            ' (' +
            address.municipality.province.abbreviation +
            ')</span></div></div>' +
            '<div class="row mt-2"><div class="col-12"><span class="text-blue text-bold text-small">Tel:</span>&nbsp;&nbsp;<span class="text-small">' +
            (company.telephone ? company.telephone : '-') +
            '</span></div></div>' +
            '<div class="row mt-2"><div class="col-12"><span class="text-blue text-bold text-small">Email:</span>&nbsp;&nbsp;<span class="text-small">' +
            (company.email ? company.email : '-') +
            '</span></div></div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>';
        return strWindow;
    }

    onSearch(criteria: CompanySearch) {
        this.criteria = criteria;
        this.wait = true;
        const searchSub = this.companyService.searchToTable(this.pageParams, criteria).subscribe((data) => {
            this.totalOccurrence = data.totalRows;
            this.companies = data.rows;
            this.wait = false;
            this.initMap();
        });
        this.subscription.add(searchSub);
    }

    centerControl(controlDiv: Element, map: google.maps.Map) {
        const controlUI = document.createElement('div');
        controlUI.style.cursor = 'pointer';
        controlUI.style.marginLeft = '20px';
        controlUI.className = 'btn btn-main rounded-circle';
        controlUI.title = 'Centra la mappa nella tua posizione';
        controlDiv.appendChild(controlUI);

        const controlText = document.createElement('div');
        controlText.innerHTML =
            '<fa-icon _ngcontent-tlc-c162="" class="ng-fa-icon" ng-reflect-icon-prop="fas,crosshairs"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="crosshairs" class="svg-inline--fa fa-crosshairs fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M500 224h-30.364C455.724 130.325 381.675 56.276 288 42.364V12c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v30.364C130.325 56.276 56.276 130.325 42.364 224H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h30.364C56.276 381.675 130.325 455.724 224 469.636V500c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12v-30.364C381.675 455.724 455.724 381.675 469.636 288H500c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12zM288 404.634V364c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40.634C165.826 392.232 119.783 346.243 107.366 288H148c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-40.634C119.768 165.826 165.757 119.783 224 107.366V148c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12v-40.634C346.174 119.768 392.217 165.757 404.634 224H364c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40.634C392.232 346.174 346.243 392.217 288 404.634zM288 256c0 17.673-14.327 32-32 32s-32-14.327-32-32c0-17.673 14.327-32 32-32s32 14.327 32 32z"></path></svg></fa-icon>';
        controlText.className = 'text-white';
        controlUI.appendChild(controlText);

        controlUI.addEventListener('click', () => {
            this.addressText.nativeElement.value = null;
            this.place = null;
            this.initMap();
        });
    }
}
