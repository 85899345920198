import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(private toastr: ToastrService) {}

    success(message, title) {
        this.toastr.success(message, title, {
            toastClass: 'ngx-toastr app-notification success',
            closeButton: true,
            tapToDismiss: true,
            enableHtml: true,
            disableTimeOut: true,
        });
    }

    warning(message, title) {
        this.toastr.warning(message, title, {
            toastClass: 'ngx-toastr app-notification warning',
            closeButton: true,
            tapToDismiss: true,
            enableHtml: true,
            disableTimeOut: true,
        });
    }

    error(message, title) {
        this.toastr.error(message, title, {
            toastClass: 'ngx-toastr app-notification error',
            closeButton: true,
            tapToDismiss: true,
            enableHtml: true,
            disableTimeOut: true,
        });
    }

    info(message, title) {
        this.toastr.info(message, title, {
            toastClass: 'ngx-toastr app-notification info',
            closeButton: true,
            tapToDismiss: true,
            enableHtml: true,
            disableTimeOut: true,
        });
    }

    clear() {
        this.toastr.clear();
    }
}
