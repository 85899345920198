<div class="modal-header">
    <h2 class="modal-title text-center text-heading text-blue">Recupera password</h2>

    <!-- <div class="close cursor-pointer" aria-label="Close" (click)="closeAction('Cross click')">
        <span aria-hidden="true">&times;</span>
    </div> -->
</div>
<div class="modal-body">
    <form class="form" role="form" autocomplete="off" id="form" novalidate="" [formGroup]="form">
        <div class="input-container">
            <div class="input-group">
                <div class="input-group-prepend custom-label custom-label-sx">
                    <fa-icon icon="user"></fa-icon>
                </div>

                <input
                    id="input-username"
                    name="username"
                    formControlName="username"
                    class="form-control custom-input"
                    type="email"
                    placeholder="Inserisci il tuo indirizzo e-mail"
                    required
                    email
                    [ngClass]="{ 'is-invalid': controls.username.errors }"
                />
            </div>
        </div>

        <div class="text-center mt-3 mt-lg-4">
            <button type="button" class="d-inline-block btn send-button btn btn-main angle-top-sx shadow-default" (click)="confirmAction()">
                <fa-icon icon="envelope" class="mr-3"></fa-icon>
                <span class="text-uppercase">recupera password</span>
            </button>
        </div>
    </form>
</div>
