<div class="container mt-5 mb-5 container-richiesta-info-wrapper">
    <form role="form" autocomplete="off" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="text-center mb-5 pb-5" data-aos="fade-down">
            <img src="assets/images/Logo_CCI_Bergamo_web_BLU.svg" height="50px" alt="Logo Insieme" />
        </div>

        <!-- TITOLO -->
        <div class="row">
            <h1 class="text-bold text-blue mb-4 mt-n5" data-aos="fade-up">
                Stai scrivendo un messaggio come {{ company?.name }}
            </h1>
        </div>

        <div class="row" data-aos="fade-up" data-aos-delay="100">
            <!-- <p class="text-grey text-uppercase text-bold mb-1">messaggio</p> -->
            <textarea class="form-control custom-input shadow-default p-3" formControlName="message" name="message" rows="5" placeholder="Scrivi qui la tua richiesta"> </textarea>
        </div>
        <div class="row mt-4" data-aos="fade-up" data-aos-delay="150">
            <mat-checkbox formControlName="privacy" class="privacy-checkbox"
                >Sì, ho letto la <a href="#" class="text-blue text-underlined">privacy policy</a> e acconsento al trattamento dei dati, che non verranno mai ceduti a terzi.
            </mat-checkbox>
        </div>
        <div class="text-center mt-4" data-aos="fade-up" data-aos-delay="200">
            <button type="submit" class="btn btn-main shadow-default angle-bottom-sx" [disabled]="!form.valid">
                <fa-icon [icon]="['fas', 'paper-plane']" class="text-white text-bigger mr-2"></fa-icon>
                <span class="text-uppercase text-white">Invia messaggio</span>
            </button>
        </div>
    </form>
</div>
