import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterUploadFiles',
    pure: false,
})
export class FilterUploadFilesPipe implements PipeTransform {
    transform(objs: any[], statusExcluded: string) {
        const status = statusExcluded.split(',').map((s) => s.trim());
        const result = objs.filter((x) => status.indexOf(x.status) === -1);
        return result;
    }
}
