<ng-container *ngIf="user || userId; else elseTemplate">
    <ng-template #popTitle>{{ user.fullName }}</ng-template>
    <ng-template #popContent>
        <div *ngIf="user.email">
            Email: <a href="mailto:{{ user.email }}" target="_blank">{{ user.email }}</a>
        </div>
        <div *ngIf="user.roles.length > 0">Ruoli: {{ showUserRoles() }}</div>
    </ng-template>

    <span class="label" [ngbPopover]="popContent" [popoverTitle]="popTitle" triggers="mouseenter:mouseleave" [autoClose]="true">
        <fa-icon icon="info-circle" class="mr-2"></fa-icon>
        <span>{{ user?.fullName }}</span>
    </span>
</ng-container>

<ng-template #elseTemplate></ng-template>
