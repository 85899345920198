//@angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

//External lib
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

//App model
import { News } from 'src/app/models/news.model';
import { NewsExtended } from 'src/app/models/news-extend.model';

//App service
import { NewsService } from 'src/app/services/resources/news.service';
import { FileService } from 'src/app/services/resources/file.service';

@Component({
    selector: 'home-page-news',
    templateUrl: './home-page-news.component.html',
    styleUrls: ['./home-page-news.component.css'],
})
export class HomePageNewsComponent implements OnInit, OnDestroy {
    public news: NewsExtended[] = null;
    private subscription = new Subscription();

    constructor(private newsService: NewsService, private fileService: FileService, public sanitizer: DomSanitizer) {}

    ngOnInit(): void {
        console.log('HomePageNewsComponent ngOnInit');
        const sub = this.newsService
            .getNews()
            .pipe(
                map((list: News[]) => list.map((n) => new NewsExtended(n, this.newsService, this.fileService, this.sanitizer))),
                map((list: NewsExtended[]) =>
                    list.map((sn) => {
                        if (sn.title.length >= 120) {
                            sn.title = sn.title.substring(0, 117).concat('...');
                        }
                        if (sn.summary.length >= 150) {
                            sn.summary = sn.summary.substring(0, 147).concat('...');
                        }
                        return sn;
                    })
                )
            )
            .subscribe((list) => (this.news = list));
        this.subscription.add(sub);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
