// @angular
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';

// External libs
import { combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

// App Helpers
import { environment } from 'src/environments/environment';
import { RepeatValidator } from 'src/app/helpers/repeat.validator';

// App Models
import { ResetPassword } from 'src/app/models/reset-password.model';

// App Services
import { UserService } from 'src/app/services/resources/user.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/resources/auth.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    userID: string;
    token: string;
    message: string;

    form: FormGroup;

    get controls() {
        return this.form.controls;
    }

    // Subscription
    private subscriptions: Subscription = new Subscription();

    // tslint:disable-next-line: max-line-length
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        private userService: UserService,
        private notificationService: NotificationService,
        private authService: AuthService,
        private navigationService: NavigationService
    ) {}

    ngOnInit(): void {
        console.log('ResetPasswordComponent ngOnInit!');

        this.form = new FormGroup(
            {
                token: new FormControl('', [Validators.required], null),
                oldPassword: new FormControl('', [Validators.required], null),
                newPassword: new FormControl('', [Validators.required], null),
                confirmNewPassword: new FormControl('', [Validators.required, RepeatValidator('newPassword')], null),
            },
            { updateOn: 'change' }
        );

        const token$ = this.route.queryParamMap.pipe(map((params) => params.get('token')));

        const sub = combineLatest([this.route.params, token$]).subscribe(([params, token]) => {
            this.token = token;
            this.userID = params.id;

            this.titleService.setTitle(`${environment.appName} - Reset password`);

            if (!this.token) {
                this.router.navigate(['/error']);
            } else {
                this.form.get('token').patchValue(token);
            }
        });
        this.subscriptions.add(sub);
    }

    ngOnDestroy(): void {
        console.log('ResetPasswordComponent ngOnDestroy!');

        this.subscriptions.unsubscribe();
    }

    public onSubmit(): void {
        if (this.form.invalid) {
            return;
        }

        const model = new ResetPassword(this.form);
        const sub = this.userService.resetPassword(this.userID, model).subscribe(
            () => {
                this.authService.logout();
                this.router.navigateByUrl('/login');
                // this.navigationService.init();
                this.notificationService.success(
                    'La nuova password è stata correttamente salvata. Ora è possibile effettuare il login con le nuove credenziali.',
                    'Cambio password effettuato'
                );
            },
            (response: HttpErrorResponse) => {
                this.notificationService.error('Link non valido oppure la vecchia password non è corretta.', 'Errore aggiornamento password');
            }
        );
        this.subscriptions.add(sub);
    }
}
