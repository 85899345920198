<form role="form" autocomplete="off" #ricercaProdotti id="searchProductForm" [formGroup]="searchProductForm">
    <div class="container-fluid bg-lightblue pb-5">
        <div class="row mb-5 pb-5">
            <div class="container-fluid container-lg mt-5 pt-5 mb-5 pb-5">
                <div class="row">
                    <div class="col-lg-8">
                        <!-- TITOLO -->
                        <h1 class="text-heading text-bigger text-blue mb-4 mb-lg-0" data-aos="fade-up">
                            Ricerca un <span class="font-underliner">prodotto</span> o un <span class="font-underliner">servizio</span>
                        </h1>
                    </div>
                    <div class="mt-lg-n4 offset-lg-4 col-lg-8 offset-xl-5 col-xl-7 mb-4 mt-4">
                        <div class="input-group search-product-wrapper" data-aos="fade-up" data-aos-delay="150">
                            <mat-select
                                id="input-product-ID"
                                class="input-product-id form-control custom-input justify-content-center d-flex flex-column text-white bg-blue text-uppercase"
                                formControlName="productServices"
                                placeholder="CATEGORIA"
                                panelClass="product-panel-select"
                            >
                                <mat-option [value]="null">Nessun Filtro</mat-option>
                                <mat-option *ngFor="let prod of products" [value]="prod.id">{{ prod.name }}</mat-option>
                            </mat-select>

                            <input
                                type="text"
                                id="parola-chiave"
                                class="form-control custom-input"
                                formControlName="keyword"
                                placeholder="Cerca per parola chiave o per categoria..."
                            />
                            <button type="button" class="btn btn-main angle-top-sx search-product-button" (click)="onSearch(searchProductForm)">
                                <fa-icon [icon]="['fas', 'search']"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12">
                        <mat-accordion id="accordion-products" class="d-flex flex-row">
                            <mat-expansion-panel *ngFor="let prod of products" data-aos="fade-up" data-aos-delay="300" hideToggle>
                                <!-- MACRO CATEGORIE -->
                                <mat-expansion-panel-header (click)="scrollToMenu()" [collapsedHeight]="customCollapsedHeight" [expandedHeight]="customExpandedHeight" class="p-0">
                                    <img
                                        class="d-block"
                                        [src]="'/assets/images/products/' + replace(prod.name) + '/' + replace(prod.name) + '.svg'"
                                        [alt]="prod.name"
                                        height="190"
                                    />
                                    <p class="text-blue text-uppercase text-bold text-center mt-4 mb-0">{{ prod.name }}</p>
                                </mat-expansion-panel-header>

                                <!-- PRODOTTI E SERVIZI -->
                                <div class="services-wrapper row justify-content-start">
                                    <div class="single-service text-center shadow-default pl-4 pr-4 pt-2 pb-2 mb-3" *ngFor="let ps of prod.productServices">
                                        <a [routerLink]="['/company/results/productservice', ps.id]">
                                            <product-service-image [ps]="ps"></product-service-image>
                                            <p class="text-blue text-uppercase text-center text-bold text-smaller mt-3 mb-0">{{ ps.name }}</p>
                                        </a>
                                    </div>

                                    <div class="all-services-button d-flex flex-row align-items-center mt-n2">
                                        <a class="text-uppercase text-green ml-3 d-none d-md-block" [routerLink]="['/company/results/product', prod.id]" routerLinkActive="active">
                                            Tutti
                                            <fa-icon
                                                icon="chevron-right"
                                                prefix="fa"
                                                class="d-inline-block line-normal text-white ml-2 bg-green border-round-pill px-2 py-1"
                                            ></fa-icon>
                                        </a>
                                        <a class="btn btn-main mt-4 d-block d-md-none" [routerLink]="['/company/results/product', prod.id]" routerLinkActive="active">
                                            <span class="text-uppercase text-white">Tutti</span>
                                            <fa-icon icon="chevron-right" prefix="fa" class="d-inline-block ml-2"></fa-icon>
                                        </a>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
