// @angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// External libs
import { Observable } from 'rxjs';

// App Models
import { Tag } from 'src/app/models/tag.model';

// App Services
import { BaseService } from './base.service';

@Injectable()
export class TagService extends BaseService {
    constructor(private http: HttpClient) {
        super(http);
    }

    public getAll(): Observable<Tag[]> {
        const url = `${this.baseUrl}tag`;
        return this.http.get<Tag[]>(url);
    }

    public quickSearch(criteria: string): Observable<Tag[]> {
        const url = `${this.baseUrl}tag/quicksearch/${criteria}`;
        return this.http.get<Tag[]>(url);
    }
}
