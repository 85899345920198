// @angular
import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// External libs
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

// App Model
import { ConventionRequest } from 'src/app/models/convention-request.model';
import { UnionFederation } from 'src/app/models/union-federation.model';

// App Services
import { BaseService } from './base.service';

@Injectable()
export class ConventionRequestService extends BaseService {
    private conventionRequestCache: { [id: string]: ConventionRequest } = {};

    @Output() currentEditConventionRequest$: BehaviorSubject<ConventionRequest> = new BehaviorSubject<ConventionRequest>(null);

    constructor(private http: HttpClient) {
        super(http);
    }

    public getAll(): Observable<ConventionRequest[]> {
        const url = `${this.baseUrl}convention/request`;
        return this.http.get<ConventionRequest[]>(url);
    }

    public getById(requestId: string): Observable<ConventionRequest> {
        const url = `${this.baseUrl}convention/request/${requestId}`;
        return this.http.get<ConventionRequest>(url).pipe(
            tap((c) => {
                this.currentEditConventionRequest$.next(c);
            })
        );
    }

    public getByConvention(conventionId: string): Observable<ConventionRequest[]> {
        const url = `${this.baseUrl}convention/request/conventions/${conventionId}`;
        return this.http.get<ConventionRequest[]>(url);
    }

    public getByCompany(companyId: string): Observable<ConventionRequest[]> {
        const url = `${this.baseUrl}convention/request/company/${companyId}`;
        return this.http.get<ConventionRequest[]>(url);
    }

    public getByConventions(request: string[]): Observable<ConventionRequest[]> {
        if (!request) {
            throw Observable.throw('Informazioni sulle campagne non valide');
        }
        const url = `${this.baseUrl}convention/request/conventions`;
        return this.http.post<ConventionRequest[]>(url, request);
    }

    public getByConventionAndCompany(conventionId: string, companyId: string): Observable<ConventionRequest[]> {
        const url = `${this.baseUrl}convention/request/${conventionId}/${companyId}`;
        return this.http.get<ConventionRequest[]>(url);
    }

    public fetchById(requestId: string): Observable<ConventionRequest> {
        const url = `${this.baseUrl}convention/request/${requestId}`;
        return this.http
            .get<ConventionRequest>(url)
            .map((c) => {
                this.currentEditConventionRequest$.next(c);
                this.conventionRequestCache[c.id] = c;
                return c;
            })
            .share();
    }
    /*
    public getByUser(userid: string): Observable<ConventionRequest[]> {
        const url = `${this.baseUrl}convention/request/user/${userid}`;
        return this.http.get<ConventionRequest[]>(url);
    }
	*/
    public update(request: ConventionRequest): Observable<ConventionRequest> {
        if (!request) {
            throw Observable.throw('Informazioni sulla richiesta non valide');
        }
        const url = `${this.baseUrl}convention/request/update`;
        return this.http.post<ConventionRequest>(url, request);
    }

    public updateAll(requests: ConventionRequest[]): Observable<ConventionRequest[]> {
        if (!requests) {
            throw Observable.throw('Informazioni sulle richieste non valide');
        }
        const url = `${this.baseUrl}convention/request/update/all`;
        return this.http.post<ConventionRequest[]>(url, requests);
    }

    public create(request: ConventionRequest): Observable<ConventionRequest> {
        if (!request) {
            throw Observable.throw('Informazioni sulla richiesta non valide');
        }
        const url = `${this.baseUrl}convention/request/create`;
        return this.http.post<ConventionRequest>(url, request);
    }

    public getUnionsAndFederationsByCompany(companyId: string): Observable<UnionFederation[]> {
        const url = `${this.baseUrl}convention/unionsfederations/${companyId}`;
        return this.http.get<UnionFederation[]>(url);
    }
}
