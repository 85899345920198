<!-- SE LOGGATO -->
<ng-container *ngIf="!isPublic; else elseTemplate">
    <div class="d-flex flex-column w-100 justify-content-center btn-area-riservata">
        <!-- DESKTOP -->
        <!-- <a class="d-none d-md-block h-100" [routerLink]="['/area-riservata/cooperations/create']" routerLinkActive="active">
            <button
                class="btn-start-collab btn btn-main angle-bottom-dx angle-top-sx angle-top-dx shadow-default text-uppercase h-100 text-right d-flex flex-row align-items-center"
            >
                <span
                    >Inizia una <br />
                    collaborazione</span
                >
                <fa-icon class="h1 ml-2" prefix="far" icon="hands-helping"></fa-icon>
            </button>
        </a> -->

        <!-- MOBILE -->
        <!-- <a class="d-inline-block d-md-none mx-auto" [routerLink]="['/area-riservata/cooperations/create']" routerLinkActive="active">
            <button (click)="triggerMenuBarMobile()" class="btn-start-collab btn btn-main shadow-default text-uppercase text-center d-flex flex-row align-items-center mb-3">
                <span class="text-uppercase">Inizia una collaborazione</span>
                <fa-icon class="h1 mb-0 ml-2" prefix="far" icon="hands-helping"></fa-icon>
            </button>
        </a> -->
        <!-- TASTO LOGOUT -->
        <a class="d-inline-block d-md-none logout-btn">
            <button (click)="logOut()" class="btn btn-main border-radius-pill shadow-default bg-bando cursor-pointer">
                <span class="text-uppercase mr-2">LOGOUT</span>
                <fa-icon [icon]="['fas', 'sign-out-alt']" ></fa-icon>
            </button>
        </a>
    </div>
    <a class="d-none d-md-flex  ml-1 logout-btn desktop">
        <button (click)="logOut()"
            class="d-flex flex-row align-items-center btn btn-main border-radius-pill shadow-default bg-bando bg-danger cursor-pointer mb-0 angle-top-sx angle-bottom-sx angle-top-dx px-2 h-100">
            <fa-icon icon="sign-out-alt" ></fa-icon>
        </button>
    </a>
</ng-container>

<!-- SE NON LOGGATO -->
<ng-template #elseTemplate>
    <!-- DESKTOP -->
    <div class="AreaRiservata-Card mt-4">
        <a class="d-flex justify-content-end">
            <button type="button" (click)="triggerMenuBarMobile()"
                class="btn-start-collab  btn-area-riservata btn btn-main angle-bottom-dx angle-top-sx angle-top-dx shadow-default text-uppercase text-right d-flex flex-row align-items-center"
                (click)="navigatetoPrivate()">
                <span class="line-normal h3 mb-0">Area riservata</span>
                <fa-icon class="h1 ml-3 mb-0" prefix="far" icon="user-circle"></fa-icon>
            </button>
        </a>
    </div>
    <!-- MOBILE -->
    <!-- <a class="d-block w-100 d-md-none btn-area-riservata px-3">
        <button
            type="button"
            (click)="triggerMenuBarMobile()"
            class="w-100 mx-auto btn-start-collab btn btn-main angle-bottom-sx shadow-default text-uppercase text-center d-flex flex-row align-items-center justify-content-center"
            (click)="navigatetoPrivate()"
        >
            <span class="line-normal">Area riservata</span>
            <fa-icon class="ml-3 h3 mb-0" prefix="far" icon="user-circle"></fa-icon>
        </button>
    </a> -->
</ng-template>