// @angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatTreeModule } from '@angular/material/tree';

// External libs
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EllipsisPipe } from 'src/app/helpers/ellipsis.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AvatarModule } from 'ngx-avatar';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// App Helpers
import { SafeHtmlPipe, SafeUrlPipe } from 'src/app/helpers/safe-transform.pipe';
import { FileSizePipe } from 'src/app/helpers/file-size.pipe';
import { FilterUploadFilesPipe } from 'src/app/helpers/filter-upload-files.pipe';
import { TooltipDirective } from 'src/app/helpers/tooltip.directive';
import { ServicesModule } from 'src/app/services/services.module';

// App Components
import { QuickUserInfoComponent } from './components/quick-user-info/quick-user-info.component';
import { WidgetSearchCompanyComponent } from './components/widget-search-company/widget-search-company.component';
import { WidgetSearchMapComponent } from './components/widget-search-map/widget-search-map.component';
import { WidgetLoginButtonComponent } from './components/widget-login-button/widget-login-button.component';
import { DocumentItemComponent } from './components/document-item/document-item.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { ProductServicePickerComponent } from './components/product-service-picker/product-service-picker.component';
import { WidgetSearchProductComponent } from './components/widget-search-product/widget-search-product.component';
import { WidgetProductServiceComponent } from './components/widget-product-service/widget-product-service.component';
import { WidgetCompanyAssociatesComponent } from './components/widget-company-associates/widget-company-associates.component';
import { WidgetNewsComponent } from './components/widget-news/widget-news.component';
import { ProductServiceListComponent } from './components/product-service-list/product-service-list.component';
import { ProductServiceImageComponent } from './components/product-service-image/product-service-image.component';
import { WidgetCompanyUsersComponent } from './components/widget-company-users/widget-company-users.component';
import { RichiestaInfoComponent } from 'src/app/modules/area-riservata/components/richiesta-info/richiesta-info.component';
import { NewsListComponent } from './components/news-list/news-list.component';
import { HomePageNewsComponent } from 'src/app/modules/shared/components/home-page-news/home-page-news.component';
import { UserRolesInfoComponent } from './components/user-roles-info/user-roles-info.component';
import { QuickCompanyInfoComponent } from './components/quick-company-info/quick-company-info.component';
import { WidgetCooperationCompanyComponent } from './components/widget-cooperation-company/widget-cooperation-company.component';
import { WidgetCooperationCompanyPartnerComponent } from './components/widget-cooperation-company-partner/widget-cooperation-company-partner.component';
import { WidgetSearchSidebarComponent } from './components/widget-search-sidebar/widget-search-sidebar.component';
import { WidgetCooperationAuthorizationComponent } from './components/widget-cooperation-authorization/widget-cooperation-authorization.component';
import { MaskRequestComponent } from 'src/app/modules/area-riservata/components/masks-request/mask-request.component';
import { ExplanationRolesModalComponent } from './components/roles-explanation-modal/explanation-roles-modal/explanation-roles-modal.component';
import { LoadingSpinnerComponent } from 'src/app/modules/shared/components/loading-spinner/loading-spinner.component';

// import { FileItemComponent } from './components/file-item/file-item.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        RouterModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        MatSelectModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatTableModule,
        ServicesModule,
        GoogleMapsModule,
        AvatarModule,
        CarouselModule.forRoot(),
        MatCardModule,
        InfiniteScrollModule,
        MatGridListModule,
        MatTableModule,
        MatInputModule,
        MatTreeModule,
    ],
    declarations: [
        SafeHtmlPipe,
        SafeUrlPipe,
        FileSizePipe,
        FilterUploadFilesPipe,
        EllipsisPipe,
        TooltipDirective,
        QuickUserInfoComponent,
        QuickCompanyInfoComponent,
        DocumentItemComponent,
        FileUploaderComponent,
        ProductServicePickerComponent,
        WidgetSearchCompanyComponent,
        WidgetSearchMapComponent,
        WidgetLoginButtonComponent,
        WidgetSearchProductComponent,
        WidgetProductServiceComponent,
        WidgetCompanyAssociatesComponent,
        WidgetCompanyUsersComponent,
        WidgetNewsComponent,
        WidgetCooperationCompanyComponent,
        WidgetCooperationCompanyPartnerComponent,
        ProductServiceListComponent,
        ProductServiceImageComponent,
        RichiestaInfoComponent,
        NewsListComponent,
        HomePageNewsComponent,
        UserRolesInfoComponent,
        WidgetSearchSidebarComponent,
        WidgetCooperationAuthorizationComponent,
        MaskRequestComponent,
        ExplanationRolesModalComponent,
        LoadingSpinnerComponent,
        //FileItemComponent,
    ],
    providers: [FileSizePipe, FilterUploadFilesPipe, SafeHtmlPipe, SafeUrlPipe],
    exports: [
        QuickUserInfoComponent,
        QuickCompanyInfoComponent,
        DocumentItemComponent,
        TooltipDirective,
        WidgetSearchCompanyComponent,
        WidgetSearchMapComponent,
        WidgetLoginButtonComponent,
        FileUploaderComponent,
        ProductServicePickerComponent,
        WidgetSearchProductComponent,
        WidgetProductServiceComponent,
        WidgetCompanyAssociatesComponent,
        WidgetCompanyUsersComponent,
        WidgetNewsComponent,
        WidgetCooperationCompanyComponent,
        WidgetCooperationCompanyPartnerComponent,
        ProductServiceListComponent,
        ProductServiceImageComponent,
        UserRolesInfoComponent,
        NewsListComponent,
        HomePageNewsComponent,
        WidgetSearchSidebarComponent,
        WidgetCooperationAuthorizationComponent,
        ExplanationRolesModalComponent,
        FileSizePipe,
        SafeHtmlPipe,
        SafeUrlPipe,
        LoadingSpinnerComponent,
    ],
})
export class SharedModule {
    static forRoot() {
        return {
            ngModule: SharedModule,
            providers: [],
        };
    }
}
