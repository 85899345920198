// @angular
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

// External libs
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

// App Models
import { User } from 'src/app/models/user.model';

// App Services
import { BaseService } from './base.service';
import { UserService } from './user.service';

const AUTH_TOKEN = 'auth.token';
const AUTH_TICKET = 'auth.ticket';
const AUTH_CURRENT = 'auth.current';
const GRANT_TYPE_PASSWORD = 'password';
const GRANT_TYPE_REFRESH = 'refresh_token';
const AUTH_COMPANY = 'companyId';
const MODE_SITE = 'modeSite';
const IS_ADMIN = 'isAdmin';

export class AuthCredentials {
    username: string;
    password: string;
    grant_type: string;
    refresh_token: string;
    isPersistent?: boolean;

    constructor(username, password, isPersistent) {
        this.username = username;
        this.password = password;
        this.isPersistent = isPersistent;
    }

    public toHttpParams() {
        let params = new HttpParams();
        Object.keys(this).forEach((key) => {
            if (this[key]) {
                params = params.set(key, this[key]);
            }
        });
        return params;
    }
}

export class AuthResponse {
    access_token: string;
    token_tike: string;
    expires_in: number;
    refresh_token: string;
    '.issued': Date;
    '.expires': Date;
    isPersistent?: boolean;
}

@Injectable()
export class AuthService extends BaseService implements OnDestroy {
    public isAdministrator$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public currentMode$: BehaviorSubject<string> = new BehaviorSubject(null);
    public companyId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private http: HttpClient, private userService: UserService) {
        super(http);

        const sub = this.userService.currentUser$.subscribe((user) => {
            if (user) {
                AuthService.setCurrentUser(user);
            }
        });
        this.subscriptions.add(sub);

        const ticket = this.getAuthTicket();
        if (ticket) {
            this.isPersistent = ticket.isPersistent || false;
        }
    }

    isPersistent = false;
    isLoggedIn = false;
    // store the URL so we can redirect after logging in
    redirectUrl = '';
    currentUser: User = null;
    loginStatusUpdates = new Subject<boolean>();

    // Subscriptions
    private subscriptions: Subscription = new Subscription();

    public static getToken() {
        return localStorage.getItem(AUTH_TOKEN);
    }

    private static setToken(token: string) {
        localStorage.setItem(AUTH_TOKEN, token);
    }

    private static removeToken() {
        localStorage.removeItem(AUTH_TOKEN);
    }

    public getAuthTicket() {
        const item = localStorage.getItem(AUTH_TICKET);
        return JSON.parse(item);
    }

    public static getCompanyId() {
        return localStorage.getItem(AUTH_COMPANY);
    }

    private static setAuthTicket(ticket: AuthResponse) {
        localStorage.setItem(AUTH_TICKET, JSON.stringify(ticket));
    }

    private static removeAuthTicket() {
        localStorage.removeItem(AUTH_TICKET);
    }

    public static getCurrentUser(): User {
        const user = localStorage.getItem(AUTH_CURRENT);
        return JSON.parse(user);
    }

    private static setCurrentUser(user: User) {
        localStorage.setItem(AUTH_CURRENT, JSON.stringify(user));
    }

    private removeCurrentUser() {
        localStorage.removeItem(AUTH_CURRENT);
        this.userService.currentUser$.next(null);
    }

    public getCurrentCompany(): string {
        return localStorage.getItem(AUTH_COMPANY);
    }

    public getIsAdmin(): string {
        return localStorage.getItem(IS_ADMIN);
    }

    private removeCurrentCompany() {
        localStorage.removeItem(AUTH_COMPANY);
        this.companyId$.next(null);
    }

    public getModalitaSito(): string {
        return localStorage.getItem(MODE_SITE);
    }

    private removeIsAdmin() {
        localStorage.removeItem(IS_ADMIN);
        this.isAdministrator$.next(null);
    }

    public setIsAdmin(admin: string) {
        localStorage.setItem(IS_ADMIN, admin);
        this.isAdministrator$.next(admin);
    }

    public setModalitaSito(mode: string): void {
        localStorage.setItem(MODE_SITE, mode);
        this.currentMode$.next(mode);
    }

    ngOnDestroy() {
        console.log('AuthService ngOnDestroy!');

        this.subscriptions.unsubscribe();
    }

    public init() {
        const token = AuthService.getToken();
        const user = AuthService.getCurrentUser();
        const cid = AuthService.getCompanyId();

        if (token && user) {
            this.userService.currentUser$.next(user);
            this.updateLoginStatus(true, user);
            const mode = this.getModalitaSito();
            this.setModalitaSito(mode);
        }

        if (cid) {
            this.updateCompanyId(cid);
        }
    }

    public login(credentials: AuthCredentials) {
        this.unloadCurrentUser();

        credentials.grant_type = GRANT_TYPE_PASSWORD;
        this.isPersistent = credentials.isPersistent;

        const httpOptions = {
            headers: new HttpHeaders({
                Accept: 'application/json',
                Audience: 'Any',
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        };

        const body = credentials.toHttpParams();

        return this.http.post<AuthResponse>(this.baseUrl + 'auth/login', body.toString(), httpOptions).pipe(
            map((auth: AuthResponse) => {
                console.log('Login success: ', auth);
                AuthService.setToken(auth.access_token);
                auth.isPersistent = this.isPersistent;
                AuthService.setAuthTicket(auth);
                this.loadCurrentUser();
            })
        );
    }

    public refreshLogin() {
        const credentials = new AuthCredentials('', '', null);
        const ticket = this.getAuthTicket();
        if (ticket) {
            credentials.refresh_token = ticket.refresh_token;
            credentials.grant_type = GRANT_TYPE_REFRESH;

            const httpOptions = {
                headers: new HttpHeaders({
                    Accept: 'application/json',
                    Audience: 'Any',
                    'Content-Type': 'application/x-www-form-urlencoded',
                }),
            };

            const body = credentials.toHttpParams();
            return this.http.post<AuthResponse>(this.baseUrl + 'auth/login', body.toString(), httpOptions).pipe(
                tap((auth: AuthResponse) => {
                    console.log('Refresh Login success: ', auth);
                    AuthService.setToken(auth.access_token);
                    auth.isPersistent = this.isPersistent;
                    AuthService.setAuthTicket(auth);
                    this.loadCurrentUser();
                })
            );
        }
    }

    public logout() {
        if (this.isLoggedIn) {
            this.unloadCurrentUser();
            this.updateLoginStatus(false, null);

            return this.http.get(this.baseUrl + 'user/logout').pipe(
                catchError((error) => {
                    console.log('logout -> catchError', error);
                    return Observable.throwError(error);
                })
            );
        }
    }

    public updateLoginStatus(isLogged: boolean, user: User) {
        this.isLoggedIn = isLogged;
        this.currentUser = user;
        this.loginStatusUpdates.next(isLogged);
        if (isLogged === false) {
            this.unloadCurrentUser();
        }
    }

    private loadCurrentUser() {
        this.userService.current().subscribe(
            (user: User) => {
                this.currentUser = user;
                AuthService.setCurrentUser(user);
                this.setModalitaSito('private');
                this.updateLoginStatus(true, user);
            },
            (error: HttpErrorResponse) => {
                this.unloadCurrentUser();
                this.updateLoginStatus(false, null);

                console.log('loadCurrentUser -> subscribe -> error', error);
                return new Error(error.message);
            }
        );
    }

    private unloadCurrentUser() {
        this.currentUser = null;
        AuthService.removeToken();
        AuthService.removeAuthTicket();
        this.removeCurrentUser();
        this.removeCurrentCompany();
        this.removeIsAdmin();
        this.setModalitaSito('public');
    }

    public updateCompanyId(cid: string) {
        this.companyId$.next(cid);
    }

    public setCompanyId(id: string) {
        localStorage.setItem(AUTH_COMPANY, id);
        this.companyId$.next(id);
    }

    public getCurrentUser(): User {
        const user = localStorage.getItem(AUTH_CURRENT);
        return JSON.parse(user);
    }
}
