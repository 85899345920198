// @angular
import { Component, OnDestroy, OnInit } from '@angular/core';

// External libs
import { Subscription } from 'rxjs';

// App Models
import { ProductService } from 'src/app/models/product-service.model';

// App Services
import { ProductServiceService } from 'src/app/services/resources/productservice.service';

@Component({
    selector: 'product-service-list',
    templateUrl: './product-service-list.component.html',
    styleUrls: ['./product-service-list.component.css'],
})
export class ProductServiceListComponent implements OnInit, OnDestroy {
    productServices: Array<ProductService>;
    private subscriptions = new Subscription();

    constructor(private productServiceService: ProductServiceService) {}

    ngOnInit(): void {
        console.log('ProductServiceListComponent - ngOnInit!!');

        const sub = this.productServiceService.getAll().subscribe((p) => {
            const randomize = p.sort(() => 0.5 - Math.random());
            this.productServices = randomize.slice(0, 8);
        });
        this.subscriptions.add(sub);
    }

    ngOnDestroy(): void {
        console.log('ProductServiceListComponent - ngOnDestroy!!');
        this.subscriptions.unsubscribe();
    }
}
