// @angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

// External libs
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

// App Helpers
import { environment } from 'src/environments/environment';
import { CooperationTypeImageEnum } from 'src/app/helpers/cooperation-type.enum';

// App Models
import { Cooperation } from 'src/app/models/cooperation.model';
import { CooperationFilter } from 'src/app/models/cooperation-filter';
import { CooperationApprove } from 'src/app/models/cooperation-approve.model';

// App Services
import { CooperationService } from 'src/app/services/resources/cooperation.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';

@Component({
    selector: 'cooperation-dashboard',
    templateUrl: './cooperation-dashboard.component.html',
    styleUrls: ['./cooperation-dashboard.component.css'],
})
export class CooperationDashboardComponent implements OnInit, OnDestroy {
    moderatingCooperation: Array<Cooperation> = [];
    totalOccurence: number;
    private subscription = new Subscription();
    filter = new CooperationFilter();
    cooperationTypeImage = CooperationTypeImageEnum;

    constructor(private cooperationService: CooperationService, private navigationService: NavigationService, private toastr: ToastrService, private titleService: Title) {}

    ngOnInit(): void {
        console.log('CooperationDashboardComponent ngOnInit!');
        this.titleService.setTitle(`${environment.appName} - Approva collaborazioni`);

        this.filter.pageSize = 10;

        const sub = this.cooperationService.getModeratingCoop(this.filter).subscribe((pagedList) => {
            this.moderatingCooperation = pagedList.rows;
            this.totalOccurence = pagedList.totalRows;
        });

        this.subscription.add(sub);
    }

    approve(id: string) {
        console.log('mycoopId', id);
        const cooperationApprove = new CooperationApprove();
        cooperationApprove.coopId = id;

        const sub = this.cooperationService
            .approve(cooperationApprove)
            .pipe(
                catchError((error) => {
                    this.toastr.warning(`L'approvazione non è andata a buon fine`);
                    return error;
                }),
                switchMap(() => this.cooperationService.getModeratingCoop(this.filter))
            )
            .subscribe((data) => {
                if (data) {
                    this.moderatingCooperation = data.rows;
                    this.totalOccurence = data.totalRows;
                    this.toastr.success(`L'approvazione è andata a buon fine`);
                }
            });

        this.subscription.add(sub);
    }

    ngOnDestroy(): void {
        console.log('CooperationDashboardComponent ngOnDestroy!');

        this.subscription.unsubscribe();
    }

    onScroll() {
        if (this.filter.pageSize < this.totalOccurence) {
            this.filter.pageSize += this.filter.pageSize;
            const sub = this.cooperationService.getModeratingCoop(this.filter).subscribe((list) => {
                if (list) {
                    this.moderatingCooperation = list.rows;
                }
            });
            this.subscription.add(sub);
        }
    }

    minimizeDescription(description: string): string {
        if (description.length >= 150) {
            return description.substring(0, 147).concat('...');
        } else {
            return description;
        }
    }
}
