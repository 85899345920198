<ng-container *ngIf="company$ | async as company">
    <div id="company-detail" class="container-fluid container-xl mt-5 mb-5">
        <div class="row">
            <!-- SCHEDA COOPERATIVA SX -->
            <div class="col-lg-9">
                <!-- NOME COOPERATIVA -->
                <h1 class="text-bold text-blue text-uppercase" data-aos="fade-down">{{ company.name }}</h1>

                <!-- DESCRIZIONE COOPERATIVA -->
                <div class="row company-description-wrapper mb-4" data-aos="fade-up" data-aos-delay="100">
                    <div class="col-md-11 mt-4" [innerHTML]="sanitizedDescription"></div>
                </div>

                <!-- TASTI CONTATTA / ALTRE INFORMAZIONI -->
                <div class="row">
<!--                    <div class="d-flex flex-column flex-md-row align-items-center justify-content-between coop-buttons-wrapper"-->
<!--                        data-aos="fade-up" data-aos-delay="100">-->
<!--                        <a class="btn btn-main angle-bottom-sx bg-transparent text-blue collapse shadow-default d-block infos-btn"-->
<!--                            (click)="isOtherInfoOpen = !isOtherInfoOpen" [attr.aria-expanded]="!isOtherInfoOpen"-->
<!--                            aria-controls="altre-informazioni" *ngIf="!isPublic">-->
<!--                            <fa-icon [icon]="['fas', 'info-circle']" class="mr-2"></fa-icon>-->
<!--                            <span class="text-uppercase">Altre informazioni</span>-->
<!--                            <fa-icon [icon]="isOtherInfoOpen ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']"-->
<!--                                class="ml-2"></fa-icon>-->
<!--                        </a>-->
<!--                    </div>-->

                    <!-- PANNELLO ALTRE INFORMAZIONI -->
                    <div class="" id="altre-informazioni"
                        aria-controls="altre-informazioni">
                        <div class="row mb-4 pb-5">
                            <div class="col-lg-5 mx-auto">
                                <div class="mb-3" *ngIf="company.vatNumber || company.fiscalCode">
                                    <p class="text-blue mb-1">Partita Iva / Codice Fiscale</p>
                                    <p class="text-grey mb-0">{{ company.vatNumber == company.fiscalCode ?
                                        company.vatNumber : company.vatNumber + ' / ' + company.fiscalCode }}</p>
                                </div>
                                <div class="mb-3" *ngIf="company.rea">
                                    <p class="text-blue mb-1">REA</p>
                                    <p class="text-grey mb-0">{{ company.rea }}</p>
                                </div>
                                <!--                                <div class="mb-3" *ngIf="company.externalCode">-->
                                <!--                                    <p class="text-blue mb-1">Matricola Cooperativa</p>-->
                                <!--                                    <p class="text-grey mb-0">{{ company.externalCode }}</p>-->
                                <!--                                </div>-->
                                <hr class="w-100" *ngIf="company.employees || company.revenue" />
                                <div class="mb-3" *ngIf="company.employees">
                                    <p class="text-blue mb-1">Numero Addetti</p>
                                    <p class="text-grey mb-0">{{ company.employees }}</p>
                                </div>
                                <div class="mb-3" *ngIf="company.revenue">
                                    <p class="text-blue mb-1">Fatturato</p>
                                    <p class="text-grey mb-0">{{ company.revenue }}</p>
                                </div>
                                <hr class="w-100" />
                                <div class="mb-3">
                                    <p class="text-blue mb-1">Filiali</p>
                                    <p class="text-grey mb-0" *ngFor="let address of addresses">
                                        {{ address.address }}, {{ address.zipCode }} {{ address.municipality.name }} ({{
                                        address.municipality.province.abbreviation }})
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-5 mx-auto">
                                <div class="mb-3" *ngIf="company.chairman">
                                    <p class="text-blue mb-1">Presidente</p>
                                    <p class="text-grey mb-0">{{ company.chairman }}</p>
                                </div>
                                <div class="mb-3 lead" *ngIf="company.referentName">
                                    <p class="text-blue mb-1">Referente Cooperativa</p>
                                    <p class="text-grey mb-0">{{ company.referentName }}</p>
                                    <p class="text-grey mb-0">{{ company.referentRole }}</p>
                                </div>
                                <hr class="w-100" />
                                <!--                                <div class="mb-3" *ngIf="company.feedRSS">-->
                                <!--                                    <p class="text-blue mb-1">Feed RSS</p>-->
                                <!--                                    <p class="text-grey mb-0">{{ company.feedRSS }}</p>-->
                                <!--                                </div>-->
                                <!--                                <hr class="w-100" />-->
                                <!--                                <div class="mb-3" *ngIf="consortia">-->
                                <!--                                    <p class="text-blue mb-1">Consorzi</p>-->
                                <!--                                    <p class="text-grey mb-0" *ngFor="let consortium of consortia">{{ consortium.name }}</p>-->
                                <!--                                </div>-->
                                <!--                                <div class="mb-3" *ngIf="certifications">-->
                                <!--                                    <p class="text-blue mb-1">Certificazioni</p>-->
                                <!--                                    <p class="text-grey mb-0" *ngFor="let cert of certifications">{{ cert.name }}</p>-->
                                <!--                                </div>-->
                                <!--                                <div class="mb-3" *ngIf="company.sector">-->
                                <!--                                    <p class="text-blue mb-1">Settore di Appartenenza</p>-->
                                <!--                                    <p class="text-grey mb-0">{{ company.sector }}</p>-->
                                <!--                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>

                <!-- PRODOTTI E SERVIZI OFFERTI -->
                <widget-product-service [companyId]="company.id" [editMode]="false"></widget-product-service>

                <!-- COLLABORAZIONI RECENTI -->
                <!--                <cooperation-search [itemToSelect]="4" *ngIf="!isPublic" [companyInfo]="company.id" [isOpen]="true"></cooperation-search>-->

                <!-- NEWS DELLA COOP -->
                <!--                <widget-news [companyId]="company.id" [showNumber]="4" *ngIf="!isPublic"></widget-news>-->
            </div>

            <!-- SIDEBAR DX -->
            <div class="col-lg-3">
                <div class="row">
                    <!-- TITOLO -->
                    <div class="col-12 mb-2 d-block d-lg-none">
                        <h2 class="text-blue text-uppercase" data-aos="fade-down">Informazioni di contatto</h2>
                    </div>

                    <!-- HEADER SIDEBAR WRAPPER -->
                    <div class="col-md-4 col-sm-6 col-xs-12 col-lg-12 col-xl-12 mb-5">
                        <!-- LOGO / NO LOGO -->
                        <div class="logo-wrapper" data-aos="fade-left" data-aos-delay="100">
                            <ng-container *ngIf="thumbnail; else noLogoTemplate">
                                <img class="coop-logo d-block mx-auto mw-100" *ngIf="thumbnail" [src]="thumbnail"
                                    [alt]="company.name" height="100" />
                            </ng-container>
                            <ng-template #noLogoTemplate>
                                <img class="coop-logo d-block mx-auto mw-100" src="/assets/images/no-image-coop.svg"
                                    height="100px" alt="Nessun logo per la cooperativa" />
                            </ng-template>
                        </div>

                        <!-- AVATAR PROFILO -->
                        <div class="col-12 mt-2 text-center mb-3" *ngIf="user" data-aos="fade-left"
                            data-aos-delay="150">
                            <ng-container *ngIf="user.avatarFileId; else elseTemplate">
                                <a class="coop-avatar" [routerLink]="['/area-riservata/user/' + user.id + '/edit']"
                                    routerLinkActive="active">
                                    <img [src]="thumbnailUser"
                                        class="img-thumbnail border-lightblue rounded-circle shadow-default p-0"
                                        height="60px" width="60px" alt="thumbnailUser" />
                                    <div class="text-center pl-4 ml-3 position-relative">
                                        <fa-icon [icon]="['fas', 'pencil-alt']" class="
                                                edit-avatar
                                                d-flex
                                                align-items-center
                                                justify-content-center
                                                mt-n3
                                                text-smaller
                                                rounded-circle
                                                shadow-default
                                                text-lightblue
                                                bg-white
                                                mx-auto
                                                border-lightblue
                                            "></fa-icon>
                                    </div>
                                </a>
                            </ng-container>
                            <ng-template #elseTemplate>
                                <a class="coop-avatar" [routerLink]="['/area-riservata/user/' + user.id + '/edit']"
                                    routerLinkActive="active">
                                    <ngx-avatar class="d-inline-block text-bold" [name]="user.surname + ' ' + user.name"
                                        size="50"></ngx-avatar>
                                    <div class="text-center pl-4 ml-3 position-relative">
                                        <fa-icon [icon]="['fas', 'pencil-alt']" class="
                                                edit-avatar
                                                d-flex
                                                align-items-center
                                                justify-content-center
                                                mt-n3
                                                text-smaller
                                                rounded-circle
                                                shadow-default
                                                text-lightblue
                                                bg-white
                                                mx-auto
                                                border-lightblue
                                            "></fa-icon>
                                    </div>
                                </a>
                            </ng-template>
                        </div>

                        <!-- TASTO MODIFICA -->
                        <div class="col-12 text-center" data-aos="fade-left" data-aos-delay="150">
                            <ng-container *ngIf="isEditable; else contactTemplate">
                                <button type="button" class="btn btn-main angle-bottom-sx shadow-default text-white"
                                    (click)="editCompany()">
                                    <fa-icon [icon]="['fas', 'pencil-alt']" class="mr-2"></fa-icon>
                                    <span class="text-uppercase text-smaller">Modifica scheda</span>
                                </button>
                            </ng-container>
                            <ng-template #contactTemplate>
                                <button class="btn btn-main angle-bottom-sx shadow-default contact-btn mb-2 mb-md-0"
                                    *ngIf="company.email" (click)="goToSendMail()">
                                    <fa-icon [icon]="['far', 'comments']" class="mr-2"></fa-icon>
                                    <span class="text-uppercase">Contatta</span>
                                </button>
                            </ng-template>
                        </div>
                    </div>

                    <!-- INFO DI CONTATTO -->
                    <div class="col-md-4 col-sm-6 col-xs-12 col-lg-12 col-xl-12 mb-4 mx-auto contact-infos"
                        data-aos="fade-left" data-aos-delay="200">
                        <!-- TITOLO -->
                        <p class="text-bold text-blue text-uppercase mb-2 d-none d-lg-block">Informazioni di contatto</p>

                        <div class="pl-2">
                            <!-- EMAIL -->
                            <div class="coop-email mb-3 d-flex flex-row align-items-center">
                                <fa-icon [icon]="['fas', 'envelope']"
                                    class="text-lightblue mr-2 d-inline-block infos-icon">
                                </fa-icon>
                                <span class="text-blue">
                                    <ng-container *ngIf="company.email; else noEmail">
                                        <a class="text-blue" [href]="'mailto:' + company.email">{{ company.email }}</a>
                                    </ng-container>
                                    <ng-template #noEmail>
                                        <span>-</span>
                                    </ng-template>
                                </span>
                            </div>

                            <!-- RECAPITI -->
                            <div class="coop-phone mb-3 d-flex flex-row align-items-start">
                                <fa-icon [icon]="['fas', 'phone-alt']"
                                    class="text-lightblue mr-2 d-inline-block infos-icon"></fa-icon>
                                <div>
                                    <p class="mb-0">
                                        <span class="text-blue d-inline-block mr-3">tel</span> <span
                                            class="text-blue">{{ company.telephone ? company.telephone : '-' }}</span>
                                    </p>
                                    <p class="mb-0">
                                        <span class="text-blue d-inline-block mr-3">fax</span> <span
                                            class="text-blue">{{ company.fax ? company.fax : '-' }}</span>
                                    </p>
                                    <p class="mb-0">
                                        <span class="text-blue d-inline-block mr-3">mobile</span>
                                        <span class="text-blue">{{ company.mobilePhone ? company.mobilePhone : '-'
                                            }}</span>
                                    </p>
                                </div>
                            </div>

                            <!-- WEBSITE -->
                            <div class="coop-website mb-3 d-flex flex-row align-items-center">
                                <fa-icon [icon]="['fas', 'globe']"
                                    class="text-lightblue mr-2 d-inline-block infos-icon">
                                </fa-icon>
                                <span class="text-blue">
                                    <ng-container *ngIf="company.website; else noWebsite">
                                        <a class="text-blue" [href]="goToWebSite(company.website)" target="_blank">{{
                                            company.website }}</a>
                                    </ng-container>
                                    <ng-template #noWebsite>
                                        <span>-</span>
                                    </ng-template>
                                </span>
                            </div>

                            <!-- INDIRIZZI -->
                            <div class="coop-address mb-3 d-flex flex-row align-items-center" *ngIf="mainAddress">
                                <fa-icon [icon]="['fas', 'map-marker-alt']"
                                    class="text-lightblue mr-2 d-inline-block infos-icon pl-1"></fa-icon>
                                <span class="text-blue">{{ mainAddress.address }}, {{ mainAddress.zipCode }} , {{
                                    mainAddress.municipality.name }} ({{
                                    mainAddress.municipality.province.abbreviation
                                    }})</span>
                            </div>
                        </div>
                    </div>

                    <!-- FILE ALLEGATI -->
                    <div data-aos="fade-left" data-aos-delay="250"
                        class="col-md-4 col-sm-12 col-xs-12 col-lg-12 col-xl-12 mb-5"
                        *ngIf="company.files && company.files.length > 0">
                        <p class="text-heading text-bold text-blue text-uppercase mb-2 d-block d-sm-none">Documenti
                            allegati</p>
                        <p class="text-bold text-blue text-uppercase mb-2 d-none d-sm-block">Documenti allegati</p>
                        <div class="d-flex flex-wrap attach-item" *ngFor="let file of company.files; let i = index">
                            <span class="attach-name">
                                {{ company.filesDescription[i] && company.filesDescription[i] != '' ?
                                company.filesDescription[i] : file.name }}
                            </span>
                            <span class="attach-size text-smaller text-blue">({{ file.size | filesize }})
                                <span class="cursor-pointer text-blue text-bigger attach-icon"
                                    (click)="downloadFile($event, file.ID, file.name)">
                                    <fa-icon icon="cloud-download-alt"></fa-icon>
                                </span>
                            </span>
                        </div>
                    </div>

                    <!-- PROFILI SOCIAL -->
                    <div data-aos="fade-left" data-aos-delay="250"
                        class="col-md-4 col-sm-12 col-xs-12 col-lg-12 col-xl-12 mb-5 coop-social-channels"
                        *ngIf="company.facebook || company.twitter || company.instagram || company.linkedin || company.youtube">
                        <!-- TITOLO -->
                        <div
                            class="d-flex flex-row align-items-center justify-content-between justify-content-md-start bg-white border-round-pill shadow-default py-2 position-relative">
                            <div>
                                <fa-icon [icon]="['fas', 'user-friends']"
                                    class="text-lightblue mr-2 d-inline-block infos-icon text-center"></fa-icon>
                                <span class="text-blue text-uppercase">Profili Social</span>
                            </div>
                            <!-- ICONE SOCIAL MOBILE -->
                            <div class="d-flex d-md-none flex-row align-items-center justify-content-end">
                                <span *ngIf="company.facebook">
                                    <a [href]="goToWebSite(company.facebook)" target="_blank" class="icon-social">
                                        <fa-icon [icon]="['fab', 'facebook']"></fa-icon>
                                    </a>
                                </span>
                                <span *ngIf="company.twitter">
                                    <a [href]="goToWebSite(company.twitter)" target="_blank" class="icon-social">
                                        <fa-icon [icon]="['fab', 'twitter']"></fa-icon>
                                    </a>
                                </span>
                                <span *ngIf="goToWebSite(company.instagram)">
                                    <a [href]="company.instagram" target="_blank" class="icon-social">
                                        <fa-icon [icon]="['fab', 'instagram']"></fa-icon>
                                    </a>
                                </span>
                                <span *ngIf="goToWebSite(company.linkedin)">
                                    <a [href]="company.linkedin" target="_blank" class="icon-social">
                                        <fa-icon [icon]="['fab', 'linkedin']"></fa-icon>
                                    </a>
                                </span>
                                <span *ngIf="company.youtube">
                                    <a [href]="goToWebSite(company.youtube)" target="_blank" class="icon-social">
                                        <fa-icon [icon]="['fab', 'youtube']"></fa-icon>
                                    </a>
                                </span>
                            </div>
                        </div>

                        <!-- ICONE SOCIAL -->
                        <div
                            class="social-icons-wrapper d-none d-md-flex flex-row align-items-center justify-content-start shadow-default pt-4 mt-n4 ml-2 ml-xl-3 mr-2 mr-xl-3 px-2 px-xl-3 pb-1">
                            <span *ngIf="company.facebook">
                                <a [href]="goToWebSite(company.facebook)" target="_blank" class="icon-social">
                                    <fa-icon [icon]="['fab', 'facebook']"></fa-icon>
                                </a>
                            </span>
                            <span *ngIf="company.twitter">
                                <a [href]="goToWebSite(company.twitter)" target="_blank" class="icon-social">
                                    <fa-icon [icon]="['fab', 'twitter']"></fa-icon>
                                </a>
                            </span>
                            <span *ngIf="company.instagram">
                                <a [href]="goToWebSite(company.instagram)" target="_blank" class="icon-social">
                                    <fa-icon [icon]="['fab', 'instagram']"></fa-icon>
                                </a>
                            </span>
                            <span *ngIf="company.linkedin">
                                <a [href]="goToWebSite(company.linkedin)" target="_blank" class="icon-social">
                                    <fa-icon [icon]="['fab', 'linkedin']"></fa-icon>
                                </a>
                            </span>
                            <span *ngIf="company.youtube">
                                <a [href]="goToWebSite(company.youtube)" target="_blank" class="icon-social">
                                    <fa-icon [icon]="['fab', 'youtube']"></fa-icon>
                                </a>
                            </span>
                        </div>
                    </div>

                    <!-- TERRITORI DI OPERATIVITÀ -->
                    <!--                    <div data-aos="fade-left" data-aos-once="true" class="col-md-4 col-sm-6 col-xs-12 col-lg-12 col-xl-12 mb-5" *ngIf="territories && territories.length > 0">-->
                    <!--                        &lt;!&ndash; TITOLO &ndash;&gt;-->
                    <!--                        <p class="text-heading text-bold text-blue text-bigger mb-2 d-block d-sm-none">Territori di operatività</p>-->
                    <!--                        <p class="text-bold text-blue text-bigger mb-2 d-none d-sm-block">Territori di operatività</p>-->
                    <!--                        &lt;!&ndash; LISTA &ndash;&gt;-->
                    <!--                        <div class="d-flex flex-wrap">-->
                    <!--                            <span-->
                    <!--                                *ngFor="let t of territories"-->
                    <!--                                class="border-round-pill border-green d-inline-block text-smaller py-1 px-2 text-bold text-uppercase text-green mr-1 mb-1"-->
                    <!--                                >{{ t.name }}</span-->
                    <!--                            >-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                    <!-- TAG -->
                    <div data-aos="fade-left" data-aos-once="true"
                        class="col-md-4 col-sm-6 col-xs-12 col-lg-12 col-xl-12 mb-5" *ngIf="tags && tags.length > 0">
                        <!-- TITOLO -->
                        <p class="text-heading text-bold text-blue text-bigger mb-2 d-block d-sm-none">Tag</p>
                        <p class="text-bold text-blue text-uppercase mb-2 d-none d-sm-block">Tag</p>
                        <!-- LISTA -->
                        <span *ngFor="let t of tags"
                            class="border-round-pill border-lightblue d-inline-block text-smaller py-1 px-2 text-bold text-uppercase text-lightblue mr-1 mb-1">{{
                            t.name
                            }}</span>
                    </div>

                    <!-- SOCI -->
                    <!--                    <div data-aos="fade-left" data-aos-once="true" class="col-md-4 col-sm-12 col-xs-12 col-lg-12 col-xl-12 mb-5" *ngIf="!isPublic">-->
                    <!--                        <widget-company-associates [companyId]="company.id" [showNumber]="3" [editMode]="false"></widget-company-associates>-->
                    <!--                    </div>-->
                </div>
            </div>
        </div>
    </div>
</ng-container>
