<div>
    <div class="document-single-item format-pdf position-relative mt-3" [ngClass]="viewMore ? 'view-more-info' : ''">
        <div class="side-sx text-center">
            <div class="file-type mb-2">DOCUMENTO</div>
            <fa-icon [icon]="document.file.icon"></fa-icon>
            <div class="file-format mt-2 text-upper">
                {{ document.file.extension }}
            </div>
        </div>
        <div class="side-dx">
            <h4 class="document-title font-pp">
                {{ document.name }}
            </h4>
            <p class="short-description font-ss mb-0 mt-2">
                {{ document.description | ellipsis: 100 }}
            </p>
            <p class="long-description font-ss mb-0 mt-2">
                {{ document.description | ellipsis: 400 }}
            </p>
            <hr />
            <div class="row file-infos font-ss mb-0 mt-2">
                <div class="col-4 text-center p-0">
                    <span><strong>Proprietario:</strong></span>
                    <span class="d-block ml-1">{{ document.createdByUser.fullName }}</span>
                </div>
                <div class="col-4 text-center p-0 file-date">
                    <span><strong>Data caricamento:</strong></span>
                    <span class="d-block ml-1">{{ document.created | date: 'dd/MM/yyyy HH:mm' }}</span>
                </div>
                <div class="col-4 text-center p-0 file-size">
                    <span><strong>Dimensioni:</strong></span>
                    <span class="d-block ml-1">{{ document.file.size | filesize }}</span>
                </div>
            </div>
            <hr class="visible-more-info" />
            <div class="down-icons text-center pl-0">
                <div class="left-icons float-left">
                    <a class="btn text-ss badge-btn btn-download float-left" (click)="download($event)">
                        <span class="d-inline-block icon-container">
                            <fa-icon icon="file-download"></fa-icon>
                            <mark>Scarica</mark>
                        </span>
                    </a>
                    <button class="btn text-ss badge-btn btn-share float-left" *ngIf="isOwner(document)" (click)="share($event)">
                        <span class="d-inline-block icon-container">
                            <fa-icon icon="share-alt"></fa-icon>
                            <mark>Condividi</mark>
                        </span>
                    </button>
                </div>
                <button class="btn text-ss badge-btn btn-view-more-info float-right" (click)="toggleCardInfo($event)">
                    <span class="d-inline-block icon-container">
                        <fa-icon icon="info-circle"></fa-icon>
                        <mark>Info</mark>
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>

<!-- CARD DOC -->
<!-- <div class="col-md-4">
            <div class="document-single-item format-doc position-relative mt-3">
                <div class="side-sx text-center">
                    <div class="file-type mb-2">
                        DOCUMENTO
                    </div>
                    <fa-icon icon="file-word"></fa-icon>
                    <div class="file-format mt-2">
                        DOC
                    </div>
                </div>
                <div class="side-dx">
                    <h4 class="document-title font-pp">Documento_completo.doc</h4>
                    <p class="short-description font-ss mb-0 mt-2">Lorem ipsum dolor sit amet consect adipisicing
                        elit.
                        Laborum corporis sed ut precipatis...</p>
                    <p class="long-description font-ss mb-0 mt-2">Lorem, ipsum dolor sit amet consectetur
                        adipisicing elit. In saepe suscipit quibusdam consequuntur voluptatem deserunt at fugit
                        commodi
                        nostrum consequatur eligendi asperiores ducimus inventore hic provident, atque?
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. In saepe suscipit quibusdam
                        consequuntur
                        voluptatem deserunt at fugit commodi nostrum consequatur eligendi asperiores ducimus
                        inventore
                        hic
                        provident.
                    </p>
                    <hr>
                    <div class="row file-infos font-ss mb-0 mt-2">
                        <div class="col-4 text-center p-0">
                            <span><strong>Proprietario:</strong></span>
                            <span class="d-block ml-1">Prof. Rossi</span>
                        </div>
                        <div class="col-4 text-center p-0 file-date">
                            <span><strong>Data:</strong></span>
                            <span class="d-block ml-1">12/07/2019</span>
                        </div>
                        <div class="col-4 text-center p-0 file-size">
                            <span><strong>Dimensioni:</strong></span>
                            <span class="d-block ml-1">973KB</span>
                        </div>
                    </div>
                    <hr class="visible-more-info">
                    <div class="down-icons text-center pl-0">
                        <div class="left-icons float-left">
                            <a href="javascript:void(0)" class="btn text-ss badge-btn btn-download float-left">
                                <span class="d-inline-block icon-container">
                                    <fa-icon icon="file-download"></fa-icon>
                                    <mark>Scarica</mark>
                                </span>
                            </a>
                            <button class="btn text-ss badge-btn btn-share float-left">
                                <span class="d-inline-block icon-container">
                                    <fa-icon icon="share-alt"></fa-icon>
                                    <mark>Condividi</mark>
                                </span>
                            </button>
                        </div>
                        <button class="btn text-ss badge-btn btn-view-more-info float-right" (click)="toggleCardInfo()">
                            <span class="d-inline-block icon-container">
                                <fa-icon icon="info-circle"></fa-icon>
                                <mark>Info</mark>
                            </span>
                        </button>
                    </div>

                </div>
            </div>
        </div> -->

<!-- CARD XLS -->
<!-- <div class="col-md-4">
            <div class="document-single-item format-xls position-relative mt-3">
                <div class="side-sx text-center">
                    <div class="file-type mb-2">
                        DOCUMENTO
                    </div>
                    <fa-icon icon="file-excel"></fa-icon>
                    <div class="file-format mt-2">
                        XLS
                    </div>
                </div>
                <div class="side-dx">
                    <h4 class="document-title font-pp">Foglio di Calcolo.xls</h4>
                    <p class="short-description font-ss mb-0 mt-2">Lorem ipsum dolor sit amet consect adipisicing
                        elit.
                        Laborum corporis sed ut precipatis...</p>
                    <p class="long-description font-ss mb-0 mt-2">Lorem, ipsum dolor sit amet consectetur
                        adipisicing elit. In saepe suscipit quibusdam consequuntur voluptatem deserunt at fugit
                        commodi
                        nostrum consequatur eligendi asperiores ducimus inventore hic provident, atque?
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. In saepe suscipit quibusdam
                        consequuntur
                        voluptatem deserunt at fugit commodi nostrum consequatur eligendi asperiores ducimus
                        inventore
                        hic
                        provident.
                    </p>
                    <hr>
                    <div class="row file-infos font-ss mb-0 mt-2">
                        <div class="col-4 text-center p-0">
                            <span><strong>Proprietario:</strong></span>
                            <span class="d-block ml-1">Prof. Bianchi</span>
                        </div>
                        <div class="col-4 text-center p-0 file-date">
                            <span><strong>Data:</strong></span>
                            <span class="d-block ml-1">29/06/2019</span>
                        </div>
                        <div class="col-4 text-center p-0 file-size">
                            <span><strong>Dimensioni:</strong></span>
                            <span class="d-block ml-1">470KB</span>
                        </div>
                    </div>
                    <hr class="visible-more-info">
                    <div class="down-icons text-center pl-0">
                        <div class="left-icons float-left">
                            <a href="javascript:void(0)" class="btn text-ss badge-btn btn-download float-left">
                                <span class="d-inline-block icon-container">
                                    <fa-icon icon="file-download"></fa-icon>
                                    <mark>Scarica</mark>
                                </span>
                            </a>
                            <button class="btn text-ss badge-btn btn-share float-left">
                                <span class="d-inline-block icon-container">
                                    <fa-icon icon="share-alt"></fa-icon>
                                    <mark>Condividi</mark>
                                </span>
                            </button>
                        </div>
                        <button class="btn text-ss badge-btn btn-view-more-info float-right" (click)="toggleCardInfo()">
                            <span class="d-inline-block icon-container">
                                <fa-icon icon="info-circle"></fa-icon>
                                <mark>Info</mark>
                            </span>
                        </button>
                    </div>

                </div>
            </div>
        </div> -->

<!-- PER ALTRI FORMATI (XLS / DOC) -> MODIFICARE LA CLASSE FORMATO-> ( PDF / DOC / XLS ) -->
