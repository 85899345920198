// @angular
import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// External libs
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

// App Model
import { CompanyRequestMasks } from 'src/app/models/company-request-masks.model';
import { User } from 'src/app/models/user.model';
import { CustomError } from 'src/app/models/custom-error.model';
import { UserRole } from 'src/app/models/user-role.model';

// App Services
import { BaseService } from './base.service';

@Injectable()
export class CompanyRequestMasksService extends BaseService {
    private CompanyRequestMasksCache: { [id: string]: CompanyRequestMasks } = {};

    @Output() currentEditCompanyRequestMasks$: BehaviorSubject<CompanyRequestMasks> = new BehaviorSubject<CompanyRequestMasks>(null);

    constructor(private http: HttpClient) {
        super(http);
    }

    public getAll(): Observable<CompanyRequestMasks[]> {
        const url = `${this.baseUrl}campaign/request-masks`;
        return this.http.get<CompanyRequestMasks[]>(url);
    }

    public getById(requestId: string): Observable<CompanyRequestMasks> {
        const url = `${this.baseUrl}campaign/request-masks/${requestId}`;
        return this.http.get<CompanyRequestMasks>(url).pipe(
            tap((c) => {
                this.currentEditCompanyRequestMasks$.next(c);
            })
        );
    }

    public getByCampaign(campaignId: string): Observable<CompanyRequestMasks[]> {
        const url = `${this.baseUrl}campaign/request-masks/campaigns/${campaignId}`;
        return this.http.get<CompanyRequestMasks[]>(url);
    }

    public getByCompany(companyId: string): Observable<CompanyRequestMasks[]> {
        const url = `${this.baseUrl}campaign/request-masks/company/${companyId}`;
        return this.http.get<CompanyRequestMasks[]>(url);
    }

    public getByCampaigns(request: string[]): Observable<CompanyRequestMasks[]> {
        if (!request) {
            throw Observable.throw('Informazioni sulle campagne non valide');
        }
        const url = `${this.baseUrl}campaign/request-masks/campaigns`;
        return this.http.post<CompanyRequestMasks[]>(url, request);
    }

    public getByCampaignAndCompany(campaignId: string, companyId: string): Observable<CompanyRequestMasks[]> {
        const url = `${this.baseUrl}campaign/request-masks/${campaignId}/${companyId}`;
        return this.http.get<CompanyRequestMasks[]>(url);
    }

    public fetchById(requestId: string): Observable<CompanyRequestMasks> {
        const url = `${this.baseUrl}campaign/request-masks/${requestId}`;
        return this.http
            .get<CompanyRequestMasks>(url)
            .map((c) => {
                this.currentEditCompanyRequestMasks$.next(c);
                this.CompanyRequestMasksCache[c.id] = c;
                return c;
            })
            .share();
    }
    /*
    public getByUser(userid: string): Observable<CompanyRequestMasks[]> {
        const url = `${this.baseUrl}campaign/request-masks/user/${userid}`;
        return this.http.get<CompanyRequestMasks[]>(url);
    }
*/
    public update(request: CompanyRequestMasks): Observable<CompanyRequestMasks> {
        if (!request) {
            throw Observable.throw('Informazioni sulla richiesta non valide');
        }
        const url = `${this.baseUrl}campaign/request-masks/update`;
        return this.http.post<CompanyRequestMasks>(url, request);
    }

    public updateAll(requests: CompanyRequestMasks[]): Observable<CompanyRequestMasks[]> {
        if (!requests) {
            throw Observable.throw('Informazioni sulle richieste non valide');
        }
        const url = `${this.baseUrl}campaign/request-masks/update/all`;
        return this.http.post<CompanyRequestMasks[]>(url, requests);
    }

    public create(request: CompanyRequestMasks): Observable<CompanyRequestMasks> {
        if (!request) {
            throw Observable.throw('Informazioni sulla richiesta non valide');
        }
        const url = `${this.baseUrl}campaign/request-masks/create`;
        return this.http.post<CompanyRequestMasks>(url, request);
    }
}
