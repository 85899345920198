export class CooperationFilter {
    searchText: string;
    coopTypes: Array<number>;
    draft?: boolean;
    private?: boolean;
    approved?: boolean;
    productService: Array<number>;
    companyId: string;
    pageSize: number;
    userId: string;
}
