<ng-container *ngIf="productServices">
    <div id="prodotti-piu-visti" class="container mt-5 pt-5 mb-5 pb-5">
        <h1 class="text-heading text-bold text-blue text-bigger mt-2 mb-4" data-aos="fade-right">Servizi e prodotti <span class="font-underliner">più visti</span></h1>
        <div class="services-wrapper row justify-content-center justify-content-md-start" data-aos="fade-up" data-aos-delay="200">
            <ng-container *ngFor="let ps of productServices">
                <div class="single-service shadow-default pl-4 pr-4 pt-2 pb-2 mb-3">
                    <a [routerLink]="['/company/results/productservice', ps.id]">
                        <product-service-image [ps]="ps"></product-service-image>
                        <p class="text-blue text-uppercase text-center mb-0">{{ ps.name }}</p>
                    </a>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
