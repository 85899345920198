import { News } from 'src/app/models/news.model';
import { NewsService } from 'src/app/services/resources/news.service';
import { FileService } from 'src/app/services/resources/file.service';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

export class NewsExtended extends News {
    label: string;
    image$: Observable<SafeStyle>;

    constructor(n: News, newsService: NewsService, fileService: FileService, sanitizer: DomSanitizer, companyId: string = null) {
        super();
        Object.assign(this, n);
        if (n.origin == 'home public' || n.origin == 'home private') {
            this.label = "Dall'associazione";
        }
        if (companyId && n.companies?.some((c) => c.id == companyId)) {
            this.label = 'Le mie news';
        }
        if (companyId && n.companies?.every((c) => c.id != companyId)) {
            this.label = 'Dalle cooperative';
        }

        if (n.image) {
            if (parseInt(n.image) > 0) {
                this.image$ = fileService.getImageThumbnail(parseInt(n.image)).pipe(
                    map((i) => {
                        return sanitizer.bypassSecurityTrustStyle(`url("data:image/jpeg;base64,${i}")`);
                    })
                );
            } else {
                this.image$ = newsService.getImageNews(n.id).pipe(
                    map((i) => {
                        return sanitizer.bypassSecurityTrustStyle(`url("${i}")`);
                    })
                );
            }
        }
    }
}
