import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ellipsis',
    pure: true,
})
export class EllipsisPipe implements PipeTransform {
    transform(text: string, length: number = 100) {
        text = text || '';
        if (text) {
            const result = text.substring(0, length) + '...';
            return result;
        }

        return text;
    }
}
