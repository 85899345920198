// @angular
import { Injectable } from '@angular/core';

// External libs
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    private _loading = false;
    loadingStatus: Subject<boolean> = new Subject();

    constructor() {}

    get loading(): boolean {
        return this._loading;
    }

    set loading(value) {
        this._loading = value;
        this.loadingStatus.next(value);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
