//@angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

//External libs
import { combineLatest, Subscription } from 'rxjs';

//App Helper
import { RolesEnum } from 'src/app/helpers/roles.enum';

// App Services
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { AuthService } from 'src/app/services/resources/auth.service';
import { UserService } from 'src/app/services/resources/user.service';

@Component({
    selector: 'widget-login-button',
    templateUrl: './widget-login-button.component.html',
    styleUrls: ['./widget-login-button.component.css'],
})
export class WidgetLoginButtonComponent implements OnInit, OnDestroy {
    isPublic = true;
    userId: string;
    triggerTogglerMenu: HTMLElement = document.querySelector('#navbar .navbar-toggler');
    private subscription: Subscription = new Subscription();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private navigationService: NavigationService,
        private authService: AuthService
    ) {}

    ngOnInit() {
        //console.log('WidgetLoginButtonComponent - ngOnInit!!!');

        const sub = combineLatest([this.authService.currentMode$, this.userService.currentUser$]).subscribe(([mode, user]) => {
            if (mode == 'public' && user) this.isPublic = true;
            else if (mode == 'private' && user) {
                this.isPublic = false;
            }

            if (user) {
                if (this.authService.currentUser) {
                    this.userId = this.authService.currentUser.id;
                }
            }
        });
        this.subscription.add(sub);
        // console.log("Utente loggato? -> " + this.authService.isLoggedIn);
    }

    ngOnDestroy(): void {
        //console.log('WidgetLoginButtonComponent - ngOnDestroy!!!');
        this.subscription.unsubscribe();
    }

    logOut() {
        this.authService.logout();
        this.router.navigate(['/']);
        // this.navigationService.init();
    }

    navigatetoPrivate() {
        console.log('navigate');

        const user = this.userService.currentUser$?.getValue();
        const checkRole = this.authService.isAdministrator$.getValue();

        if (user) {
            this.authService.setModalitaSito('private');
            if (checkRole === RolesEnum.Administrator) {
                this.router.navigate(['/area-riservata/cooperations']);
            } else {
                const companyid = this.authService.companyId$.getValue();
                this.authService.companyId$.next(companyid);
                this.router.navigate(['/area-riservata/company', companyid, 'info']);
            }
            this.isPublic = false;
        } else {
            this.router.navigate(['/login']);
        }
    }

    triggerMenuBarMobile() {
        if (window.innerWidth <= 991) {
            this.triggerTogglerMenu = document.querySelector('#navbar .navbar-toggler');
            this.triggerTogglerMenu.click();
        }
    }
}
