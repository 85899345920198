export class CustomError {
    code: number;
    error: { message: string };
    message: string;
    exception: any;

    constructor(code: number, message: string) {
        this.code = code;
        this.message = message;
        this.error = { message };
    }
}
