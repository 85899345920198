<div class="container d-flex flex-column flex-md-row registration-form-wrapper position-relative mb-5 pb-5" *ngIf="isLogged === false">
    <div class="w-100 d-block d-md-none">
        <h3 class="mt-5 text-bigger text-heading text-blue text-left">Registrazione</h3>
        <p class="mb-1 text-dark text-left">Completa la registrazione compilando i campi che seguono</p>
    </div>
    <div class="order-last order-md-first input-group mb-4 container" *ngIf="isValid; else errorMessage">
        <div class="w-100 d-none d-md-block">
            <h3 class="mt-5 text-bigger text-heading text-blue text-left">Registrazione</h3>
            <p class="mb-5 text-dark text-left">Completa la registrazione compilando i campi che seguono</p>
        </div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="mx-auto ml-md-0">
            <div class="form-group mb-0 round-switch-wrapper registration-switch">
                <div class="form-check pl-0">
                    <label class="form-check-label cursor-pointer mt-1 mb-4" for="IsPersonaFisica">
                        <input class="form-check-input custom-control-input round-switch d-none" type="checkbox" formControlName="userSelector" id="IsPersonaFisica" />
                        <span class="switch-side-selection left-side text-grey d-inline-block mr-2"> Persona Giuridica </span>
                        <div class="switch-registration-type round-switch-button d-inline-block cursor-pointer">
                            <fa-icon icon="chevron-right" class="round-switch-icon"></fa-icon>
                        </div>
                        <span class="switch-side-selection right-side text-grey d-inline-block ml-2"> Persona Fisica </span>
                    </label>
                </div>
            </div>

            <div id="user-is-person" *ngIf="userSelector.value">
                <label for="input-user-name"></label>
                <input formControlName="name" id="input-user-name" type="text" placeholder="Nome *" class="form-control custom-input" />
                <div *ngIf="!form.controls.name.valid">
                    <span *ngIf="form.controls.name.errors.required" class="text-danger"> Il nome è obbligatorio </span>
                    <span *ngIf="form.controls.name.errors.pattern" class="text-danger"> Inserisci un nome valido </span>
                </div>
                <label for="input-user-surname"></label>
                <input formControlName="surname" id="input-user-surname" type="text" placeholder="Cognome *" class="form-control custom-input" />
                <div *ngIf="!form.controls.surname.valid">
                    <span *ngIf="form.controls.surname.errors.required" class="text-danger"> Il cognome è obbligatorio </span>
                    <span *ngIf="form.controls.surname.errors.pattern" class="text-danger"> Inserisci un cognome valido </span>
                </div>
            </div>
            <div id="user-is-company" *ngIf="!userSelector.value">
                <label for="input-user-businessName"></label>
                <input formControlName="businessName" id="input-user-businessName" type="text" placeholder="Ragione Sociale" class="form-control custom-input" />
                <span *ngIf="!form.controls.businessName.valid" class="text-danger"> La ragione sociale è obbligatoria </span>
            </div>

            <label for="input-fiscal-code"></label>
            <input formControlName="fiscalCode" id="input-fiscal-code" type="text" placeholder="Codice Fiscale" class="form-control custom-input" (input)="toUppercase($event)" />
            <span *ngIf="form.controls.fiscalCode.errors?.pattern" class="text-danger"> Inserisci un codice fiscale valido </span>
            <label for="input-user-phone"></label>
            <input formControlName="phoneNumber" id="input-user-phone" type="tel" placeholder="Telefono" class="form-control custom-input" />
            <div *ngIf="!form.controls.phoneNumber.valid">
                <span *ngIf="form.controls.phoneNumber.errors.required" class="text-danger"> Il numero di telefono è obbligatorio </span>
                <span *ngIf="form.controls.phoneNumber.errors.pattern" class="text-danger"> Inserisci un numero valido </span>
            </div>
            <label for="input-user-email"></label>
            <input formControlName="email" id="input-user-email" type="email" placeholder="Email" class="form-control custom-input" />
            <div *ngIf="!form.controls.email.valid">
                <span *ngIf="form.controls.email.errors.required" class="text-danger"> L'email è obbligatoria. </span>
                <span *ngIf="form.controls.email.errors.email" class="text-danger"> Inserisci un email valida. </span>
            </div>

            <div class="text-center">
                <button type="submit" class="btn btn-main angle-bottom-sx shadow-default mt-4" id="btn-register">
                    <fa-icon icon="user-plus" class="mr-3"></fa-icon>
                    <span class="text-uppercase">Registrati</span>
                </button>
            </div>
        </form>
    </div>
    <img src="/assets/images/img-registration.svg" class="registration-form-image mb-5 mb-md-0" alt="" />
</div>

<ng-template #errorMessage>
    <div class="registration-not-valid container text-center">
        <img src="/assets/images/invitononvalido.svg" class="registration-not-valid-image" alt="Registrazione non valida" />
        <h3 class="mt-3 text-bigger text-heading text-blue">Link di invito non valido</h3>
        <h4 class="mb-5 text-dark">Purtroppo si è verificato un errore con il link d'invito.</h4>
    </div>
</ng-template>
<div *ngIf="isLogged" class="mt-5 mb-5 pt-5 pb-5 container">
    <h3 class="text-heading text-blue text-center">
        Sei già loggato, effettua il logout e clicca sul link ricevuto via email per registrare un nuovo account, oppure torna alla home.
    </h3>
</div>
