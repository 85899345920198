// @angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { FormControl, FormGroup } from '@angular/forms';

// External libs
import { combineLatest, Subscription } from 'rxjs';

// App Helpers
import { environment } from 'src/environments/environment';
import { RolesEnum } from 'src/app/helpers/roles.enum';

// App Models
import { NewsExtended } from 'src/app/models/news-extend.model';
import { QueryNews } from 'src/app/models/query-news.model';

// App Services
import { UserService } from 'src/app/services/resources/user.service';
import { NewsService } from 'src/app/services/resources/news.service';
import { FileService } from 'src/app/services/resources/file.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { AuthService } from 'src/app/services/resources/auth.service';

@Component({
    selector: 'news-list',
    templateUrl: './news-list.component.html',
    styleUrls: ['./news-list.component.css'],
})
export class NewsListComponent implements OnInit, OnDestroy {
    companyId: string;
    isPrivate: boolean;
    news: Array<NewsExtended>;
    allNews: Array<NewsExtended>;
    private subscription = new Subscription();
    form: FormGroup;
    isShowed: any;
    showButton: boolean;
    totalOccurance: number;
    query = new QueryNews();
    isMoreThanThree: boolean;
    isAdmin: Boolean = false;

    constructor(
        private authService: AuthService,
        private userService: UserService,
        private newsService: NewsService,
        private fileService: FileService,
        private navigationService: NavigationService,
        private titleService: Title,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit(): void {
        console.log('NewsListComponent - ngOnInit!!');

        let sub = this.authService.isAdministrator$.subscribe((v) => {
            if (v === RolesEnum.Administrator) {
                this.isAdmin = true;
            }
        });
        this.subscription.add(sub);

        this.titleService.setTitle(`${environment.appName} - Notizie`);

        this.form = new FormGroup({
            searchByCompanyName: new FormControl(),
        });

        sub = combineLatest([this.authService.currentMode$, this.userService.currentUser$]).subscribe(([mode, user]) => {
            console.log('modeinsubscription', mode);

            if (user) {
                if (mode === 'public') {
                    this.isPrivate = false;
                }
                if (mode === 'private') {
                    this.isPrivate = true;
                }
            } else {
                this.isPrivate = false;
            }

            this.query = this.query || new QueryNews();
            this.query.isHome = !this.isPrivate;

            /*if (!this.isPrivate) {
                this.getRssNews();
            } else {*/
                this.getFilteredNews();
            //}
        });
        this.subscription.add(sub);

        this.companyId = this.authService.companyId$.getValue();

        this.query = new QueryNews();

        if (!this.isPrivate) {
            this.query.isHome = true;
        }

        /*if (!this.isPrivate) {
            this.getRssNews();
        } else {*/
            this.getFilteredNews();
        //}

        sub = this.form.get('searchByCompanyName')?.valueChanges.subscribe((x: string) => {
            this.isMoreThanThree = x.length > 2;
        });
        this.subscription.add(sub);
    }

    all() {
        this.isShowed = false;
        this.query = new QueryNews();
        if (!this.isPrivate) {
            this.query.isHome = true;
        }
        /*if (!this.isPrivate) {
            this.getRssNews();
        } else {*/
            this.getFilteredNews();
        //}
    }

    fromAssociation() {
        this.isShowed = false;
        this.query = new QueryNews();
        this.query.isFromAssociation = true;
        this.getFilteredNews();
    }

    myNews() {
        this.isShowed = false;
        this.query = new QueryNews();
        this.query.companyId = this.companyId;
        this.getFilteredNews();
    }

    showInputSearch() {
        this.isShowed = true;
    }

    OnScroll() {
        if (this.query.pageSize < this.totalOccurance) {
            this.query.pageSize += this.query.pageSize;
            this.getFilteredNews();
        }
    }

    OnSubmit() {
        this.query = new QueryNews();
        this.query.searchByName = this.form.get('searchByCompanyName').value;
        this.getFilteredNews();
    }

    getFilteredNews() {
        const sub = this.newsService.filterNews(this.query).subscribe((paginatedList) => {
            {
                this.totalOccurance = paginatedList.totalRows;
                this.news = paginatedList.rows.map((n) => {
                    if (n.title.length >= 120) {
                        n.title = n.title.substring(0, 117).concat('...');
                    }
                    if (n.summary.length >= 150) {
                        n.summary = n.summary.substring(0, 147).concat('...');
                    }
                    return new NewsExtended(n, this.newsService, this.fileService, this.sanitizer, this.companyId);
                });
                console.log('I found this news ', paginatedList.rows);
                console.log('with this parameter ', this.query);
            }
        });
        this.subscription.add(sub);
    }

    getRssNews() {
        const pageSize = 10;
        const sub = this.newsService.getNewsRSS(pageSize).subscribe((paginatedList) => {
            {
                this.totalOccurance = paginatedList.totalRows;
                this.news = paginatedList.rows.map((n) => {
                    if (n.title.length >= 120) {
                        n.title = n.title.substring(0, 117).concat('...');
                    }
                    if (n.summary.length >= 150) {
                        n.summary = n.summary.substring(0, 147).concat('...');
                    }
                    return new NewsExtended(n, this.newsService, this.fileService, this.sanitizer, this.companyId);
                });
                console.log('I found this news ', paginatedList.rows);
            }
        });
        this.subscription.add(sub);
    }

    ngOnDestroy(): void {
        console.log('NewsListComponent - ngOnDestroy!!');
        this.subscription.unsubscribe();
    }
}
