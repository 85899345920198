//@angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// External libs
import { Observable } from 'rxjs';
//App Model
import { Invitation } from 'src/app/models/invitation.model';
//App Service
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root',
})
export class InvitationService extends BaseService {
    constructor(private http: HttpClient) {
        super(http);
    }

    public getById(id: string): Observable<Invitation> {
        return this.http.get<Invitation>(this.baseUrl + 'invitation/' + id);
    }
}
