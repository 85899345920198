// @angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// External libs
import { Observable } from 'rxjs';

// App Models
import { News } from 'src/app/models/news.model';
import { QueryNews } from 'src/app/models/query-news.model';
import { GenericPager } from 'src/app/models/generic-pager.model';

// App Services
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root',
})
export class NewsService extends BaseService {
    constructor(private http: HttpClient) {
        super(http);
    }

    public getNews(): Observable<News[]> {
        return this.http.get<News[]>(this.baseUrl + 'news/home');
    }

    public filterNews(query: QueryNews): Observable<GenericPager<News>> {
        return this.http.post<GenericPager<News>>(this.baseUrl + 'news', query);
    }

    public getImageNews(newsId: string): Observable<string> {
        return this.http.get<any>(this.baseUrl + 'news/' + newsId + '/image');
    }

    public getNewsRSS(pageSize: number): Observable<GenericPager<News>> {
        const query = {
            pageSize: pageSize,
            feedUrl: this.feedUrl
        };
        return this.http.post<GenericPager<News>>(this.baseUrl + 'news/home/rss', query);
    }
}
