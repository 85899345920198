import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'company-search',
    templateUrl: './company-search.component.html',
    styleUrls: ['./company-search.component.css'],
})
export class CompanySearchComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
