// @angular
import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeItExtra from '@angular/common/locales/extra/it';
import localeIt from '@angular/common/locales/it';
import { ErrorHandler, isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';

// External libs
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab as FontAwesomeBrands } from '@fortawesome/free-brands-svg-icons';
import { far as FontAwesomeRegular } from '@fortawesome/free-regular-svg-icons';
import { fas as FontAwesomeSolid } from '@fortawesome/free-solid-svg-icons';
import { ToastrModule } from 'ngx-toastr';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AvatarModule } from 'ngx-avatar';
import {
    AngularGlobalizeModule, // This modules export pipes for formatting date, number and currency.
    CANG_SUPPORTED_CULTURES, // This import is needed to provide the languages your application support
} from '@code-art/angular-globalize';

// App Helpers
import { environment } from 'src/environments/environment';
import { BlockCopyPasteDirective } from 'src/app/helpers/copy-paste-blocker.directive';
import { DateHttpInterceptor } from 'src/app/helpers/date-http.interceptor';
import { ErrorsInterceptor } from 'src/app/helpers/errors.interceptor';
import { JwtInterceptor } from 'src/app/helpers/jwt.interceptor';
import { LoadingInterceptor } from 'src/app/helpers/loading.interceptor';

// App Modules
import { AppRoutingModule } from 'src/app/app-routing.module';
import { ServicesModule } from 'src/app/services/services.module';
import { SharedModule } from 'src/app/modules/shared/shared.module';

// App Components
import { AppComponent } from './app.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MenuComponent } from './components/menu/menu.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { EmailConfirmComponent } from './components/email-confirm/email-confirm.component';
import { ResetPasswordModalComponent } from './components/reset-password-modal/reset-password-modal.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SearchWidgetCompanyComponent } from './components/search-widget-company/search-widget-company.component';
import { SearchMapCompanyComponent } from './components/search-map-company/search-map-company.component';
import { InitiativeComponent } from './components/initiative/initiative.component';
import { NetworkComponent } from './components/network/network.component';
import { CompanyPickerComponent } from './components/company-picker/company-picker.component';
import { RegisterComponent } from './components/register/register.component';
import { FooterComponent } from './components/footer/footer.component';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CooperationDashboardComponent } from './modules/area-riservata/components/cooperation-dashboard/cooperation-dashboard.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NewsComponent } from './components/news/news.component';
import { loadCldrData } from 'src/app/helpers/load-cldr-globalize';
import { MatCheckboxModule } from '@angular/material/checkbox';

registerLocaleData(localeIt, 'it');
registerLocaleData(localeItExtra, 'it-IT', localeItExtra);

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-right',
            autoDismiss: false,
            newestOnTop: true,
            preventDuplicates: true,
            resetTimeoutOnDuplicate: true,
        }),
        BsDropdownModule.forRoot(),
        AppRoutingModule,
        ServicesModule.forRoot(),
        SharedModule.forRoot(),
        MatSlideToggleModule,
        MatProgressBarModule,
        MatCardModule,
        InfiniteScrollModule,
        OverlayModule,
        AngularGlobalizeModule.forRoot(),
        AvatarModule,
        MatCheckboxModule,
    ],
    declarations: [
        AppComponent,
        BlockCopyPasteDirective,
        NotFoundComponent,
        LoadingComponent,
        MenuComponent,
        LoginComponent,
        EmailConfirmComponent,
        ResetPasswordModalComponent,
        ResetPasswordComponent,
        HomeComponent,
        RegisterComponent,
        SearchWidgetCompanyComponent,
        SearchMapCompanyComponent,
        InitiativeComponent,
        NetworkComponent,
        CompanyPickerComponent,
        FooterComponent,
        NewsComponent,
        CooperationDashboardComponent,
    ],
    entryComponents: [ResetPasswordModalComponent, CompanyPickerComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DateHttpInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: ErrorsInterceptor,
        },
        Title,
        DatePipe,
        { provide: LOCALE_ID, useValue: 'it' },
        { provide: CANG_SUPPORTED_CULTURES, useValue: 'it' },
    ],
    bootstrap: [AppComponent],
    exports: [],
})
export class AppModule {
    constructor() {
        console.log('AppModule constructor!');
        library.add(FontAwesomeSolid);
        library.add(FontAwesomeRegular);
        library.add(FontAwesomeBrands);
        loadCldrData();

        if (isDevMode()) {
            console.log('Development!');
        } else {
            console.log('Production!');
            window.console.log = () => {};
        }

        console.log('Environment baseApiUrl: ', environment.baseApiUrl);
    }
}
