// @angular
import { Component, Input, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';

// External libs
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// App Models
import { Company } from 'src/app/models/company.model';
import { User } from 'src/app/models/user.model';

// App Services
import { CompanyService } from 'src/app/services/resources/company.service';
import { UserService } from 'src/app/services/resources/user.service';
import { RoleService } from 'src/app/services/resources/role.service';
import { Role } from 'src/app/models/role.model';
import { UserRole } from 'src/app/models/user-role.model';

// App Component
import { WidgetCompanyUsersComponent } from '../widget-company-users/widget-company-users.component';

@Component({
    selector: 'widget-company-associates',
    templateUrl: './widget-company-associates.component.html',
    styleUrls: ['./widget-company-associates.component.css'],
})
export class WidgetCompanyAssociatesComponent implements OnInit {
    @Input('companyInfo') company: Company;
    @Input('companyId') companyid: string;
    @Input('showNumber') showNumber: number;
    @Input('editMode') editMode: boolean;

    users: User[];
    showUsers: User[];
    allUsers: UserRole[];
    allRoles: Role[];
    availableRoles: Role[];

    @ViewChild('AllAssociates', { static: true }) AllAssociates: TemplateRef<ElementRef>;

    private subscription: Subscription = new Subscription();

    constructor(private modalService: NgbModal, private companyService: CompanyService, private roleService: RoleService, private userService: UserService) {}

    ngOnInit() {
        //console.log('WidgetCompanyAssociatesComponent - ngOnInit!!!');
        if (!this.company) {
            if (this.companyid) {
                const sub = this.companyService.getAssociates(this.companyid).subscribe((users) => {
                    this.users = users;
                    //console.log('Users: ', this.users);
                    if (users && users.length > 0) {
                        if (this.showNumber) {
                            this.showUsers = users.slice(0, this.showNumber);
                        } else {
                            this.showUsers = users;
                        }
                    }
                });
                this.subscription.add(sub);

                const userRoleSub = this.companyService.getUsers(this.companyid).subscribe((data) => (this.allUsers = data));
                this.subscription.add(userRoleSub);

                const roleSub = this.roleService.getAll().subscribe((data) => (this.allRoles = data));
                this.subscription.add(roleSub);
            }
        }
    }

    ngOnDestroy(): void {
        //console.log('WidgetCompanyAssociatesComponent - ngOnDestroy!!!');
        this.subscription.unsubscribe();
    }

    showAll() {
        const modalRef = this.modalService.open(this.AllAssociates, { backdrop: true, size: 'lg' });
        modalRef.componentInstance.users = this.users;
    }

    showUserModal() {
        const currentUser = this.userService.currentUser$.getValue();
        const currentRoles = currentUser.roles.filter((r) => r.companies.some((c) => c == this.companyid));
        const minLevel = currentRoles.reduce((min, r) => (r.role.permissionLevel < min ? r.role.permissionLevel : min), currentUser.roles[0].role.permissionLevel);
        this.availableRoles = this.allRoles.filter((r) => r.permissionLevel >= minLevel);

        const modalRef = this.modalService.open(WidgetCompanyUsersComponent, { backdrop: true, size: 'xl' });
        modalRef.componentInstance.companyId = this.companyid;
        modalRef.componentInstance.users = this.allUsers;
        modalRef.componentInstance.roles = this.availableRoles;
        modalRef.result.then(
            () => {
                const sub = this.companyService.getAssociates(this.companyid).subscribe((data) => {
                    this.users = data;

                    if (data && data.length > 0) {
                        if (this.showNumber) {
                            this.showUsers = data.slice(0, this.showNumber);
                        } else {
                            this.showUsers = data;
                        }
                    }
                });
                this.subscription.add(sub);
            },
            (reason) => {
                console.log(reason);
            }
        );
    }
}
