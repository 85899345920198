// @angular
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

// External libs
import { Observable, of, Subscription } from 'rxjs';

// App Helpers
import { environment } from 'src/environments/environment';

// App Services
import { AuthService } from 'src/app/services/resources/auth.service';
import { UserService } from 'src/app/services/resources/user.service';

@Component({
    selector: 'email-confirm',
    templateUrl: './email-confirm.component.html',
})
export class EmailConfirmComponent implements OnInit, OnDestroy {
    ID: string;
    code: string;
    result$: Observable<boolean>;
    message$: Observable<string>;

    // Subscriptions
    private subscriptions: Subscription = new Subscription();

    constructor(private route: ActivatedRoute, private router: Router, private titleService: Title, private authService: AuthService, private userService: UserService) {}

    ngOnInit(): void {
        console.log('EmailConfirmComponent ngOnInit!');

        const sub = this.route.params.subscribe((params) => {
            this.ID = params.id;
            this.code = params.code;
            console.log('params: ', params, this.ID, this.code);

            const isLoggedIn = this.authService.isLoggedIn;
            if (isLoggedIn) {
                this.router.navigateByUrl('/');
            } else {
                if (this.ID && this.code && !isLoggedIn) {
                    this.titleService.setTitle(`${environment.appName} - Conferma indirizzo e-mail`);

                    const sub1 = this.userService.confirmEmail(this.ID, this.code).subscribe(
                        () => {
                            this.result$ = of(true);
                            this.message$ = of('Il tuo indirizzo e-mail è stato confermato.');
                        },
                        () => {
                            this.message$ = of(`Impossibile conferma l'indirizzo e-mail: il link non è valido.`);
                        }
                    );
                    this.subscriptions.add(sub1);
                } else {
                    this.router.navigateByUrl('/error');
                }
            }
        });
        this.subscriptions.add(sub);
    }

    ngOnDestroy(): void {
        console.log('EmailConfirmComponent ngOnDestroy!');

        this.subscriptions.unsubscribe();
    }
}
