import { trigger, transition, style, query, group, animate, state } from '@angular/animations';

export const fader = trigger('routeAnimations', [
    transition('* <=> *', [
        query(':enter, :leave', [
            style({
                position: 'absolute',
                left: 0,
                width: '100%',
                opacity: 0,
                transform: 'scale(0) translateY(100%)',
            }),
        ]),
        query(':enter', [animate('600ms ease', style({ opacity: 1, transform: 'scale(1) translateY(0)' }))]),
    ]),
]);

// FOR PRODUCTION BUILD - CANNOT REFERENCE A NON-EXPORTED FUNCTION INTO 'slider' CONST !!
export const slider = trigger('routeAnimations', [
    transition('* => isLeft', [
        query(
            ':enter, :leave',
            [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                }),
            ],
            { optional: true }
        ),
        query(':enter', [style({ left: '-100%' })]),
        group([query(':leave', [animate('300ms ease', style({ left: '100%' }))], { optional: true }), query(':enter', [animate('300ms ease', style({ left: '0%' }))])]),
    ]),
    transition('* => isRight', [
        query(
            ':enter, :leave',
            [
                style({
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: '100%',
                }),
            ],
            { optional: true }
        ),
        query(':enter', [style({ right: '-100%' })]),
        group([query(':leave', [animate('300ms ease', style({ right: '100%' }))], { optional: true }), query(':enter', [animate('300ms ease', style({ right: '0%' }))])]),
    ]),
    transition('isRight => *', [
        query(
            ':enter, :leave',
            [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                }),
            ],
            { optional: true }
        ),
        query(':enter', [style({ left: '-100%' })]),
        group([query(':leave', [animate('300ms ease', style({ left: '100%' }))], { optional: true }), query(':enter', [animate('300ms ease', style({ left: '0%' }))])]),
    ]),
    transition('isLeft => *', [
        query(
            ':enter, :leave',
            [
                style({
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: '100%',
                }),
            ],
            { optional: true }
        ),
        query(':enter', [style({ right: '-100%' })]),
        group([query(':leave', [animate('300ms ease', style({ right: '100%' }))], { optional: true }), query(':enter', [animate('300ms ease', style({ right: '0%' }))])]),
    ]),
]);

export const smoothCollapse = trigger('smoothCollapse', [
    state(
        'initial',
        style({
            height: '0',
            overflow: 'hidden',
            opacity: '0',
        })
    ),
    state(
        'final',
        style({
            overflow: 'hidden',
            opacity: '1',
        })
    ),
    transition('initial=>final', animate('250ms ease-in-out')),
    transition('final=>initial', animate('250ms ease-in-out')),
]);
