// @angular
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

// External libs
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

// App Models
import { CompanySearch } from 'src/app/models/company-search.model';
import { PagerParams } from 'src/app/models/pager-params.model';

// App Services
import { CompanyService } from 'src/app/services/resources/company.service';
import { AuthService } from 'src/app/services/resources/auth.service';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
    pageParameters: PagerParams = new PagerParams();
    private subscription: Subscription = new Subscription();

    constructor(private router: Router, private companyService: CompanyService, private titleService: Title, private authService: AuthService) {}

    ngOnInit() {
        console.log('HomeComponent - ngOnInit!!!');
        this.titleService.setTitle(`${environment.appName} - Home`);

        const companyId = this.authService.getCurrentCompany();

        const isAdmin = this.authService.getIsAdmin();

        let modeSite = this.authService.getModalitaSito();

        if (modeSite) {
            this.authService.currentMode$.next(modeSite);
            if (modeSite === 'private') {
                if (isAdmin) {
                    this.router.navigate(['/area-riservata/cooperations']);
                } else if (companyId) {
                    this.router.navigate(['/area-riservata/company', companyId, 'info']);
                } else {
                    this.router.navigate(['home']);
                }
            }
        }
    }

    ngOnDestroy(): void {
        console.log('HomeComponent - ngOnDestroy!!!');
        this.subscription.unsubscribe();
    }

    onSearch(criteria: CompanySearch) {
        const sub = this.companyService.searchToTable(this.pageParameters, criteria).subscribe(() => {
            this.router.navigate(['/company/results']);
        });
        this.subscription.add(sub);
    }
}
