// @angular
import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// External libs
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

// App Model
import { Company } from 'src/app/models/company.model';
import { GenericPager } from 'src/app/models/generic-pager.model';
import { PagerParams } from 'src/app/models/pager-params.model';
import { CompanySearch } from 'src/app/models/company-search.model';
import { Certification } from 'src/app/models/certification.model';
import { News } from 'src/app/models/news.model';
import { User } from 'src/app/models/user.model';
import { Tag } from 'src/app/models/tag.model';
import { Territory } from 'src/app/models/territory.model';
import { CompanyAddress } from 'src/app/models/company-address.model';
import { CustomError } from 'src/app/models/custom-error.model';
import { ProductService } from 'src/app/models/product-service.model';
import { Consortium } from 'src/app/models/consortium.model';
import { UserRole } from 'src/app/models/user-role.model';

// App Services
import { BaseService } from './base.service';
import { CompanyContact } from 'src/app/models/company-contact.model';

@Injectable()
export class CompanyService extends BaseService {
    private companyCache: { [id: string]: Company } = {};

    @Output() currentCriteria$: BehaviorSubject<CompanySearch> = new BehaviorSubject<CompanySearch>(null);
    @Output() currentSearchResult$: BehaviorSubject<Company[]> = new BehaviorSubject([]);
    @Output() totalOccurrence: number;
    @Output() currentEditCompany$: BehaviorSubject<Company> = new BehaviorSubject<Company>(null);

    constructor(private http: HttpClient) {
        super(http);
    }

    public getAll(): Observable<Company[]> {
        const url = `${this.baseUrl}company`;
        return this.http.get<Company[]>(url);
    }

    public getById(companyId: string): Observable<Company> {
        const url = `${this.baseUrl}company/${companyId}`;
        return this.http.get<Company>(url).pipe(
            tap((c) => {
                this.currentEditCompany$.next(c);
            })
        );
    }

    public fetchById(companyId: string): Observable<Company> {
        const url = `${this.baseUrl}company/${companyId}`;
        return this.http
            .get<Company>(url)
            .map((c) => {
                this.currentEditCompany$.next(c);
                this.companyCache[c.id] = c;
                return c;
            })
            .share();
    }

    public getByUser(userid: string): Observable<Company[]> {
        const url = `${this.baseUrl}company/user/${userid}`;
        return this.http.get<Company[]>(url);
    }

    public getCertifications(companyid: string): Observable<Certification[]> {
        const url = `${this.baseUrl}company/${companyid}/certifications`;
        return this.http.get<Certification[]>(url);
    }

    public getConsortium(companyid: string): Observable<Consortium[]> {
        const url = `${this.baseUrl}company/${companyid}/consortia`;
        return this.http.get<Consortium[]>(url);
    }

    public getNews(companyid: string): Observable<News[]> {
        const url = `${this.baseUrl}company/${companyid}/news`;
        return this.http.get<News[]>(url);
    }

    public createNews(news: News): Observable<News> {
        if (!news) throw Observable.throw('News non conforme');

        const url = `${this.baseUrl}company/news/create`;
        return this.http.post<News>(url, news);
    }

    public deleteNews(news: any): Observable<News> {
        if (!news) throw Observable.throw('News non presente');

        const url = `${this.baseUrl}company/news/delete`;
        return this.http.post<News>(url, news);
    }

    public updateNews(news: News): Observable<News> {
        if (!news) throw Observable.throw('News non conforme');

        const url = `${this.baseUrl}company/news/update`;
        return this.http.post<News>(url, news);
    }

    public getAssociates(companyid: string): Observable<User[]> {
        const url = `${this.baseUrl}company/${companyid}/associates`;
        return this.http.get<User[]>(url);
    }

    public getUsers(companyid: string): Observable<UserRole[]> {
        const url = `${this.baseUrl}company/${companyid}/users`;
        return this.http.get<UserRole[]>(url);
    }

    public getTags(companyid: string): Observable<Tag[]> {
        const url = `${this.baseUrl}company/${companyid}/tags`;
        return this.http.get<Tag[]>(url);
    }

    public getTerritories(companyid: string): Observable<Territory[]> {
        const url = `${this.baseUrl}company/${companyid}/territories`;
        return this.http.get<Territory[]>(url);
    }

    public getAddresses(companyid: string): Observable<CompanyAddress[]> {
        const url = `${this.baseUrl}company/${companyid}/addresses`;
        return this.http.get<CompanyAddress[]>(url);
    }

    public getProductServices(companyid: string): Observable<ProductService[]> {
        const url = `${this.baseUrl}company/${companyid}/productservices`;
        return this.http.get<ProductService[]>(url);
    }

    public update(company: Company): Observable<Company> {
        if (!company) throw Observable.throw('Informazioni sulla cooperativa non valide');

        const url = `${this.baseUrl}company/update`;
        return this.http.post<Company>(url, company);
    }

    public create(company: Company): Observable<Company> {
        if (!company) throw Observable.throw('Informazioni sulla cooperativa non valide');

        const url = `${this.baseUrl}company/create`;
        return this.http.post<Company>(url, company);
    }

    public updateAddresses(companyid: string, addresses: CompanyAddress[]): Observable<CompanyAddress[]> {
        if (!companyid) {
            return Observable.throwError(new CustomError(400, 'Riferimento alla cooperativa non valido.'));
        }

        if (!addresses || !(addresses instanceof Array) || (addresses.length > 0 && !addresses.some((a) => a instanceof CompanyAddress))) {
            return Observable.throwError(new CustomError(400, 'Informazioni sulle filiali non valide.'));
        }

        return this.http.post<CompanyAddress[]>(this.baseUrl + `company/${companyid}/address`, addresses);
    }

    public updateUsers(companyid: string, users: UserRole[]): Observable<UserRole[]> {
        if (!companyid) throw Observable.throwError(new CustomError(400, 'Riferimento alla cooperativa non valido.'));

        return this.http.post<UserRole[]>(this.baseUrl + `company/${companyid}/users`, users);
    }

    public updateProductServices(companyid: string, productServices: number[]): Observable<ProductService[]> {
        if (!companyid) {
            return Observable.throwError(new CustomError(400, 'Riferimento alla cooperativa non valido.'));
        }

        return this.http.post<ProductService[]>(this.baseUrl + `company/${companyid}/productservices`, productServices);
    }

    public updateTerritories(companyid: string, territories: Territory[]): Observable<Territory[]> {
        if (!companyid) {
            return Observable.throwError(new CustomError(400, 'Riferimento alla cooperativa non valido.'));
        }

        return this.http.post<Territory[]>(this.baseUrl + `company/${companyid}/territory`, territories);
    }

    public updateTags(companyid: string, tags: Tag[]): Observable<Tag[]> {
        if (!companyid) {
            return Observable.throwError(new CustomError(400, 'Riferimento alla cooperativa non valido.'));
        }

        return this.http.post<Tag[]>(this.baseUrl + `company/${companyid}/tag`, tags);
    }

    public updateCertifications(companyid: string, certifications: Certification[]): Observable<Certification[]> {
        if (!companyid) {
            return Observable.throwError(new CustomError(400, 'Riferimento alla cooperativa non valido.'));
        }

        return this.http.post<Certification[]>(this.baseUrl + `company/${companyid}/certification`, certifications);
    }

    public updateConsortium(companyid: string, consortium: Consortium[]): Observable<Consortium[]> {
        if (!companyid) {
            return Observable.throwError(new CustomError(400, 'Riferimento alla cooperativa non valido.'));
        }

        return this.http.post<Consortium[]>(this.baseUrl + `company/${companyid}/consortia`, consortium);
    }

    public searchToTable(pager: PagerParams, criteria: CompanySearch): Observable<GenericPager<Company>> {
        const url = `${this.baseUrl}company/search/${pager.page}/${pager.pageSize}`;
        return this.http.post<GenericPager<Company>>(url, criteria).pipe(
            tap((c) => {
                this.currentSearchResult$.next(c.rows);
                this.totalOccurrence = c.totalRows;
                this.currentCriteria$.next(criteria);
            })
        );
    }

    public searchToScroll(criteria: CompanySearch): Observable<Company[]> {
        const url = `${this.baseUrl}company/search`;
        return this.http.post<Company[]>(url, criteria).pipe(
            tap((companies) => {
                this.currentSearchResult$.next(companies);
                this.currentCriteria$.next(criteria);
            })
        );
    }

    public addMultipleUsers(companyid: string, users: Array<User>): Observable<void> {
        return this.http.post<void>(this.baseUrl + `company/${companyid}/multipleusers`, users);
    }

    public deleteCompanyFile(companyId: string, fileId: number) {
        const url = `${this.baseUrl}company/${companyId}/file/delete/${fileId}`;
        return this.http.get<void>(url);
    }

    public deleteFile(companyId: string, fileId: number) {
        const url = `${this.baseUrl}company/${companyId}/${fileId}`;
        return this.http.get<void>(url);
    }

    public sendMail(companyContact: CompanyContact) {
        const content = new FormData();
        if (companyContact.attachment != null && companyContact.attachment != undefined)
            content.append(companyContact.attachment.name, companyContact.attachment, companyContact.attachment.name);

        if (companyContact.companyGuidRecipient === null || companyContact.companyGuidRecipient === undefined)
            throw new Error("The parameter 'CompanyGUIDRecipient' cannot be null.");
        else content.append('companyGuidRecipient', companyContact.companyGuidRecipient);

        if (companyContact.companyGuidSender === null || companyContact.companyGuidSender === undefined) throw new Error("The parameter 'CompanyGUIDSender' cannot be null.");
        else content.append('companyGuidSender', companyContact.companyGuidSender);

        if (companyContact.messageBody === null || companyContact.messageBody === undefined) throw new Error("The parameter 'messageBody' cannot be null.");
        else content.append('messageBody', companyContact.messageBody);

        if (companyContact.messageSubject === null || companyContact.messageSubject === undefined) throw new Error("The parameter 'messageSubject' cannot be null.");
        else content.append('messageSubject', companyContact.messageSubject);

        return this.http.post(this.baseUrl + `company/contact`, content, { reportProgress: true });
    }
}
