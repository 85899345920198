// @angular
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

// App Helpers
import { RolesEnum } from './helpers/roles.enum';
import { AuthGuard } from './helpers/auth.guard';

// App Components
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LoginComponent } from './components/login/login.component';
import { EmailConfirmComponent } from './components/email-confirm/email-confirm.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { HomeComponent } from './components/home/home.component';
import { RegisterComponent } from './components/register/register.component';
import { HomePageNewsComponent } from './modules/shared/components/home-page-news/home-page-news.component';
import { SearchMapCompanyComponent } from './components/search-map-company/search-map-company.component';
import { NetworkComponent } from './components/network/network.component';
import { InitiativeComponent } from './components/initiative/initiative.component';
import { CompanyPickerComponent } from 'src/app/components/company-picker/company-picker.component';
import { CompanyResultComponent } from './modules/area-riservata/components/company/components/company-result/company-result.component';
import { CompanySearchComponent } from './modules/area-riservata/components/company/components/company-search/company-search.component';
import { CompanyComponent } from './modules/area-riservata/components/company/components/company/company.component';
import { CompanyDetailComponent } from './modules/area-riservata/components/company/components/company-detail/company-detail.component';
import { NewsComponent } from 'src/app/components/news/news.component';
import { CampaignCreateComponent } from './modules/area-riservata/components/campaign/components/campaign-create/campaign-create.component';

const routerOptions: ExtraOptions = {
    enableTracing: false,
    scrollPositionRestoration: 'enabled',
    scrollOffset: [0, 0],
    anchorScrolling: 'enabled',
};

const routes: Routes = [
    // {
    //     path: 'users',
    //     loadChildren: './modules/users/users.module#UsersModule',
    //     data: { roles: [RolesEnum.Administrator, RolesEnum.PlexAdministrator] },
    //     canActivate: [AuthGuard]
    // },
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'login', component: LoginComponent },
    { path: 'register/:id', component: RegisterComponent },
    { path: 'confirm/:id/:code', component: EmailConfirmComponent },
    { path: 'reset/:id', component: ResetPasswordComponent },
    // { path: 'news', component: NewsComponent },
    // { path: 'initiative', component: InitiativeComponent },
    // { path: 'network', component: NetworkComponent },
    // { path: 'aroundme', component: SearchMapCompanyComponent },
    { path: 'company-picker', component: CompanyPickerComponent },
    //{ path: 'campaign', component: CampaignCreateComponent },
    {
        path: 'company',
        children: [
            { path: '', redirectTo: 'search', pathMatch: 'full' },
            { path: 'search', component: CompanySearchComponent },
            {
                path: 'results',
                children: [
                    { path: '', component: CompanyResultComponent },
                    { path: 'productservice/:id', component: CompanyResultComponent },
                    { path: 'product/:id', component: CompanyResultComponent },
                ],
            },
            {
                path: ':id',
                component: CompanyComponent,
                children: [
                    { path: '', redirectTo: 'info', pathMatch: 'full' },
                    { path: 'info', component: CompanyDetailComponent },
                ],
            },
        ],
    },
    {
        path: 'area-riservata',
        loadChildren: () => import('./modules/area-riservata/area-riservata.module').then((m) => m.AreaRiservataModule),
        canActivate: [AuthGuard],
    },
    { path: 'error', component: NotFoundComponent },
    { path: '**', component: NotFoundComponent, data: { error: 404 } },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
