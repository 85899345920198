import { Certification } from './certification.model';
import { CompanyAddress } from './company-address.model';
import { Consortium } from './consortium.model';
import { Cooperation } from './cooperation.model';
import { FileInfo } from './file-info.model';
import { News } from './news.model';
import { ProductService } from './product-service.model';
import { Tag } from './tag.model';
import { Territory } from './territory.model';
import { FormGroup } from '@angular/forms';

export class Company {
    id: string;
    logoFileId?: number;
    fileIds?: Array<number>;
    provinceId?: number;
    externalCode: string;
    externalOrigin: string;
    fiscalCode: string;
    vatNumber: string;
    rea: string;
    name: string;
    description: string;
    mobilePhone: string;
    telephone: string;
    fax: string;
    email: string;
    chairman: string;
    referentName: string;
    referentRole: string;
    employees?: number;
    revenue?: number;
    sector?: string;
    website?: string;
    facebook?: string;
    twitter?: string;
    instagram?: string;
    linkedin?: string;
    youtube?: string;
    telegram?: string;
    whatsapp?: string;
    feedRSS?: string;
    created: Date;
    createdById: string;
    logoFile?: FileInfo;
    files?: Array<FileInfo>;
    filesDescription?: Array<string>;
    companyAddresses?: CompanyAddress[];
    ownedCooperations?: Cooperation[];
    certification?: Certification[];
    consortia?: Consortium[];
    productServices?: ProductService[];
    tags?: Tag[];
    territories?: Territory[];
    partnerCooperations?: Cooperation[];
    news?: News[];

    constructor(form: FormGroup) {
        if (form) {
            this.id = form.value.ID;
            this.logoFileId = form.value.logoFileId;
            this.fileIds = form.value.fileIds;
            this.provinceId = form.value.provinciaID;
            this.externalCode = form.value.externalCode;
            this.externalOrigin = form.value.externalOrigin;
            this.fiscalCode = form.value.fiscalCode;
            this.vatNumber = form.value.vatNumber;
            this.rea = form.value.rea;
            this.name = form.value.name;
            this.description = form.value.description;
            this.mobilePhone = form.value.mobilePhone;
            this.telephone = form.value.telephone;
            this.fax = form.value.fax;
            this.email = form.value.email;
            this.chairman = form.value.chairman;
            this.referentName = form.value.referentName;
            this.referentRole = form.value.referentRole;
            this.employees = form.value.employees;
            this.revenue = form.value.revenue;
            this.sector = form.value.sector;
            this.website = form.value.website;
            this.facebook = form.value.facebook;
            this.twitter = form.value.twitter;
            this.instagram = form.value.instagram;
            this.linkedin = form.value.linkedin;
            this.youtube = form.value.youtube;
            this.telegram = form.value.telegram;
            this.whatsapp = form.value.whatsapp;
            this.feedRSS = form.value.feedRSS;
        }
    }
}
