<ng-container>
    <div id="ultime-news" class="container mb-5">
        <!-- TITOLO -->
        <h1 class="text-heading text-bold text-blue text-big mb-3 ml-n3" data-aos="fade-up">Ultime news</h1>

        <div class="news-wrapper justify-content-start row" data-aos="fade-up" data-aos-delay="100">
            <ng-container *ngFor="let n of showNews">
                <!-- SINGOLA NEWS -->
                <div class="col-md-6 mb-4 p-0 px-md-2">
                    <div class="single-news h-100 d-flex flex-row justify-content-start align-items-center border-round-pill angle-bottom-sx shadow-default">
                        <!-- IMMAGINE NEWS -->
                        <ng-container *ngIf="n.image$ | async as image; else noImage">
                            <div class="img-news h-100" [style.background-image]="image"></div>
                        </ng-container>
                        <ng-template #noImage>
                            <div style="background-image: url(/assets/images/no-image-news.svg); background-size: contain" class="img-news h-100"></div>
                        </ng-template>

                        <div class="infos-news d-flex flex-column justify-content-between h-100 p-2 pl-3">
                            <div class="mb-4">
                                <p class="text-blue text-bold mb-2">{{ n.title }}</p>
                                <p class="text-grey text-smaller mb-0">{{ n.summary }}</p>
                            </div>
                            <a class="btn-leggi-news btn btn-main shadow-default angle-bottom-sx text-white text-smaller" [href]="n.url" target="blank">Leggi</a>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <!-- TASTO TUTTE LE NEWS -->
        <div class="row mt-2" data-aos="fade-up" data-aos-delay="200">
            <div class="col-12 text-right">
                <a class="btn btn-main angle-top-sx shadow-default text-white" [routerLink]="['/area-riservata/news']" routerLinkActive="active">
                    <span class="text-uppercase">Tutte le news</span>
                </a>
            </div>
        </div>
    </div>
</ng-container>
