// @angular
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

// App Services
import { ErrorsService } from '../services/navigation/errors.service';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class ErrorsInterceptor implements ErrorHandler {
    constructor(private injector: Injector) {}

    handleError(error: Error | HttpErrorResponse) {
        const router = this.injector.get(Router);
        const location = this.injector.get(Location);
        const notification = this.injector.get(NotificationService);
        const errorsService = this.injector.get(ErrorsService);

        console.log('ErrorsInterceptor handleError: ', error);
        if (error instanceof HttpErrorResponse) {
            // Server error happened
            if (!navigator.onLine) {
                // No Internet connection
                return notification.warning('No Internet Connection', '');
            }

            // WebAPI returned "Forbidden Exception" to one or more resources
            if (error.status === 403) {
                errorsService.log(error).subscribe();

                notification.error(
                    'Non è possibile visitare la pagina desiderata in quanto non si dispone di sufficienti permessi per potervi accedere.',
                    'Accesso non consentito!'
                );
                return location.back();
            }
        } else {
        }

        errorsService.log(error).subscribe();
        throw error;
    }
}
