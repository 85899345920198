import { FormControl } from '@angular/forms';

export function RepeatValidator(confirmPasswordInput: string) {
    let confirmControl: FormControl;
    let firstControl: FormControl;

    return (control: FormControl) => {
        if (!control.parent) {
            return null;
        }

        if (!confirmControl) {
            confirmControl = control;
            firstControl = control.parent.get(confirmPasswordInput) as FormControl;
            firstControl.valueChanges.subscribe(() => {
                confirmControl.updateValueAndValidity();
            });
        }

        if (firstControl.value !== confirmControl.value) {
            return {
                notMatch: true,
            };
        }
        return null;
    };
}
