<div class="d-flex flex-column justify-content-center align-items-center" id="login-page">
    <div class="container">
        <div class="row d-flex flex-row align-items-end mb-5">
            <!-- ILLUSTRAZIONE SX -->
            <div class="col-lg-7 mt-5 mb-0" data-aos="fade-down">
                <img class="illustration-bg img-fluid" src="assets/images/Login_Bergamo.svg" alt="" />
            </div>

            <!-- CONTAINER LOGIN DX -->
            <div class="container-login col-lg-4 offset-1 order-first order-lg-last mb-5 p4 p-lg-2">

                <!-- <h2 class="font-pp text-center mt-1">Login</h2> -->

                <ng-container *ngIf="message" data-aos="fade-up">
                    <hr class="mt-1" />
                    <p class="text-danger text-center">{{ message }}</p>
                    <p class="text-danger text-small text-center">{{ description }}</p>
                </ng-container>


                <form class="form" role="form" autocomplete="off" id="formLogin" novalidate="" [formGroup]="authForm" (ngSubmit)="login()">
                    <!-- INPUT USERNAME -->
                    <div class="form-group" data-aos="fade-up" data-aos-delay="100">
                        <div class="input-group mb-4 mt-4">
                            <div class="input-group-prepend custom-label custom-label-sx">
                                <fa-icon prefix="far" icon="user"></fa-icon>
                            </div>

                            <!-- Campo errore username -->
                            <div class="invalid-feedback">Inserisci il nome utente!</div>

                            <input class="form-control custom-input" id="username" formControlName="username" type="email" placeholder="Username" />
                        </div>
                    </div>

                    <!-- INPUT PASSWORD -->
                    <div class="form-group" data-aos="fade-up" data-aos-delay="150">
                        <div class="input-group mb-4">
                            <div class="input-group-prepend custom-label custom-label-sx">
                                <fa-icon prefix="far" icon="lock"></fa-icon>
                            </div>

                            <!-- Campo errore password -->
                            <div class="invalid-feedback">Inserisci la password</div>

                            <input class="form-control custom-input" id="password" formControlName="password" [type]="passwordFieldType" placeholder="Password" />
                        </div>
                    </div>

                    <hr class="mt-5 mb-4" />

                    <!-- CONTENITORE RICORDAMI / PW DIMENTICATA -->
                    <div class="remember-recover-container mb-4 d-flex flex-row justify-content-between align-items-center">
                        <!-- CHECK REMEMBER ME -->
                        <div class="form-group mb-0 round-switch-wrapper" data-aos="fade-up" data-aos-delay="200">
                            <div class="form-check pl-0">
                                <label class="form-check-label cursor-pointer" for="ricordami">
                                    <input class="form-check-input custom-control-input round-switch d-none" type="checkbox" formControlName="isPersistent" id="ricordami" />
                                    <div class="round-switch-button d-inline-block cursor-pointer">
                                        <fa-icon icon="check" class="round-switch-icon"></fa-icon>
                                    </div>
                                    <span class="text-smaller text-grey d-inline-block ml-1 remember-me-text"> Ricordami </span>
                                </label>
                            </div>
                        </div>

                        <!-- PW FORGOT -->
                        <div class="form-check recover-password pt-2" data-aos="fade-up" data-aos-delay="250">
                            <span class="cursor-pointer text-smaller text-underlined text-green" (click)="resetPassword()">Password dimenticata?</span>
                        </div>
                    </div>

                    <!-- Campo errore generico -> esempio:username e password non sono corretti -->
                    <p class="text-danger text-center p-2 d-none" data-aos="fade-up"></p>

                    <!-- NEW LOGIN BTN -->
                    <div class="text-center pt-2 mb-2" data-aos="fade-up" data-aos-delay="300">
                        <button type="submit" class="btn btn-main angle-bottom-sx shadow-default" id="btn-login">
                            <fa-icon icon="user-circle" class="mr-3"></fa-icon>
                            <span class="text-uppercase">Accedi</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
