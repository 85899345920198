// @angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//External Libs
import { Observable } from 'rxjs';

//App Models
import { Message } from 'src/app/models/message.model';

//App Service
import { BaseService } from 'src/app/services/resources/base.service';
import { MaskMessage } from 'src/app/models/mask-message.model';

@Injectable({
    providedIn: 'root',
})
export class MailService extends BaseService {
    constructor(private http: HttpClient) {
        super(http);
    }

    postMessage(message: Message): Observable<any> {
        return this.http.post(this.baseUrl + '/mail', message);
    }

    postMasksRequest(message: MaskMessage): Observable<any> {
        return this.http.post(this.baseUrl + '/mail/masks', message);
    }
}
