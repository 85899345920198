import { Company } from 'src/app/models/company.model';

export class News {
    id: string;
    title: string;
    summary: string;
    text: string;
    origin: string;
    url: string;
    image: string;
    attachment: string;
    feedUrl: string;
    created: Date;
    date: Date;
    companies: Array<Company>;
}
