import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'explanation-roles-modal',
    templateUrl: './explanation-roles-modal.component.html',
    styleUrls: ['./explanation-roles-modal.component.scss'],
})
export class ExplanationRolesModalComponent {
    modalRef: NgbActiveModal;
    constructor(private modalService: NgbModal) {}

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.open(template);
    }
}
