<ng-container *ngIf="!isPublic; else publicArea">
    <nav id="navbar"
        class="navbar navbar-private navbar-expand-md py-0 px-0 fixed-top align-items-start align-items-md-center shadow-default"
        [ngClass]="{ expanded: !isCollapsed }">
        <div class="container-fluid flex-column bg-white px-0">
            <div class="d-flex flex-row justify-content-between align-items-center menu-container my-3">
                <a class="navbar-brand logo-wrapper pt-2 cursor-pointer logobgbs" [routerLink]="['/']"><img
                        src="assets/images/LogoBg_bs2022.png" alt="Bergamo Brescia 2022" /></a>



                <button class="navbar-toggler d-block d-lg-none border-0" type="button" (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="isCollapsed" aria-controls="navbar-private">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <!-- DESKTOP -->

                <a class="navbar-brand logo-wrapper pt-2 cursor-pointer d-none d-lg-flex"
                    href="https://www.bergamo.confcooperative.it/" target="_blank"><img
                        src="assets/images/Logo_CCI_Bergamo_web_BLU.svg"
                        alt="Confcooperative Bergamo - Area Riservata" /></a>
                <a class="navbar-brand logo-wrapper pt-2 d-none d-lg-block"
                    href="https://www.brescia.confcooperative.it/" target="_blank"><img
                        src="assets/images/Logo_CCI_Brescia_web_BLU.svg" alt="Confcooperative Brescia" /></a>
                <a class="navbar-brand logo-wrapper pt-2 cursor-pointer d-none d-lg-flex" href="https://www.coesi.coop/"
                    target="_blank"><img src="assets/images/logo_Coesi.svg" alt="Logo Coesi" /></a>
                <div class="d-none d-lg-flex">
                    <widget-login-button></widget-login-button>
                </div>
            </div>

            <div class="collapse navbar-collapse w-100 pt-3 pt-md-2 bg-blue" [ngClass]="{ show: !isCollapsed }">
                <ul
                    class="navbar-nav mx-auto justify-content-between align-items-start align-items-md-center menu-container">
                    <ng-container *ngFor="let item of filteredMenuItems">
                        <ng-container *ngIf="item.children.length > 0; else elseTemplate">
                            <ng-container *ngIf="item.children.length == 1 && !customChildNav; else moreChildren">
                                <li class="nav-item">
                                    <a class="nav-link text-white" [routerLink]="item.children[0].route"
                                        routerLinkActive="active" (click)="isCollapsed = true">{{
                                        item.children[0].title
                                        }}</a>
                                </li>
                            </ng-container>
                            <ng-template #moreChildren>
                                <div class="position-relative" dropdown>
                                    <button type="button"
                                        class="btn bg-transparent nav-item-dropdown-title text-white pl-0 pr-2"
                                        (click)="navigateTo($event, item)">
                                        <span class="truncate-company">{{ item.title }}</span>
                                    </button>
                                    <button id="button-split" type="button" dropdownToggle
                                        class="pl-0 btn dropdown-toggle dropdown-toggle-split bg-transparent text-white"
                                        aria-controls="dropdown-split">
                                        <span class="caret"></span>
                                    </button>
                                    <ul id="dropdown-split" *dropdownMenu
                                        class="custom-dropdown-class-{{
                                            item.title.replace(' ', '-').toLowerCase()
                                        }} dropdown-menu flex-column align-items-center justify-content-center py-3 px-4 border-0 rounded-0 shadow-default mt-2"
                                        role="menu" aria-labelledby="button-split" (click)="$event.stopPropagation()">
                                        <p *ngIf="item.code === 'schedacooperativa' && item.children.length > 1"
                                            class="menu-company-search-wrapper">
                                            <label class="hide" for="menu-company-search">Ricerca rapida</label>
                                            <input id="menu-company-search" class="form-control custom-input"
                                                type="text" (input)="filterCompany($event, 3)"
                                                placeholder="Ricerca rapida" autocomplete="off" />
                                        </p>
                                        <div class="d-flex flex-row align-items-start justify-content-center">
                                            <div class="menu-company-item flex-column align-items-center justify-content-center icon-cooperations-menu py-3 px-2"
                                                *ngFor="let child of item.children">
                                                <a class="
                                                        d-flex
                                                        flex-column
                                                        align-items-center
                                                        justify-content-center
                                                        text-blue
                                                        dropdown-item
                                                        text-center
                                                        d-block
                                                        text-smaller
                                                        p-0
                                                        text-uppercase
                                                    " [routerLink]="child.route" (click)="onClickChildItem(child)">
                                                    <ng-container
                                                        *ngIf="item.code === 'schedacooperativa' || item.code === 'collaborazioni'; else toggleAvatar">
                                                        <ng-container *ngIf="child.image; else avatar">
                                                            <img class="image-cooperation-menu mb-2" [src]="child.image"
                                                                [alt]="child.title" />
                                                        </ng-container>
                                                        <ng-template #avatar>
                                                            <ngx-avatar [name]="child.title" size="40" initialsSize="3">
                                                            </ngx-avatar>
                                                        </ng-template>
                                                        <span class="text-blue text-uppercase text-smaller">{{
                                                            child.title }}</span>
                                                    </ng-container>
                                                    <ng-template #toggleAvatar>
                                                        <span class="text-blue text-uppercase text-smaller">{{
                                                            child.title }}</span>
                                                    </ng-template>
                                                </a>
                                            </div>
                                        </div>
                                        <button type="button" class="btn btn-main shadow-default mt-0 mt-md-2"
                                            *ngIf="item.title == 'Collaborazioni'" (click)="searchCompany()">
                                            <span class="text-uppercase text-white">Ricerca cooperativa</span>
                                            <fa-icon icon="search" class="ml-3 text-white"></fa-icon>
                                        </button>
                                    </ul>
                                </div>
                            </ng-template>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <ng-container *ngIf="!isPublic; else loggedTemplate">
                                <li class="nav-item">
                                    <a (click)="smoothScrollMenu(item.fragment)" class="nav-link text-white"
                                        [routerLink]="item.route" routerLinkActive="active"
                                        (click)="isCollapsed = true">{{ item.title }}</a>
                                </li>
                            </ng-container>
                            <ng-template #loggedTemplate>
                                <li class="nav-item">
                                    <a (click)="smoothScrollMenu(item.fragment)" class="nav-link text-white"
                                        [class.active]="(activeFragment | async) === item.fragment"
                                        [routerLink]="item.route" [fragment]="item.fragment"
                                        (click)="isCollapsed = true">{{ item.title }}</a>
                                </li>
                            </ng-template>
                        </ng-template>
                    </ng-container>
                    <!--                    <li class="nav-item" *ngIf="!isPublic && isAdmin">-->
                    <!--                        <a (click)="navigateToDashboard()" class="nav-link text-white cursor-pointer" (click)="isCollapsed = true">Dashboard</a>-->
                    <!--                    </li>-->
                    <li class="nav-item" *ngIf="!isPublic">
                        <a (click)="navigateToPublic()" class="nav-link text-white cursor-pointer"
                            (click)="isCollapsed = true">
                            <fa-icon class="h3" [icon]="'globe-europe'"></fa-icon>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="isAdmin">
                        <a (click)="editUser()" class="nav-link text-white cursor-pointer">
                            <fa-icon [icon]="'user-cog'" class="mr-2"></fa-icon>
                            <span class="text-white text-smaller">{{ getUserFullName() }}</span>
                        </a>
                    </li>
                </ul>

                <!-- MOBILE -->
                <div class="d-block d-md-none mb-2 mt-4">
                    <widget-login-button></widget-login-button>
                </div>
            </div>
        </div>
    </nav>
</ng-container>
<ng-template #publicArea>
    <nav id="navbar"
        class="navbar-public navbar navbar-expand-md bg-white pt-0 fixed-top align-items-start align-items-md-center shadow-default"
        [ngClass]="{ expanded: !isCollapsed }">
        <div class="container-fluid flex-column bg-white h-100 px-0">
            <div class="d-flex flex-row justify-content-between menu-container align-items-center my-3">
                <a class="navbar-brand logo-wrapper pt-2 cursor-pointer logobgbs" [routerLink]="['/']"><img
                        src="assets/images/LogoBg_bs2022.png" alt="Bergamo Brescia 2022" /></a>
                <a class="navbar-brand logo-wrapper pt-2 d-none d-lg-block"
                    href="https://www.bergamo.confcooperative.it/" target="_blank"><img
                        src="assets/images/Logo_CCI_Bergamo_web_BLU.svg" alt="Confcooperative Bergamo" /></a>
                <a class="navbar-brand logo-wrapper pt-2 d-none d-lg-block"
                    href="https://www.brescia.confcooperative.it/" target="_blank"><img
                        src="assets/images/Logo_CCI_Brescia_web_BLU.svg" alt="Confcooperative Brescia" /></a>
                <a class="navbar-brand logo-wrapper pt-2 cursor-pointer d-none d-lg-block"
                    href="https://www.coesi.coop/" target="_blank"><img src="assets/images/logo_Coesi.svg"
                        alt="Logo Coesi" /></a>
                <!-- <button class="navbar-toggler d-md-none border-0" type="button" (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="isCollapsed" aria-controls="navbar-public">
                    <span class="navbar-toggler-icon"></span>
                </button> -->

                <!-- DESKTOP -->
                <!-- <div class="d-none d-md-flex">
                    <widget-login-button></widget-login-button>
                </div> -->
            </div>

            <div class="collapse navbar-collapse w-100 mt-3 mt-md-2" [ngClass]="{ show: !isCollapsed }">
                <!--                <ul class="navbar-nav mr-auto w-100 justify-content-evenly align-items-start align-items-md-center">-->
                <!--                    <ng-container *ngFor="let item of filteredMenuItems">-->
                <!--                        <ng-container *ngIf="item.fragment; else noFragment">-->
                <!--                            <li class="nav-item">-->
                <!--                                <a-->
                <!--                                    (click)="smoothScrollMenu(item.fragment)"-->
                <!--                                    class="nav-link text-blue"-->
                <!--                                    [routerLink]="item.route"-->
                <!--                                    [class.active]="(activeFragment | async) === item.fragment"-->
                <!--                                    [fragment]="item.fragment"-->
                <!--                                    (click)="isCollapsed = true"-->
                <!--                                    >{{ item.title }}</a-->
                <!--                                >-->
                <!--                            </li>-->
                <!--                        </ng-container>-->
                <!--                        <ng-template #noFragment>-->
                <!--                            <li class="nav-item">-->
                <!--                                <a class="nav-link text-blue" [routerLink]="item.route" routerLinkActive="active" (click)="isCollapsed = true">{{ item.title }}</a>-->
                <!--                            </li>-->
                <!--                        </ng-template>-->
                <!--                    </ng-container>-->
                <!--                </ul>-->

                <!-- MOBILE -->
                <!-- <div class="d-block d-md-none mb-2 mt-4">
                    <widget-login-button></widget-login-button>
                </div> -->
            </div>
        </div>
    </nav>
</ng-template>
<loading-spinner [isLoading]="wait"></loading-spinner>