// @angular
import { Injectable, Injector } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';

// External libs
import * as StackTraceParser from 'error-stack-parser';

// App Services
import { BaseService } from '../resources/base.service';
import { AuthService } from '../resources/auth.service';

@Injectable()
export class ErrorsService extends BaseService {
    constructor(private injector: Injector, private http: HttpClient, private authService: AuthService) {
        super(http);
    }

    public log(error) {
        // Log the error to the console
        // console.error(error);

        // Send error to server
        const errorObj = this.addContextInfo(error);
        return this.http.post(`${this.baseUrl}error/log`, errorObj);
    }

    private addContextInfo(error) {
        // All the context details that you want (usually coming from other services; Constants, UserService...)
        const id = '';
        const name = error.name || null;
        const user = JSON.stringify(this.authService.currentUser);
        const time = new Date().getTime();
        const location = this.injector.get(LocationStrategy);
        const url = location instanceof PathLocationStrategy ? location.path() : '';
        const status = error.status || null;
        const message = error.message || error.toString();
        const stack = error instanceof HttpErrorResponse ? null : StackTraceParser.parse(error);
        const errorToSend = { id, name, user, time, url, status, message, stack: JSON.stringify(stack) };
        return errorToSend;
    }
}
