// @angular
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

// External libs
import { Subscription } from 'rxjs';

// App Models
import { User } from 'src/app/models/user.model';

// App Services
import { UserService } from 'src/app/services/resources/user.service';

@Component({
    selector: 'quick-user-info',
    templateUrl: './quick-user-info.component.html',
    styleUrls: ['./quick-user-info.component.css'],
})
export class QuickUserInfoComponent implements OnInit, OnDestroy {
    // tslint:disable-next-line: no-input-rename
    @Input('userInfo') user: User;
    // tslint:disable-next-line: no-input-rename
    @Input('userId') userId: string;

    // Subscription
    private subscriptions: Subscription = new Subscription();

    constructor(private userService: UserService) {}

    ngOnInit(): void {
        if (!this.user) {
            if (this.userId) {
                const sub = this.userService.getById(this.userId).subscribe((u) => (this.user = u));
                this.subscriptions.add(sub);
            }
        }
    }

    public showUserRoles(): string {
        if (this.user.roles instanceof Array) {
            console.log(this.user);
            return this.user.roles.map((r) => r.role.publicName).join(', ');
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
