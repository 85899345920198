// @angular
import { FormGroup } from '@angular/forms';

// App Models
import { DataSourceSort } from './datasource-sort.model';
import { ProductService } from './product-service.model';

export class CompanySearch {
    id: number;
    productServices: number[];
    keyword: string;
    provinceId: number;
    revenueId: number;
    employeesId: number;
    certificationId: number;
    territoryId: number;
    sortOrder: DataSourceSort[];

    constructor(form: FormGroup) {
        if (form) {
            try {
                this.id = form.value.id;
            } catch {}

            this.productServices = form.value.productServices;
            this.keyword = form.value.keyword;
            this.provinceId = form.value.idProvince;
            this.revenueId = form.value.idRevenue;
            this.employeesId = form.value.idEmployees;
            this.certificationId = form.value.idCertification;
            this.territoryId = form.value.idTerritory;
            this.sortOrder = [];
        }
    }
}
