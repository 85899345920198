<ng-container *ngIf="company; else elseTemplate">
    <ng-container *ngIf="!onlyAvatar; else elseAvatar">
        <div class="single-company mb-3 input-group d-flex flex-row align-items-center justify-content-between shadow-default border-round-pill pl-3">
            <span class="nome-company text-blue text-uppercase text-smaller">{{ company.name }}</span>
            <div class="text-center bg-white company-foto-wrapper border-green rounded-circle">
                <ng-container *ngIf="company.logoFileId; else elseTemplate">
                    <!--                    <img [src]="getImage(company.logoBase64$.getValue())" class="h-100 rounded-circle">-->
                    <img [src]="thumbnail" class="h-100 rounded-circle" />
                </ng-container>
                <ng-template #elseTemplate>
                    <ngx-avatar class="text-green text-bold" [name]="company.name" initialsSize="3"></ngx-avatar>
                </ng-template>
            </div>
        </div>
    </ng-container>
    <ng-template #elseAvatar>
        <div
            class="
                single-cooperation-invited
                rounded-circle
                shadow-default
                d-inline-block
                mr-n2
                text-grey text-uppercase text-big text-center text-bold
                d-flex
                flex-row
                align-items-center
                justify-content-center
            "
        >
            <ng-container *ngIf="company.logoFileId; else elseTemplate">
                <!--                <img [src]="getImage(company.logoBase64$.getValue())" class="h-100 rounded-circle">-->
                <img [src]="thumbnail" class="company-img-logo" />
            </ng-container>
            <ng-template #elseTemplate>
                <ngx-avatar class="text-green text-bold" [name]="company.name" initialsSize="3"></ngx-avatar>
            </ng-template>
        </div>
    </ng-template>
</ng-container>
<ng-template #elseTemplate></ng-template>
