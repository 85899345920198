import { FormGroup } from '@angular/forms';

export class ResetPassword {
    token: string;
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;

    constructor(form: FormGroup) {
        if (form) {
            this.token = form.value.token;
            this.oldPassword = form.value.oldPassword;
            this.newPassword = form.value.newPassword;
            this.confirmNewPassword = form.value.confirmNewPassword;
        }
    }
}
