// @angular
import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';

// External libs
import { Subscription } from 'rxjs';

// App Models
import { Product } from 'src/app/models/product.model';
import { CompanySearch } from 'src/app/models/company-search.model';

// App Services
import { ProductService } from 'src/app/services/resources/product.service';

@Component({
    selector: 'widget-search-product',
    templateUrl: './widget-search-product.component.html',
    styleUrls: ['./widget-search-product.component.css'],
})
export class WidgetSearchProductComponent implements OnInit, OnDestroy {
    @Output() search: EventEmitter<CompanySearch> = new EventEmitter<CompanySearch>();
    products: Product[];

    searchProductForm: FormGroup;

    customCollapsedHeight: string = '190px';
    customExpandedHeight: string = '190px';

    private subscription: Subscription = new Subscription();

    constructor(private route: ActivatedRoute, private router: Router, private productService: ProductService) {}

    ngOnInit() {
        //console.log('WidgetSearchProductComponent - ngOnInit!!!');
        this.searchProductForm = new FormGroup(
            {
                productServices: new FormControl(null),
                keyword: new FormControl(null),
            },
            { updateOn: 'change' }
        );

        const productSub = this.productService.getAll().subscribe((data) => (this.products = data));
        this.subscription.add(productSub);
    }

    ngOnDestroy(): void {
        //console.log('WidgetSearchProductComponent - ngOnDestroy!!!');
        this.subscription.unsubscribe();
    }

    onSearch(form: FormGroup) {
        let companySearch: CompanySearch = new CompanySearch(form);
        if (form.value.productServices > 0) {
            let product: Product = this.products.find((p) => p.id == form.value.productServices);
            companySearch.productServices = [];
            companySearch.productServices = product.productServices.map((list) => list.id);
        }
        this.search.emit(companySearch);
    }

    replace(name: string): string {
        name = name
            .toLowerCase()
            .replace(/\s/g, '-')
            .replace(/[/] = !toggleNam/g, '-')
            .replace(/[à]/, 'a');
        return name;
    }

    scrollToMenu() {
        if (window.innerWidth <= 991) {
            let menuEl = <HTMLElement>document.getElementById('navbar');
            let menuHeight = menuEl.offsetHeight;
            let scrollblock = <HTMLElement>document.getElementById('accordion-products');
            let heightQty = scrollblock.getBoundingClientRect().top + window.scrollY;
            let heightElm = scrollblock.offsetHeight;
            let totalToScroll = heightQty + heightElm - menuHeight;
            scroll({
                top: totalToScroll,
                behavior: 'smooth',
            });
        }
    }
}
