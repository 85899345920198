import { DataSourceSort } from './datasource-sort.model';

export class UserSearch {
    keyword: string;
    sortOrder: DataSourceSort[];

    constructor() {
        this.sortOrder = [];
    }
}
