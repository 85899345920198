export class Utility {
    public static download(response: any, filename: string) {
        const a = document.createElement('a');
        a.style.display = 'none';
        document.body.appendChild(a);

        const binaryData = [];
        binaryData.push(response);
        const blob = new Blob(binaryData, { type: response.type });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
}
