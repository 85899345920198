// @angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';

// External libs
import { Observable, Subject, timer, from, of } from 'rxjs';
import { tap, concatAll, count, scan, withLatestFrom, switchMapTo, share, mapTo, map } from 'rxjs/operators';

// App Helpers
import { Utility } from '../navigation/utility';

// App Models
import { FileInfo } from 'src/app/models/file-info.model';

// App Services
import { BaseService } from './base.service';

@Injectable()
export class FileService extends BaseService {
    public currentUploadStatus$: Subject<number> = new Subject();

    constructor(private http: HttpClient) {
        super(http);
    }

    public upload(files: File[]) {
        console.log('FileService -> upload called!');

        const formData = new FormData();
        for (const file of files) {
            formData.append(file.name, file);
        }

        return this.http.post<FileInfo[]>(this.baseUrl + 'file/new', formData, {
            reportProgress: true,
        });
    }

    private __uploadMultiple(files: File[]) {
        const observables = [];

        observables.push(timer(3000).pipe(mapTo(null)));

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < files.length; i++) {
            const formData = new FormData();

            formData.append(files[i].name, files[i]);
            const obs$ = this.http.post<FileInfo>(this.baseUrl + 'file/new', formData, { reportProgress: true });

            observables.push(obs$);
        }

        observables.push(timer(3000).pipe(mapTo(null)));
        this.currentUploadStatus$.next(0);

        const array$ = from(observables);
        const requests$ = array$.pipe(concatAll());
        const start$ = of(null);
        const progress$ = start$.pipe(switchMapTo(requests$), share());

        const count$ = requests$.pipe(count());

        const ratio$ = progress$.pipe(
            tap((x) => console.log('start$ pipe1 tap x: ', x)),
            scan((current) => current + 1, 0),
            withLatestFrom(count$, (current, count) => current / count)
        );

        const updateProgress = (ratio: number): number => {
            console.log('updateProgress ratio: ', (ratio * 100).toFixed(0));
            return parseFloat((ratio * 100).toFixed(0));
        };

        start$.pipe(switchMapTo(ratio$)).subscribe((ratio) => {
            this.currentUploadStatus$.next(updateProgress(ratio));
        });
        return progress$;
    }

    private getEventMessage(event: HttpEvent<FileInfo | number>) {
        console.log('getEventMessage: ', event);
        switch (event.type) {
            case HttpEventType.UploadProgress:
                const done = Math.round((100 * event.loaded) / event.total);
                console.log('HttpEventType.UploadProgress: ', done);
                this.currentUploadStatus$.next(done);
                return done;

            case HttpEventType.Response:
                return event.body;
        }
    }

    public download(id: number, filename: string = null): void {
        const url = `${this.baseUrl}file/${id}/download`;

        this.http
            .get(url, { responseType: 'blob' as 'json' })
            .catch((error: HttpErrorResponse) => {
                throw error;
            })
            .subscribe((response: any) => {
                Utility.download(response, filename);
            });
    }

    public getImageThumbnail(id: number): Observable<string> {
        const url = `${this.baseUrl}file/${id}/image`;
        return this.http.get<string>(url);
    }

    public getFile(id: number): Observable<FileInfo> {
        const url = `${this.baseUrl}file/${id}/get`;
        return this.http.get<FileInfo>(url);
    }

    public delete(id: number): Observable<boolean> {
        return this.http.delete<boolean>(`${this.baseUrl}file/${id}`);
    }
}
