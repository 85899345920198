import { Cooperation } from './cooperation.model';
import * as Globalize from 'globalize'; // This is needed to call Globalize static to load CLDR data.
import 'globalize/number';

export class PurchaseItem {
    id: number;
    itemName: string;
    price: number;
    itemDescription: string;
    created: Date;
    cooperationId: string;
    createdBy: string;

    constructor(obj: any) {
        this.itemName = obj.itemName;
        const priceToString = obj.price.toString().replace('.', ',');
        this.price = Globalize.parseNumber(priceToString);
        this.itemDescription = obj.itemDescription;
    }
}
