// @angular
import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// External libs
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

// App Models
import { Certification } from 'src/app/models/certification.model';

// App Services
import { BaseService } from './base.service';

@Injectable()
export class CertificationService extends BaseService {
    @Output() certifications: Observable<Certification[]> = null;

    constructor(private http: HttpClient) {
        super(http);
    }

    public getAll(): Observable<Certification[]> {
        if (!this.certifications) {
            const url = `${this.baseUrl}certification`;
            this.certifications = this.http.get<Certification[]>(url).pipe(publishReplay(1), refCount());
        }

        return this.certifications;
    }

    public quickSearch(criteria: string): Observable<Certification[]> {
        const url = `${this.baseUrl}certification/quicksearch/${criteria}`;
        return this.http.get<Certification[]>(url);
    }
}
