// @angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// External libs
import { Observable } from 'rxjs';

// App Models
import { Territory } from 'src/app/models/territory.model';

// App Services
import { BaseService } from './base.service';

@Injectable()
export class TerritoryService extends BaseService {
    constructor(private http: HttpClient) {
        super(http);
    }

    public getAll(): Observable<Territory[]> {
        const url = `${this.baseUrl}territory`;
        return this.http.get<Territory[]>(url);
    }

    public quickSearch(criteria: string): Observable<Territory[]> {
        const url = `${this.baseUrl}territory/quicksearch/${criteria}`;
        return this.http.get<Territory[]>(url);
    }
}
