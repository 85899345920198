// @angular
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';

// External libs
import { Subscription, Observable, of, Subject } from 'rxjs';

// App Models
import { FileUploaderConfig } from './file-uploader-config.model';
import { FileNotAllowed } from './file-not-allowed.model';
import { FileInfo } from 'src/app/models/file-info.model';

// App Services
import { FileService } from 'src/app/services/resources/file.service';
import { FileSizePipe } from 'src/app/helpers/file-size.pipe';

@Component({
    selector: 'file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.css'],
})
export class FileUploaderComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() config: FileUploaderConfig = new FileUploaderConfig();
    //@Input() preloadFiles: Observable<FileInfo[]>;
    @Input() preloadFiles: FileInfo[];
    @Input() isAvatar: boolean = false;
    @Input() filesDescription: string[] = [];

    @Output() selectError = new EventEmitter();
    @Output() select = new EventEmitter();
    @Output() uploadError = new EventEmitter();
    @Output() uploadSuccess = new EventEmitter();
    @Output('onDelete') onDelete = new EventEmitter<FileInfo>();
    @Output('onFileDescriptionChange') onFileDescriptionChange = new EventEmitter<string[]>();

    get acceptedExtensions(): string {
        return this.config.formatsAllowed.join(', ').trim();
    }

    get formatsAllowed(): string {
        return this.config.formatsAllowed.join(', ').replace(/\./gi, '').trim();
    }

    get maxFileSize(): number {
        return this.config.maxFileSize * 1024000;
    }

    get fullStringUploadRestrictions() {
        // Expressed in MB
        const maxSize = this.filesizePipe.transform(this.config.maxFileSize * 1024000);
        const formats = this.config.formatsAllowed.join(', ').replace(/\./gi, '').trim();
        return `Dimensione massima file: ${maxSize}  -  Formati Accettati: ${formats}`;
    }

    uploadedFiles: any[] = [];
    @Output() uploadedFiles$: Subject<FileInfo[]> = new Subject();

    private uploadSubscriptions: { [id: string]: Subscription } = {};

    currentUploadPercent$: Observable<number> = of(0);

    uploadEnabled: boolean;
    disableUploadButton: boolean;
    @Output() selectedFiles: { [id: string]: FileInfo };
    @Output() rejectedFiles: { [id: string]: FileNotAllowed };

    get selectedFilesLength(): number {
        return Object.keys(this.selectedFiles).length;
    }

    get rejectedFilesLength(): number {
        return Object.keys(this.rejectedFiles).length;
    }

    selectFileButtonText: string = this.config.multiple ? 'Scegli files' : 'Scegli file';

    // Subscription
    private subscriptions: Subscription = new Subscription();

    //  <!======= CLASSI DA AGGIUNGERE PER COMPORTAMENTI =======!>
    //  <!======= CLASSI DA AGGIUNGERE PER COMPORTAMENTI =======!>
    //  <!======= CLASSI DA AGGIUNGERE PER COMPORTAMENTI =======!>

    // FUNZIONE RIMUOVI ELEMENTO
    // public removeRejectedFile(): void {
    // '.single-item.isRemoved' -> addClass -> 'file-rejected-removed'
    // }

    // FUNZIONE RICHIEDI CONFERMA DELETE
    public confirmDelete: boolean[] = [];

    constructor(private filesizePipe: FileSizePipe, private fileService: FileService) {}

    ngOnInit(): void {
        this.uploadEnabled = true;
        this.disableUploadButton = false;
        this.resetFileUpload();

        // const sub = this.fileService.currentUploadStatus$.subscribe((percent) => {
        //     console.log('update status upload: ', percent, this.progressBar.nativeElement);
        //     this.progressBar.nativeElement.width = `${percent}%`;
        // });
        // this.subscriptions.add(sub);

        if (this.preloadFiles) {
            this.uploadedFiles = this.preloadFiles.map((f) => new FileInfo().from(f));
            console.log('uploadedfiles', this.uploadedFiles);
            this.checkControls();
        }
    }

    ngAfterViewInit(): void {}

    ngOnDestroy(): void {
        this.uploadEnabled = false;
        //this.deleteUnsentFiles();
        this.subscriptions.unsubscribe();
    }

    // @HostListener('window:beforeunload', ['$event'])
    // beforeunloadHandler(event) {
    //     this.deleteUnsentFiles();
    // }
    //
    // // Send requests to API for delete no-attached Files
    // private deleteUnsentFiles() {
    //     if (this.uploadedFiles.length > 0) {
    //         this.uploadedFiles.forEach((f) => {
    //             this.fileService.delete(f.ID).subscribe();
    //         });
    //     }
    // }

    drop(event: any) {
        event.stopPropagation();
        event.preventDefault();
        // console.log('drop: ', event);
        // console.log('drop: ', event.dataTransfer.files);
        this.onSelectFiles(event);
    }

    allowDrop(event: any) {
        event.stopPropagation();
        event.preventDefault();
        event.dataTransfer.dropEffect = 'copy';
        // console.log('allowDrop: ',event)
    }

    public resetFileUpload(): void {
        this.uploadEnabled = true;
        this.disableUploadButton = false;
        this.selectedFiles = {};
        this.rejectedFiles = {};
        this.uploadedFiles = [];
        this.uploadedFiles$.next(this.uploadedFiles);
        this.checkControls();
    }

    public onSelectFiles(event: any): void {
        // reset della variabile opzionale
        this.rejectedFiles = {};

        let files: FileList;
        if (event.type === 'drop') {
            files = event.dataTransfer.files;
        } else {
            files = event.target.files || event.srcElement.files;
        }

        // Se non è consentito l'upload multiplo ma viene caricato più di un file (impossibile per via dell'attr sull'input:file)
        // allora viene 'tagliata' la parte eccedente dell'array
        // if(!this.config.multiple && files.length > 1)

        for (let i = 0; i < files.length; i++) {
            const fileInfo = new FileInfo();
            fileInfo.name = files[i].name;
            fileInfo.size = files[i].size;
            fileInfo.lastModified = files[i].lastModified;
            fileInfo.slice = files[i].slice;
            fileInfo.type = files[i].type;
            fileInfo.interface = files[i];
            console.log(`index ${i}:`, files[i], ' - fileInfo: ', fileInfo);

            const nameParts = files[i].name.split('.');
            const ext = nameParts[nameParts.length - 1];
            if (!ext || this.config.formatsAllowed.length === 0) {
                // Estensione non valida o estensioni consentite non configurate
            } else {
                const check = this.config.formatsAllowed.some((f) => f === `.${ext}`);

                if (!check) {
                    this.rejectedFiles[fileInfo.guid] = new FileNotAllowed(fileInfo, 'Estensione non valida');
                    continue;
                }
            }

            if (files[i].size > this.maxFileSize) {
                this.rejectedFiles[fileInfo.guid] = new FileNotAllowed(fileInfo, 'Dimensione maggiore rispetto al limite stabilito');
                continue;
            } else {
                // format allowed and size allowed then add file to selectedFile array
                this.selectedFiles[fileInfo.guid] = fileInfo;
            }
        }

        event.target.value = null;
        console.log('this.selectedFiles: ', this.selectedFiles);
        console.log('this.rejectedFiles: ', this.rejectedFiles);

        const rejectedCount = Object.keys(this.rejectedFiles).length;
        if (rejectedCount > 0) {
            this.selectError.emit(this.rejectedFiles);
        }

        this.select.emit(this.selectedFiles);

        const selectedCount = Object.keys(this.selectedFiles).length;
        if (this.config.autoUpload && selectedCount > 0) {
            this.upload();
            this.filesDescription.push('');
        }
    }

    public removeSelectedFile(file: FileInfo): void {
        if (confirm(`Sei sicuro di voler eliminare il file ${file.name}?`)) {
            delete this.selectedFiles[file.guid];
            if (this.uploadSubscriptions[file.guid]) {
                this.uploadSubscriptions[file.guid].unsubscribe();
            }
        }

        const selectedCount = Object.keys(this.selectedFiles).length;
        if (selectedCount === 0) {
            this.disableUploadButton = true;
        }
    }

    public askForConfirmDelete(index: number): void {
        // '.single-item.isRemoved' -> addClass -> 'confirm-delete'
        this.confirmDelete[index] = true;
    }

    // FUNZIONE VECCHIA
    // public removeUploadedFile(index: number): void {
    //      let file = this.uploadedFiles[index];
    //      if (confirm(`Sei sicuro di voler eliminare il file ${file.name}?`)) {
    //      this.uploadedFiles.splice(index, 1);
    //      this.uploadedFiles$.next(this.uploadedFiles);
    //      this.checkControls();
    //      }
    // }

    //  <!======= CLASSI DA AGGIUNGERE PER COMPORTAMENTI =======!>
    //  <!======= CLASSI DA AGGIUNGERE PER COMPORTAMENTI =======!>
    //  <!======= CLASSI DA AGGIUNGERE PER COMPORTAMENTI =======!>

    // FUNZIONE NUOVA
    public removeUploadedFile(index: number, file: FileInfo): void {
        console.log('remove uploaded file');
        if (confirm(`Sei sicuro di voler eliminare il file ${file.name}?`)) {
            this.uploadedFiles.splice(index, 1);
            this.uploadedFiles$.next(this.uploadedFiles);
            this.checkControls();
        }
        // .single-item.isRemoved' -> addClass -> 'file-rejected-removed'
    }

    private checkControls() {
        if (this.config.multiple) {
        } else {
            if (this.uploadedFiles.length > 0) {
                this.selectedFiles = {};
                this.disableUploadButton = true;
                this.uploadEnabled = false;
            } else {
                this.selectedFiles = {};
                this.disableUploadButton = true;
                this.uploadEnabled = true;
            }
        }
    }

    private upload(): void {
        console.log('FileUploaderComponent -> upload called!');

        const keys = Object.keys(this.selectedFiles);
        for (const key of keys) {
            const file = this.selectedFiles[key];
            if (file) {
                this.uploadSubscriptions[file.guid] = this.fileService.upload([file.interface]).subscribe(
                    (files) => {
                        const subs$ = this.uploadSubscriptions[file.guid];
                        if (!subs$.closed) {
                            const fileinfos = files.map((f) => new FileInfo().from(f));

                            this.uploadedFiles = [].concat(this.uploadedFiles, fileinfos);
                            this.uploadedFiles$.next(this.uploadedFiles);
                            this.checkControls();
                            this.uploadSuccess.emit();

                            delete this.selectedFiles[file.guid];
                            this.select.emit(this.selectedFiles);
                        }
                    },
                    (error) => {
                        this.uploadError.emit(error);
                    }
                );

                this.subscriptions.add(this.uploadSubscriptions[file.guid]);
            }
        }
    }

    public download($event: MouseEvent, ID: number, name: string): void {
        $event.stopImmediatePropagation();
        this.fileService.download(ID, name);
    }

    public delete($event: MouseEvent, file: FileInfo) {
        $event.stopImmediatePropagation();
        this.onDelete.emit(file);
    }

    public changeFileDescription($event, index) {
        $event.stopImmediatePropagation();
        this.filesDescription = Object.assign([], this.filesDescription, { [index]: $event.target.value });
        this.onFileDescriptionChange.emit(this.filesDescription);
    }
}
