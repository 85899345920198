// @angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// External libs
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

// App Models
import { Region } from 'src/app/models/region.model';

// App Services
import { BaseService } from './base.service';

@Injectable()
export class RegionService extends BaseService {
    private regions: Observable<Region[]> = null;

    constructor(private http: HttpClient) {
        super(http);

        this.getAll();
    }

    public getAll(): Observable<Region[]> {
        if (!this.regions) {
            this.regions = this.http.get<Region[]>(this.baseUrl + `region`).pipe(publishReplay(1), refCount());
        }

        return this.regions;
    }
}
