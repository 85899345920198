import { Municipality } from './municipality.model';
import { FormGroup } from '@angular/forms';

export class CompanyAddress {
    id: number;
    companyId: string;
    municipalityId: number;
    address: string;
    zipCode: string;
    isMain: boolean;
    isReadOnly: boolean;
    latitude: number;
    longitude: number;
    municipality: Municipality;
    created: Date;
    createdBy: string;

    constructor(form: FormGroup) {
        if (form) {
            this.id = form.value.id;
            this.companyId = form.value.companyId;
            this.municipalityId = form.value.municipalityId;
            this.address = form.value.address;
            this.zipCode = form.value.zipCode;
            this.isMain = form.value.isMain;
            this.isReadOnly = form.value.isReadOnly;
            this.latitude = form.value.latitude;
            this.longitude = form.value.longitude;
            this.created = form.value.created;
            this.createdBy = form.value.createdBy;
        }
    }
}
