// @angular
import { Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// External libs
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// App Models
import { CooperationCompany } from 'src/app/models/cooperation-company.model';
import { Cooperation } from 'src/app/models/cooperation.model';

// App Services
import { CooperationService } from 'src/app/services/resources/cooperation.service';
import { FileService } from 'src/app/services/resources/file.service';

// App Components
import { WidgetCooperationCompanyComponent } from 'src/app/modules/shared/components/widget-cooperation-company/widget-cooperation-company.component';

@Component({
    selector: 'widget-cooperation-company-partner',
    templateUrl: './widget-cooperation-company-partner.component.html',
    styleUrls: ['./widget-cooperation-company-partner.component.scss'],
})
export class WidgetCooperationCompanyPartnerComponent implements OnInit, OnDestroy {
    @Input('cooperationId') cooperationId: string;
    @Input('cooperation') cooperation: Cooperation;
    @Input('showNumber') showNumber: number;

    companies: CooperationCompany[];
    showCompanies: CooperationCompany[];

    @ViewChild('showAllPartnerCompanies', { static: true }) showAllPartnerCompanies: TemplateRef<ElementRef>;

    private subscription: Subscription = new Subscription();

    constructor(
        private modalService: NgbModal,
        private cooperationService: CooperationService,
        private fileService: FileService,
        private sanitizer: DomSanitizer,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        //console.log('WidgetCooperationCompanyComponent - ngOnInit!!!');
        if (!this.cooperation) {
            if (this.cooperationId) {
                const sub = this.cooperationService.getPartnerCompanies(this.cooperationId).subscribe((companies) => {
                    this.companies = companies;

                    if (companies && companies.length > 0) {
                        if (this.showNumber) {
                            this.showCompanies = companies.slice(0, this.showNumber);
                        } else {
                            this.showCompanies = companies;
                        }
                    }
                });
                this.subscription.add(sub);
            }
        }
    }

    ngOnDestroy(): void {
        //console.log('WidgetCooperationCompanyComponent - ngOnDestroy!!!');
        this.subscription.unsubscribe();
    }

    showAll() {
        const modalRef = this.modalService.open(this.showAllPartnerCompanies, { backdrop: true, size: 'lg' });
        modalRef.componentInstance.companies = this.companies;
    }

    showCompanyModal() {
        const modalRef = this.modalService.open(WidgetCooperationCompanyComponent, { backdrop: true, size: 'xl' });
        modalRef.componentInstance.cooperationId = this.cooperationId;
        modalRef.componentInstance.companies = this.companies;
        modalRef.result.then(
            () => {
                const sub = this.cooperationService.getPartnerCompanies(this.cooperationId).subscribe((companies) => {
                    this.companies = companies;

                    if (companies && companies.length > 0) {
                        if (this.showNumber) {
                            this.showCompanies = companies.slice(0, this.showNumber);
                        } else {
                            this.showCompanies = companies;
                        }
                    }
                });
                this.subscription.add(sub);
            },
            (reason) => {
                console.log(reason);
            }
        );
    }
}
