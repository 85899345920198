// @angular
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// External libs
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, filter, startWith, switchMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

// App Models
import { Company } from 'src/app/models/company.model';
import { Role } from 'src/app/models/role.model';
import { UserRole } from 'src/app/models/user-role.model';
import { UserSearch } from 'src/app/models/user-search.model';
import { User } from 'src/app/models/user.model';

// App Services
import { NotificationService } from 'src/app/services/notification.service';
import { CompanyService } from 'src/app/services/resources/company.service';
import { UserService } from 'src/app/services/resources/user.service';

@Component({
    selector: 'widget-company-users',
    templateUrl: './widget-company-users.component.html',
    styleUrls: ['./widget-company-users.component.scss'],
})
export class WidgetCompanyUsersComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input('companyInfo') company: Company;
    @Input('companyId') companyId: string;
    @Input('users') users: UserRole[];
    @Input('roles') roles: Role[];

    userSearchForm: FormGroup;
    availableRoles$: BehaviorSubject<Role[]> = new BehaviorSubject<Role[]>([]);
    users$: Observable<User[]>;
    availableUsers$: BehaviorSubject<UserRole[]> = new BehaviorSubject<UserRole[]>([]);
    selectedUser: User;

    addUser: boolean = false;

    get controls() {
        return this.userSearchForm.controls;
    }

    private subscriptions: Subscription = new Subscription();

    constructor(
        private activeModal: NgbActiveModal,
        private notification: NotificationService,
        private userService: UserService,
        private companyService: CompanyService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        //console.log('WidgetCompanyUsersComponent - ngOnInit!!!');
        this.userSearchForm = new FormGroup(
            {
                keyword: new FormControl(null, [Validators.required]),
                roleId: new FormControl(null, [Validators.required]),
            },
            { updateOn: 'change' }
        );

        this.availableRoles$ = new BehaviorSubject<Role[]>(this.roles);
        this.availableUsers$ = new BehaviorSubject<UserRole[]>(this.users);
    }

    ngAfterViewInit(): void {
        //console.log('WidgetCompanyUsersComponent - ngAfterViewInit!!!');
        this.availableRoles$ = new BehaviorSubject<Role[]>(this.roles);
        this.availableUsers$ = new BehaviorSubject<UserRole[]>(this.users);

        const emptyList = of([] as User[]);
        this.users$ = this.userSearchForm.get('keyword').valueChanges.pipe(
            startWith(null),
            debounceTime(300),
            filter((value) => value && value.length > 2 && typeof value === 'string'),
            switchMap((value: string) => {
                if (!value || value.length < 2) return emptyList;
                else {
                    let criteria: UserSearch = new UserSearch();
                    criteria.keyword = value;
                    return this.userService.quickSearch(criteria);
                }
            })
        );
    }

    ngOnDestroy(): void {
        //console.log('WidgetCompanyUsersComponent - ngOnDestroy!!!');
        this.subscriptions.unsubscribe();
    }

    public closeAction(reason: string) {
        this.activeModal.dismiss(reason);
    }

    insertUser() {
        if (this.selectedUser) {
            let check = this.users.some((ur) => ur.userId == this.selectedUser.id && ur.roleId == this.userSearchForm.get('roleId').value.id);
            if (check) {
                this.notification.warning('Associazione già presente con il ruolo selezionato', 'Associazione Utente/cooperativa');
            } else {
                let userRole: UserRole = new UserRole(null);
                userRole.role = this.userSearchForm.get('roleId').value;
                userRole.roleId = (this.userSearchForm.get('roleId').value as Role).id;
                userRole.userId = this.selectedUser.id;
                userRole.user = this.selectedUser;
                this.users.push(userRole);
                this.changeDetectorRef.detectChanges();
                this.addUser = false;
            }
        }

        this.userSearchForm.get('keyword').setValue('');
        this.userSearchForm.get('roleId').setValue(null);
    }

    getUser(value: User) {
        this.selectedUser = value;
        this.userSearchForm.get('keyword').setValue(value.email);
    }

    saveUsers() {
        console.log('SaveUsers!!!!');
        const companySub = this.companyService.updateUsers(this.companyId, this.users).subscribe(
            () => {
                this.notification.success('Utenti della cooperativa aggiornati correttamente.', 'Aggiornamento Cooperativa');
                this.activeModal.close();
            },
            (response: HttpErrorResponse) => {
                this.notification.error(response.error.message, 'Errore aggiornamento utenti cooperativa');
            }
        );
        this.subscriptions.add(companySub);
    }

    onDeleted(userId: string, roleId: string) {
        let check = this.users.some((u) => u.userId == userId && u.roleId == roleId);
        if (check) {
            let index = this.users.findIndex((u) => u.userId == userId && u.roleId == roleId);
            this.users.splice(index, 1);
            this.changeDetectorRef.detectChanges();
        }
    }

    showForm() {
        this.addUser = !this.addUser;
    }
}
