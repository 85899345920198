<div class="container-fluid mt-n5 mb-5 pb-5">
    <div class="row">
        <!-- TITOLO -->
        <div class="container mt-n5">
            <h1 class="text-heading text-bigger text-blue mb-4 mt-n5" data-aos="fade-up">Ricerca <span class="font-underliner">intorno a te</span></h1>
        </div>

        <!-- MAPPA -->
        <div id="floating-panel" class="container mx-auto input-group mt-0 mt-md-n4" data-aos="fade-up" data-aos-delay="200">
            <button id="button-center" type="button" class="btn btn-main rounded-circle mr-2" (click)="setCenter($event)">
                <fa-icon [icon]="['fas', 'crosshairs']"></fa-icon>
            </button>
            <input id="addresstext" type="text" class="form-control custom-input position-relative" #addresstext />
            <button id="button-cerca" type="button" class="btn btn-main angle-bottom-sx" (click)="searchMap($event)">
                <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
        </div>
        <div class="col-lg-7 mt-n5 mt-md-0 pl-0 pr-0" data-aos="fade-right" data-aos-delay="200">
            <div id="map" class="shadow-default"></div>
        </div>

        <!-- SIDEBAR -->
        <div class="sidebar-map-wrapper col-md-10 mx-auto col-lg-4 col-xl-3 bg-blue mt-n5 mt-lg-0 shadow-default mx-lg-0" data-aos="fade-left" data-aos-delay="300">
            <h2 class="text-white mt-4">Filtra per categoria</h2>
            <div class="row mt-4">
                <div class="col-12 check-products-wrapper pb-4">
                    <ul class="list-unstyled m-0">
                        <li class="text-white" *ngFor="let item of data.ParentChildchecklist">
                            <div class="d-flex flex-row justify-content-between p-2">
                                <input
                                    type="checkbox"
                                    class="d-none check-item-checkbox"
                                    [(ngModel)]="item.isSelected"
                                    name="list_name"
                                    id="id-product-checkbox-{{ item.id }}"
                                    value="{{ item.id }}"
                                    (ngModelChange)="parentCheck(item)"
                                />
                                <label
                                    for="id-product-checkbox-{{ item.id }}"
                                    class="check-item-name cursor-pointer position-relative text-uppercase pl-4 text-white text-bold text-uppercase text-bigger"
                                >
                                    {{ item.value }}
                                </label>
                                <span (click)="expandCollapse(item)" class="cursor-pointer ml-2">
                                    <fa-icon [icon]="['fas', 'caret-down']" *ngIf="item.isClosed; else isCollapsed"></fa-icon>
                                    <ng-template #isCollapsed><fa-icon [icon]="['fas', 'caret-up']"></fa-icon></ng-template>
                                </span>
                            </div>

                            <div class="child-list" [hidden]="item.isClosed">
                                <ul class="list-unstyled pl-2 m-0">
                                    <li class="text-white pl-4" *ngFor="let itemChild of item.childList">
                                        <input
                                            type="checkbox"
                                            class="d-none check-item-checkbox"
                                            [(ngModel)]="itemChild.isSelected"
                                            name="list_name_child"
                                            value="{{ itemChild.id }}"
                                            id="id-product-checkbox-{{ item.id }}-{{ itemChild.id }}"
                                            (ngModelChange)="childCheck(item, item.childList)"
                                        />
                                        <label
                                            for="id-product-checkbox-{{ item.id }}-{{ itemChild.id }}"
                                            class="cursor-pointer check-item-name position-relative text-uppercase pl-4 text-white text-bold text-uppercase"
                                            >{{ itemChild.value }}</label
                                        >
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- DEVE ESSERE ASINCRONA! -->
            <!-- <button type="button" class="btn btn-main shadow-default text-uppercase angle-bottom-sx mt-4 mb-4" (click)="search()">RICERCA</button> -->
        </div>
    </div>
</div>
