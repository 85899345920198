// @angular
import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';

// External libs
import { Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { concatMap } from 'rxjs/operators';

// App Helpers
import { environment } from 'src/environments/environment';

// App Models
import { Company } from 'src/app/models/company.model';
import { Message } from 'src/app/models/message.model';

// App Services
import { CompanyService } from 'src/app/services/resources/company.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { MailService } from 'src/app/services/resources/mail.service';
import { AuthService } from 'src/app/services/resources/auth.service';
import { FileUploaderComponent } from 'src/app/modules/shared/components/file-uploader/file-uploader.component';
import { FileUploaderConfig } from 'src/app/modules/shared/components/file-uploader/file-uploader-config.model';
import { MaskMessage } from 'src/app/models/mask-message.model';

@Component({
    selector: 'masks-request',
    templateUrl: './mask-request.component.html',
    styleUrls: ['./mask-request.component.scss'],
})
export class MaskRequestComponent implements OnInit, OnDestroy, AfterViewInit {
    config: FileUploaderConfig;

    constructor(
        private companyService: CompanyService,
        private navigationService: NavigationService,
        private titleService: Title,
        private mailService: MailService,
        private toastr: ToastrService,
        private authService: AuthService
    ) {}

    form: FormGroup;
    company: Company;
    company$: Observable<Company>;
    isGDPRAccepted: boolean = false;
    @ViewChild(FileUploaderComponent) uploader: FileUploaderComponent;
    private subscription = new Subscription();

    ngOnInit() {
        console.log('MaskRequestComponent - ngOnInit!!');
        this.titleService.setTitle(`${environment.appName} - Contatta Insieme`);

        this.config = new FileUploaderConfig();
        this.config.multiple = false;
        this.config.formatsAllowed = ['.xls', '.xlsx'];

        this.form = new FormGroup({
            senderMail: new FormControl(),
            message: new FormControl(null),
            privacy: new FormControl(false, Validators.requiredTrue),
            excelFile: new FormControl(null, Validators.required),
        });

        this.company$ = this.authService.companyId$.asObservable().pipe(concatMap((id) => this.companyService.getById(id)));

        let sub = this.company$.subscribe((c) => {
            this.company = c;
            console.log('company', c);
        });
        this.subscription.add(sub);

        sub = this.privacy.valueChanges.subscribe((checked) => {
            this.isGDPRAccepted = !!checked;
        });
        this.subscription.add(sub);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    get privacy() {
        return this.form.controls['privacy'] as FormControl;
    }

    onSubmit(): void {
        let message = new MaskMessage();
        message.mailFrom = this.company.email;
        message.message = this.form.get('message').value;
        message.companyId = this.company.id;
        message.fileId = this.form.get('excelFile').value;
        const sub = this.mailService.postMasksRequest(message).subscribe(
            () => {
                this.toastr.success('Il messaggio è stato inviato correttamente');
                this.form.reset();
                this.uploader.resetFileUpload();
            },
            () => {
                this.toastr.warning(`C'è stato un errore! Il messaggio non è stato recapitato correttamente`);
            }
        );
        this.subscription.add(sub);
    }

    ngAfterViewInit(): void {
        console.log('MaskRequestComponent - ngAfterViewInit!!');

        const sub = this.uploader.uploadedFiles$.asObservable().subscribe((files) => {
            const file = files[0];
            if (file) {
                this.form.get('excelFile').setValue(file.ID);
            } else {
                this.form.get('excelFile').setValue(null);
            }
        });
        this.subscription.add(sub);
    }
}
