// @angular
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

// External libs
import { BehaviorSubject, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// App Models
import { Company } from 'src/app/models/company.model';
import { ProductService as Service } from 'src/app/models/product-service.model';
import { Product } from 'src/app/models/product.model';

// App Services
import { CompanyService } from 'src/app/services/resources/company.service';
import { ProductService } from 'src/app/services/resources/product.service';
import { AuthService } from 'src/app/services/resources/auth.service';
import { NotificationService } from 'src/app/services/notification.service';

//App Component
import { ProductServicePickerComponent } from '../product-service-picker/product-service-picker.component';

@Component({
    selector: 'widget-product-service',
    templateUrl: './widget-product-service.component.html',
    styleUrls: ['./widget-product-service.component.css'],
})
export class WidgetProductServiceComponent implements OnInit, OnDestroy {
    @Input('companyInfo') company: Company;
    @Input('companyId') companyId: string;
    @Input('editMode') editMode: boolean;

    productService$ = new BehaviorSubject<Product[]>([]);
    productservices: Service[] = [];
    isLoggedIn: Boolean;

    private subscription: Subscription = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private modalService: NgbModal,
        private authService: AuthService,
        private companyService: CompanyService,
        private productService: ProductService,
        private notification: NotificationService
    ) {}

    ngOnInit(): void {
        //console.log('WidgetProductServiceComponent - ngOnInit!!!');
        this.isLoggedIn = this.authService.isLoggedIn;

        if (!this.company) {
            if (this.companyId) {
                const sub = this.companyService.getProductServices(this.companyId).subscribe((product) => {
                    this.productservices = product;
                });
                this.subscription.add(sub);
            }
        }
    }

    ngOnDestroy(): void {
        //console.log('WidgetProductServiceComponent - ngOnDestroy!!!');
        this.subscription.unsubscribe();
    }

    replace(name: string): string {
        name = name.toLowerCase().replace(/\s/g, '-').replace(/[/]/g, '-').replace(/[à]/, 'a');
        return name;
    }

    searchProductService(serviceId: number) {
        if (serviceId) {
            if (this.isLoggedIn) this.router.navigate(['/area-riservata/company/results/productservice', serviceId]);
            else this.router.navigate(['/company/results/productservice', serviceId]);
        }
    }

    openProductModal() {
        const productSub = this.productService.getAll().subscribe((products) => {
            this.productService$.next(products);
            const modalRef = this.modalService.open(ProductServicePickerComponent, { backdrop: 'static', size: 'xl' });
            modalRef.componentInstance.itemSelected = this.productservices.map((x) => x.id);
            modalRef.componentInstance.productService$ = this.productService$;
            modalRef.componentInstance.passEntry.subscribe((result) => {
                if (result) {
                    let selectedItems: number[] = result.map((x) => x.id);
                    console.log(selectedItems);

                    const sub = this.companyService.updateProductServices(this.companyId, selectedItems).subscribe(
                        () => {
                            this.notification.success('Prodotti e Servizi della cooperativa sono stati aggiornati correttamente.', 'Aggiornamento Cooperativa');
                            const prodSub = this.companyService.getProductServices(this.companyId).subscribe((data) => (this.productservices = data));
                            this.subscription.add(prodSub);
                        },
                        (response: HttpErrorResponse) => {
                            this.notification.error(response.error.message, 'Errore aggiornamento prodotti e servizi');
                        }
                    );
                    this.subscription.add(sub);
                }
            });
        });
        this.subscription.add(productSub);
    }
}
