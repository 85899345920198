// @angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//External libs
import { Observable } from 'rxjs';

//App Models
import { CooperationType } from 'src/app/models/cooperation-type.model';

//App Service
import { BaseService } from 'src/app/services/resources/base.service';

@Injectable({
    providedIn: 'root',
})
export class CooperationTypeService extends BaseService {
    constructor(private http: HttpClient) {
        super(http);
    }

    public getAll(): Observable<CooperationType[]> {
        return this.http.get<Array<CooperationType>>(this.baseUrl + 'cooperationType');
    }
}
