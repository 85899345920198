import { FileInfo } from './file-info.model';
import { Company } from './company.model';
import { ProductService } from './product-service.model';
import { Invitation } from './invitation.model';
import { CooperationLink } from './cooperation-link.model';
import { PurchaseItem } from './purchase-item.model';
import { CooperationType } from './cooperation-type.model';
import { FormGroup } from '@angular/forms';
import * as Globalize from 'globalize/dist/globalize';

export class Cooperation {
    competitionDeadline?: Date;
    competitionCallDate?: Date;
    created: Date;
    createdBy: string;
    description: string;
    files: Array<FileInfo>;
    filesId: Array<number>;
    id: string;
    invitations: Array<Invitation>;
    isApproved: boolean;
    isDraft: boolean;
    isPrivate: boolean;
    jobContractualStatus: string;
    jobPosition: string;
    jobRequiredQualifications: string;
    jobRevenue?: number;
    jobSector: string;
    link: string;
    links: Array<CooperationLink>;
    linksUrl: Array<string>;
    ownedCompanyId: string;
    partnerCompanies: Company[];
    partnerCompaniesId: string[];
    productServices: Array<ProductService>;
    productServicesId: Array<number>;
    purchaseItems: Array<PurchaseItem>;
    status: string;
    title: string;
    typeId: number;
    type: CooperationType;
    fromAssociation: boolean;

    constructor(form: FormGroup) {
        this.competitionDeadline = form.value.competitionDeadline;
        this.competitionCallDate = form.value.competitionCallDate;
        this.description = form.value.description;
        this.filesId = form.value.filesId;
        this.isApproved = form.value.isApproved;
        this.isDraft = form.value.isDraft;
        this.isPrivate = form.value.isPrivate == '1';
        this.jobContractualStatus = form.value.jobContractualStatus;
        this.jobPosition = form.value.jobPosition;
        this.jobRequiredQualifications = form.value.jobRequiredQualifications;
        this.jobRevenue = form.value.jobRevenue ? Globalize.parseNumber(form.value.jobRevenue.toString().replace('.', '.')) : null;
        this.jobSector = form.value.jobSector;
        this.linksUrl = form.value.links;
        this.ownedCompanyId = form.value.ownedCompanyId;
        this.partnerCompanies = form.value.partnerCompanies;
        this.purchaseItems = form.value.purchaseItems?.map((pi) => new PurchaseItem(pi));
        this.title = form.value.title;
        this.type = form.value.type;
        this.typeId = form.value.typeId;
        this.files = form.value.files;
        this.productServicesId = form.value.productService;
        this.fromAssociation = form.value.fromAssociation;
    }
}
