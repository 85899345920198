// @angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// App Helpers
import { environment } from 'src/environments/environment';

@Injectable()
export class BaseService {
    // TO API ENDPOINTS
    baseUrl: string;
    feedUrl: string;

    constructor(http: HttpClient) {
        this.baseUrl = environment.baseApiUrl;
        this.feedUrl = environment.feedUrl;
    }
}
