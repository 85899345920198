// @angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// External libs
import { Observable } from 'rxjs';

// App Models
import { Province } from 'src/app/models/province.model';

// App Services
import { BaseService } from './base.service';

@Injectable()
export class ProvinceService extends BaseService {
    constructor(private http: HttpClient) {
        super(http);
    }

    public getAll(): Observable<Province[]> {
        const url = `${this.baseUrl}province`;
        return this.http.get<Province[]>(url);
    }
}
