<ng-container *ngIf="user; else elseTemplate">
    <ng-container *ngIf="!onlyAvatar; else elseAvatar">
        <div class="single-socio input-group mb-3 d-flex flex-row align-items-center justify-content-between shadow-default border-round-pill pl-3">
            <span class="nome-socio d-flex flex-column align-items-start text-blue text-uppercase text-smaller text-ellipsis mw-99">
                <ng-container *ngIf="user.fullName; else onlyEmail"
                    ><span class="text-uppercase text-smaller">{{ user.fullName }}</span></ng-container
                >
                <ng-template #onlyEmail>
                    <ng-container *ngIf="user.businessName; else userSendEmail">
                        <span class="text-uppercase text-smaller">{{ user.businessName }}</span>
                    </ng-container>
                    <ng-template #userSendEmail>
                        <a href="mailto:{{ user.email }}" class="text-blue text-uppercase text-smaller text-ellipsis mw-100">{{ user.email }}</a>
                    </ng-template>
                </ng-template>
                <span class="bg-blue border-round-pill text-white text-center text-smaller ng-star-inserted px-2" *ngIf="role">{{ role?.name }}</span>
            </span>
            <div class="text-center bg-white socio-foto-wrapper border-green rounded-circle">
                <ng-container *ngIf="user.avatarFileId; else elseTemplate">
                    <img [src]="thumbnail" class="h-100 rounded-circle" />
                </ng-container>
                <ng-template #elseTemplate>
                    <ngx-avatar class="text-green text-bold" [name]="user.fullName ? user.fullName : user.businessName ? user.businessName : user.email"></ngx-avatar>
                </ng-template>
            </div>
        </div>
    </ng-container>
    <ng-template #elseAvatar>
        <div class="single-socio input-group mb-3 d-flex flex-row align-items-center justify-content-between shadow-default border-round-pill pl-3">
            <div class="text-center bg-white socio-foto-wrapper border-green rounded-circle">
                <ng-container *ngIf="user.avatarFileId; else elseTemplate">
                    <img [src]="thumbnail" class="h-100 rounded-circle" />
                </ng-container>
                <ng-template #elseTemplate>
                    <ngx-avatar class="text-green text-bold" [name]="user.fullName ? user.fullName : user.businessName ? user.businessName : user.email"></ngx-avatar>
                </ng-template>
            </div>
        </div>
    </ng-template>
</ng-container>
<ng-template #elseTemplate></ng-template>
