export class ProductService {
    id: number;
    name: string;
    productId: ProductServicesNameEnum;
    selected: boolean;
}

export enum ProductServicesNameEnum {
    'default',
    'prodotti-e-servizi',
}

export enum ProductServicePathsEnum {
    '/assets/images/products/prodotti-e-servizi/prodotti-e-servizi.svg',
    '/assets/images/products/prodotti-e-servizi/eventi-spettacoli-convegni.svg',
    '/assets/images/products/prodotti-e-servizi/visite-itinerari-culturali-e-turistici.svg',
    '/assets/images/products/prodotti-e-servizi/laboratori-e-altre-attivita-a-valenza-culturale.svg',
    '/assets/images/products/prodotti-e-servizi/servizi-ricettivi.svg',
    '/assets/images/products/prodotti-e-servizi/servizi-logistici.svg',
    '/assets/images/products/prodotti-e-servizi/food-e-beverage.svg',
    '/assets/images/products/prodotti-e-servizi/messa-a-disposizione-di-strutture.svg',
    '/assets/images/products/prodotti-e-servizi/servizi-ricettivi-disabili.svg'
}
