<div class="modal-header">
    <h2 class="modal-title text-heading text-blue">Invita nuove cooperative</h2>
    <button type="button" class="close modal-state-close modal-close-soci" aria-label="Close" (click)="closeAction('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container">
        <!-- BOTTONE RICERCA -->
        <div class="row mb-4">
            <div class="col-12">
                <button type="button" class="btn btn-main bg-white border-blue d-block shadow-default mr-4 mx-auto px-3 text-blue" (click)="showSearch()">
                    <span class="text-uppercase text-blue text-bold">Invita altre cooperative</span>
                    <fa-icon icon="plus" class="ml-3 text-blue"></fa-icon>
                </button>
            </div>
        </div>

        <!-- WIDGET RICERCA -->
        <form role="form" autocomplete="off" #ricerca id="searchForm" [formGroup]="searchForm">
            <div *ngIf="addCompany">
                <widget-search-company id="widget-search" (search)="onSearch($event)" [isInModal]="true"></widget-search-company>
            </div>

            <!-- RISULTATI RICERCA -->
            <ng-container *ngIf="searchCompanies">
                <div class="row" *ngIf="searchCompanies.length > 0; else noResult">
                    <h3 class="col-12 text-blue text-bigger d-block-inline">Risultati della ricerca</h3>
                    <p class="text-grey text-smaller col-12">Cliccare sulla cooperativa per invitare</p>

                    <div class="col col-6" *ngFor="let c of searchCompanies">
                        <div class="single-company-select px-3 shadow-default input-group mb-3 d-flex flex-row align-items-center justify-content-start border-round-pill pl-0">
                            <input
                                type="checkbox"
                                class="d-none input-company-selected"
                                [value]="c"
                                (change)="onCheckboxChange($event, c)"
                                [id]="c.id"
                                [checked]="isSelected(c.id)"
                            />
                            <span class="static-company-border"></span>
                            <label class="d-inline-block h3 text-heading text-blue text-bold ml-2 mb-0 text-ellipsis mw-99" [for]="c.id">{{ c.name }}</label>
                        </div>
                    </div>
                </div>
                <ng-template #noResult>
                    <h3 class="text-heading text-bold text-blue mb-3 text-center">La ricerca non ha prodotto risultati.</h3>
                </ng-template>
            </ng-container>
        </form>

        <!-- ELENCO COMPANY GIA' SELEZIONATE -->
        <div class="row mt-4">
            <h3 class="modal-title text-heading text-blue col-12 mb-3">Cooperative invitate</h3>
            <div class="input-group single-invited-company col-lg-4 col-md-6" *ngFor="let c of searchForm.get('selectedCompanies').value">
                <div class="d-flex flex-row justify-content-start align-items-center w-100">
                    <quick-company-info [companyInfo]="c"></quick-company-info>
                </div>
            </div>
        </div>

        <hr class="w-100" />

        <div class="row">
            <div class="col col-12 text-center">
                <button type="button" class="btn btn-main angle-bottom-sx shadow-default" (click)="saveCompanies()">
                    <fa-icon icon="check" class="mr-3"></fa-icon>
                    <span class="text-uppercase">Salva</span>
                </button>
            </div>
        </div>
    </div>
</div>
