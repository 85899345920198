export enum CooperationTypeEnum {
    default,
    buyingGroup,
    jobOffer,
    projectIdeas,
    competition,
}

export enum CooperationTypeImageEnum {
    'default',
    'gruppo-di-acquisto',
    'offerta-di-lavoro',
    'idea-progettuale',
    'bando',
}
