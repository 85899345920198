//@angular
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// External libs
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
// App Models
import { User } from 'src/app/models/user.model';
import { RegisterInvitation } from 'src/app/models/register-invitation';
// App Services
import { UserService } from 'src/app/services/resources/user.service';
import { InvitationService } from 'src/app/services/resources/invitation.service';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit, OnDestroy {
    form: FormGroup;
    private subscriptions: Subscription = new Subscription();
    id: string;
    isValid: boolean = false;
    CF_REGEX = '^[A-Z]{6}[A-Z0-9]{2}[A-Z][A-Z0-9]{2}[A-Z][A-Z0-9]{3}[A-Z]$';
    CF_COMPANY_REGEX = '^[0-9]{11}$';
    NAME_SURNAME_REGEX = "^([a-zA-Z\\é\\è\\ò\\à\\ù\\ì\\'\\s]{2,}\\s[a-zA-Z\\é\\è\\ò\\à\\ù\\ì\\'\\s]{1,}'?-?[a-zA-Z\\é\\è\\ò\\à\\ù\\ì\\'\\s]{2,}\\s?([a-zA-Z\\é\\è\\ò\\à\\ù\\ì\\'\\s]{1,})?)";
    isLogged: boolean = false;
    isGDPRAccepted: boolean = false;

    constructor(
        private router: Router,
        route: ActivatedRoute,
        private userService: UserService,
        private invitationService: InvitationService,
        private toastr: ToastrService,
        private titleService: Title
    ) {
        route.params.subscribe((params) => {
            this.id = params['id'];
        });
    }

    ngOnInit(): void {
        this.titleService.setTitle(`${environment.appName} - Registrati`);

        let sub = this.userService.currentUser$.asObservable().subscribe((u) => {
            if (u) this.isLogged = true;
        });
        this.subscriptions.add(sub);

        this.form = new FormGroup({
            id: new FormControl(''),
            username: new FormControl(''),
            fullName: new FormControl(''),
            name: new FormControl(''),
            surname: new FormControl(''),
            businessName: new FormControl('', Validators.required),
            phoneNumber: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
            email: new FormControl('', [Validators.required, Validators.email]),
            fiscalCode: new FormControl(''),
            confirmEmail: new FormControl(''),
            lockoutEnabled: new FormControl('', [Validators.nullValidator]),
            roles: new FormControl([]),
            userSelector: new FormControl(false, Validators.required),
            // privacy: new FormControl(false, Validators.requiredTrue)
        });

        // this.privacy.valueChanges.subscribe(checked=>{
        //     this.isGDPRAccepted = !!checked; 
        // })

        sub = this.form.controls.userSelector.valueChanges.subscribe((value) => {
            if (!value) {
                this.updateValidatorCompany();
            } else {
                this.updateValidatorUser();
            }
            this.form.controls.name.updateValueAndValidity();
            this.form.controls.surname.updateValueAndValidity();
            this.form.controls.businessName.updateValueAndValidity();
            this.form.controls.fiscalCode.updateValueAndValidity();
        });
        this.subscriptions.add(sub);

        sub = this.invitationService.getById(this.id).subscribe((invitation) => {
            if (invitation && !invitation.isAccepted) {
                if (invitation.invitedUser.businessName == null) {
                    //if user is not a company set true user selector (slider "Persona Fisica" = true) and updates validators.
                    this.form.controls.userSelector.setValue(true);
                    this.updateValidatorUser();
                } else {
                    this.updateValidatorCompany();
                }

                this.form.patchValue(invitation.invitedUser);
                this.isValid = true;
            }
        });
        this.subscriptions.add(sub);
    }

    get userSelector() {
        return this.form.get('userSelector') as FormControl;
    }

    // get privacy(){
    //     return this.form.controls['privacy'] as FormControl;
    // }

    ngOnDestroy(): void {
        console.log('RegisterComponent onDestroy');
        this.subscriptions.unsubscribe();
    }

    updateValidatorUser() {
        this.form.controls.name.setValidators(Validators.required);
        this.form.controls.surname.setValidators(Validators.required);
        this.form.controls.fiscalCode.setValidators(null);
        this.form.controls.fiscalCode.setValidators(Validators.pattern(this.CF_REGEX));
        this.form.controls.businessName.setValidators(null);
    }

    updateValidatorCompany() {
        this.form.controls.name.setValidators(null);
        this.form.controls.surname.setValidators(null);
        this.form.controls.businessName.setValidators(Validators.required);
        this.form.controls.fiscalCode.setValidators(null);
        this.form.controls.fiscalCode.setValidators(Validators.pattern(this.CF_COMPANY_REGEX));
    }

    onSubmit() {
        if (this.form.valid) {
            let user = new User(this.form);
            let registration = new RegisterInvitation();
            registration.user = user;
            registration.invitationId = this.id;
            const sub = this.userService.register(registration).subscribe(
                () => {
                    this.toastr.success(`La registrazione è avvenuta con successo! Controlla la tua email ${user.email} per procedere con il login`, 'Registrato!');
                    this.router.navigateByUrl('/login');
                },
                () => {
                    this.toastr.warning('La registrazione non è avvenuta', 'Non Registrato');
                }
            );
            this.subscriptions.add(sub);
        } else {
            this.toastr.error('inserisci tutti i campi obbligatori', 'Attenzione!');
        }
    }
	
	toUppercase(ev: any) {
		this.form.patchValue({ fiscalCode: ev.target.value.toUpperCase() });	
	}	
}
