<div id="partners-cooperation" class="row mt-5 pt-5 mb-5 pb-5">
    <div class="title-section-partners col-12 d-flex flex-row justify-content-between align-items-center w-100 mb-3">
        <p class="text-bold text-blue mb-0">Inviti a cooperare</p>

        <button type="button" class="btn btn-main shadow-default border-blue bg-white text-blue" (click)="showCompanyModal()">
            <fa-icon icon="plus"></fa-icon>
        </button>
    </div>

    <ng-container *ngIf="showCompanies; else noCompanies">
        <div class="col-12">
            <div class="mb-3" *ngFor="let c of showCompanies">
                <quick-company-info [companyInfo]="c.company"></quick-company-info>
            </div>
        </div>

        <!-- <div class="col-12 text-center" *ngIf="companies.length > showNumber && !showNumber"> -->
        <div class="col-12 text-center">
            <button type="button" class="btn btn-main angle-bottom-sx mt-4 shadow-default text-white see-all-companies-btn" (click)="showAll()">
                <span class="text-uppercase">VEDI TUTTI</span>
            </button>
        </div>
    </ng-container>
    <ng-template #noCompanies>
        <h4 class="col-12 text-blue text-center text-smaller">Non sono presenti partners per questa collaborazione</h4>
    </ng-template>
</div>

<ng-template #showAllPartnerCompanies let-modal>
    <div class="modal-header">
        <div class="d-flex flex-row align-items-center justify-content-between w-100">
            <p class="text-heading h2 text-blue mb-0">Inviti a cooperare</p>
            <button type="button" class="close modal-state-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span class="text-grey" aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body partners-modal">
        <div class="row">
            <div class="col-12">
                <widget-cooperation-company-partner [cooperationId]="cooperationId"></widget-cooperation-company-partner>
            </div>
        </div>
    </div>
</ng-template>
