// @angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

// External libs
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';

// App Services
import { AuthService, AuthResponse } from 'src/app/services/resources/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private router: Router, private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const newRequest = this.addAuthenticationToken(request);

        return next.handle(newRequest).catch((response) => {
            if (response instanceof HttpErrorResponse) {
                // token is expired refresh and try again
                if (response.status === 401) {
                    return this.handleUnauthorized(request, next);
                }
            }

            throw response;
        });
    }

    handleUnauthorized(req: HttpRequest<any>, next: HttpHandler) {
        if (this.authService.isPersistent) {
            if (!this.refreshTokenInProgress) {
                this.refreshTokenInProgress = true;

                // Reset here so that the following requests wait until the token
                // comes back from the refreshToken call.
                this.refreshTokenSubject.next(null);
                // get a new token via userService.refreshToken
                return this.authService.refreshLogin().pipe(
                    tap(
                        // do:
                        (ticket: AuthResponse) => {
                            if (ticket.refresh_token) {
                                this.refreshTokenInProgress = false;
                                this.refreshTokenSubject.next(ticket.refresh_token);
                                return next.handle(this.addAuthenticationToken(req));
                            }

                            // If we don't get a new token, we are in trouble so logout.
                            this.refreshTokenInProgress = false;
                            this.authService.logout();
                            this.goToLogin();
                        },
                        // catch error:
                        (error) => {
                            this.refreshTokenInProgress = false;
                            this.authService.logout();
                            this.goToLogin();
                        }
                    )
                );
            } else {
                return this.refreshTokenSubject
                    .filter((result) => result !== null)
                    .take(1)
                    .switchMap(() => next.handle(this.addAuthenticationToken(req)));
            }
        } else {
            this.goToLogin();
        }
    }

    private goToLogin(): void {
        this.authService.updateLoginStatus(false, null);
        this.router.navigateByUrl('/login');
    }

    addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
        const token = AuthService.getToken();

        if (!token) {
            return request;
        }

        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
}
