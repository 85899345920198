import { FormGroup } from '@angular/forms';
import { Role } from './role.model';
import { UserRole } from './user-role.model';
import { FileInfo } from './file-info.model';

export class User {
    id: string;
    username: string;
    email: string;
    emailConfirmed: boolean;
    phoneNumber: string;
    phoneNumberConfirmed: boolean;
    name: string;
    surname: string;
    fullName: string;
    businessName: string;
    displayName: string;
    fiscalCode: string;
    avatarFile: FileInfo;
    avatarFileId: number;
    lockoutEnabled: boolean;
    lockoutEndDate?: Date;
    roles: UserRole[];

    constructor(form: FormGroup) {
        if (form) {
            this.id = form.value.id;
            this.name = form.value.name;
            this.surname = form.value.surname;
            this.displayName = form.value.displayName;
            this.fiscalCode = form.value.fiscalCode;
            this.email = form.value.email;
            this.emailConfirmed = form.value.emailConfirmed;
            this.avatarFileId = form.value.avatarFileId;
            this.fullName = form.value.surname + ' ' + form.value.name;
            this.displayName = form.value.surname + ' ' + form.value.name;
            this.businessName = form.value.businessName;

            if (form.value.username) this.username = form.value.username;
            else this.username = form.value.email;

            this.phoneNumber = form.value.phoneNumber;
            this.phoneNumberConfirmed = form.value.phoneNumberConfirmed;
            this.lockoutEnabled = form.value.lockoutEnabled;
            this.roles = form.value.userRoles;
        }
    }
}
