// @angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// External libs
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

// App Models
import { Role } from 'src/app/models/role.model';

// App Services
import { BaseService } from './base.service';

@Injectable()
export class RoleService extends BaseService {
    roles: Observable<Role[]> = null;

    constructor(private http: HttpClient) {
        super(http);

        this.getAll();
    }

    public getAll(): Observable<Role[]> {
        if (!this.roles) {
            this.roles = this.http.get<Role[]>(this.baseUrl + `role`).pipe(publishReplay(1), refCount());
        }

        return this.roles;
    }

    public getById(id: string): Observable<Role> {
        const url = `${this.baseUrl}role/${id}`;
        return this.http.get<Role>(url);
    }
}
