// @angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

// External libs
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

// App Services
import { LoadingService } from 'src/app/services/navigation/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    activeRequests = 0;

    /**
     * URLs for which the loading screen should not be enabled
     */
    skippUrls = [
        '/refresh',
        'api/file/new',
        'api/message/unread',
        // Execution History -> mark as read request
        /\/api\/execution\/(\{{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}\}{0,1})\/read/gim,
    ];

    constructor(private loadingService: LoadingService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let displayLoading = true;

        for (const skippUrl of this.skippUrls) {
            if (new RegExp(skippUrl).test(request.url)) {
                displayLoading = false;
                break;
            }
        }

        if (displayLoading) {
            if (this.activeRequests === 0) {
                this.loadingService.startLoading();
            }
            this.activeRequests++;

            return next.handle(request).pipe(
                finalize(() => {
                    this.activeRequests--;

                    if (this.activeRequests === 0) {
                        this.loadingService.stopLoading();
                    }
                })
            );
        } else {
            return next.handle(request);
        }
    }
}
