<div id="soci" class="row">
    <div class="col-12">
        <!-- TITOLO -->
        <div class="block-typology d-flex flex-row align-items-center justify-content-between mb-4">
            <p class="soci-title text-heading text-bold text-blue text-bigger mb-0 d-block d-sm-none">Soci della cooperativa</p>
            <p class="soci-title text-bold text-blue text-bigger mb-0 d-none d-sm-block">Soci della cooperativa</p>
        </div>

        <!-- SINGOLO ELEMENTO SOCIO -->
        <ng-container *ngIf="users; else noUsers">
            <div class="row">
                <div class="col col-12" *ngFor="let u of showUsers">
                    <user-roles-info [userInfo]="u"></user-roles-info>
                </div>
            </div>

            <div class="row" *ngIf="users.length > showNumber && showNumber">
                <div class="col col-12">
                    <button type="button" class="show-soci-btn border-green bg-white border-round-pill text-green py-1 px-3 mt-4 mx-auto d-block" (click)="showAll()">
                        <span class="text-uppercase text-green">VEDI TUTTI</span>
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-template #noUsers>
            <span class="text-danger text-smaller">Non ci sono soci per questa cooperativa</span>
        </ng-template>
    </div>
</div>

<ng-template #AllAssociates let-modal>
    <div class="modal-header">
        <h2 class="modal-title text-heading text-blue">Soci della cooperativa</h2>
        <button type="button" class="close modal-state-close modal-close-soci" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span class="text-grey" aria-hidden="true">&times;</span>
        </button>
    </div>
    <div id="modal-body-soci" class="modal-body">
        <div class="row">
            <div class="col-12">
                <widget-company-associates [companyId]="companyid" [editMode]="false"></widget-company-associates>
            </div>
        </div>
    </div>
</ng-template>
