// @angular
import { Component, OnInit, AfterViewInit, OnDestroy, Output, EventEmitter, ViewEncapsulation, Input, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';

// External Libs
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// App Models
import { Product } from 'src/app/models/product.model';
import { Certification } from 'src/app/models/certification.model';
import { Province } from 'src/app/models/province.model';
import { CompanySearch } from 'src/app/models/company-search.model';
import { Territory } from 'src/app/models/territory.model';
import { Employees } from 'src/app/models/employees.model';
import { Revenuee } from 'src/app/models/revenuee.model';
import { ProductService } from 'src/app/models/product-service.model';

// App Services
import { NotificationService } from 'src/app/services/notification.service';
import { ProductService as pService } from 'src/app/services/resources/product.service';
import { CertificationService } from 'src/app/services/resources/certification.service';
import { ProvinceService } from 'src/app/services/resources/province.service';
import { TerritoryService } from 'src/app/services/resources/territory.service';
import { CompanyService } from 'src/app/services/resources/company.service';
import { RevenueeService } from 'src/app/services/resources/revenuee.service';
import { EmployeesService } from 'src/app/services/resources/employees.service';

// App Component
import { ProductServicePickerComponent } from '../product-service-picker/product-service-picker.component';

@Component({
    selector: 'widget-search-company',
    templateUrl: './widget-search-company.component.html',
    styleUrls: ['./widget-search-company.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class WidgetSearchCompanyComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input('isInModal') isInModal?: boolean;
    @Output() search: EventEmitter<CompanySearch> = new EventEmitter<CompanySearch>();

    searchForm: FormGroup;
    selectedProductService = [];
    productService$ = new BehaviorSubject<Product[]>([]);
    products$: Observable<Product[]>;
    certifications$: Observable<Certification[]>;
    provinces$: Observable<Province[]>;
    territories$: Observable<Territory[]>;
    employees$: Observable<Employees[]>;
    revenuee$: Observable<Revenuee[]>;

    isExpandCategory: { [id: number]: boolean } = {};
    private stateRecord: Array<ProductService> = [];
    expanded: any;

    get productServices() {
        return this.searchForm.get('productServices') as FormControl;
    }

    private subscription: Subscription = new Subscription();

    constructor(
        private notification: NotificationService,
        private modalService: NgbModal,
        private productService: pService,
        private certificationService: CertificationService,
        private provinceService: ProvinceService,
        private territoryService: TerritoryService,
        private employeesService: EmployeesService,
        private revenueeService: RevenueeService,
        private companyService: CompanyService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        //console.log('WidgetSearchComponent - ngOnInit!!!!');
        this.searchForm = new FormGroup(
            {
                id: new FormControl(null),
                productServices: new FormControl(null),
                keyword: new FormControl(null),
                idProvince: new FormControl(null),
                idRevenue: new FormControl(null),
                idEmployees: new FormControl(null),
                idCertification: new FormControl(null),
                idTerritory: new FormControl(null),
            },
            { updateOn: 'change' }
        );
    }

    ngAfterViewInit(): void {
        //console.log('WidgetSearchComponent - ngAfterViewInit!!!!');
        this.products$ = this.productService.getAll();
        this.certifications$ = this.certificationService.getAll();
        this.provinces$ = this.provinceService.getAll();
        this.territories$ = this.territoryService.getAll();
        this.employees$ = this.employeesService.getAll();
        this.revenuee$ = this.revenueeService.getAll();
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        //console.log('WidgetSearchComponent - ngOnDestroy!!!!');
        this.subscription.unsubscribe();
    }

    onSearch(form: FormGroup) {
        let companySearch: CompanySearch = new CompanySearch(form);
        if (this.selectedProductService.length > 0) companySearch.productServices = this.selectedProductService;

        const sub = this.companyService.searchToScroll(companySearch).subscribe();
        this.subscription.add(sub);
        this.search.emit(companySearch);
    }

    openProductModal() {
        let sub = this.productService.getAll().subscribe((p) => {
            this.productService$.next(p);
            const modalRef = this.modalService.open(ProductServicePickerComponent, { backdrop: 'static', size: 'xl' });
            modalRef.componentInstance.itemSelected = this.selectedProductService;
            modalRef.componentInstance.productService$ = this.productService$;
            let sub = modalRef.componentInstance.passEntry.subscribe((result) => {
                if (result) {
                    this.selectedProductService = result.map((x) => x.id);
                }
            });
            this.subscription.add(sub);
        });
        this.subscription.add(sub);
    }
}
