<div class="container-fluid container-lg mt-5 mb-5">
    <!-- TITOLO -->
    <h1 class="text-heading text-bold text-blue text-bigger mb-3 text-center" data-aos="fade-up">News</h1>

    <!-- TASTI FILTRAGGIO NEWS -->
    <div class="wrapper-newspage text-center mb-4" data-aos="fade-up" data-aos-delay="150" *ngIf="isPrivate">
        <button
            class="btn-filter-news bg-white d-inline-block text-bold text-smaller text-uppercase text-green cursor-pointer py-1 px-2 border-round-pill border-green mr-2"
            type="button"
            (click)="all()"
        >
            Tutte
        </button>
        <button
            class="btn-filter-news bg-white d-inline-block text-bold text-smaller text-uppercase text-green cursor-pointer py-1 px-2 border-round-pill border-green mr-2"
            type="button"
            (click)="fromAssociation()"
        >
            Dall'associazione
        </button>
        <button
            class="btn-filter-news bg-white d-inline-block text-bold text-smaller text-uppercase text-green cursor-pointer py-1 px-2 border-round-pill border-green mr-2"
            type="button"
            (click)="showInputSearch()"
        >
            Cerca per cooperativa
        </button>
        <button
            class="btn-filter-news bg-white d-inline-block text-bold text-smaller text-uppercase text-green cursor-pointer py-1 px-2 border-round-pill border-green mr-2"
            type="button"
            (click)="myNews()"
            *ngIf="isAdmin === false"
        >
            Le mie news
        </button>

        <form class="input-group mb-4 search-input-three-letters mx-auto mt-3" data-aos="fade-up" [formGroup]="form" *ngIf="isShowed">
            <input class="form-control custom-input" formControlName="searchByCompanyName" placeholder="Digita 3 lettere.." />
            <div class="input-group-append custom-label custom-label-dx">
                <button type="submit" (click)="OnSubmit()" *ngIf="isMoreThanThree" class="btn btn-main shadow-default p-0 w-100 h-100">
                    <fa-icon [icon]="['fa', 'search']"></fa-icon>
                </button>
                <fa-icon [icon]="['fa', 'search']" [ngClass]="isMoreThanThree ? 'd-none' : 'd-block'"></fa-icon>
            </div>
        </form>
    </div>

    <!-- LISTA NEWS -->
    <div class="news-results row" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="OnScroll()">
        <!-- SINGOLA NEWS -->
        <div class="col-md-6 col-lg-4 mb-4" data-aos="fade-up" data-aos-delay="250" data-aos-scroll-placement="left-bottom" *ngFor="let n of news; let i = index">
            <a href="{{ n.url }}" target="_blank">
                <div class="single-news d-flex flex-row align-items-center border-round-pill shadow-default angle-bottom-sx h-100">
                    <!-- IMMAGINE NEWS -->
                    <ng-container *ngIf="n.image$ | async as image; else noImage">
                        <div class="news-image h-100" attr-test="{{ n.image }}" [style.background-image]="image"></div>
                    </ng-container>
                    <ng-template #noImage>
                        <div class="news-image h-100" style="background-image: url(/assets/images/no-image-news.svg); background-size: contain"></div>
                    </ng-template>

                    <!-- INFO NEWS -->
                    <div class="news-info h-100 p-2 pb-3 d-flex flex-column justify-content-between">
                        <div>
                            <p class="text-uppercase text-smaller text-green mb-1">{{ n.label }}</p>
                            <div class="news-content">
                                <p class="text-bold text-blue mb-1 line-normal">{{ n.title.substring(0, 40) }}...</p>
                                <p class="text-grey mb-2 line-normal text-smaller">{{ n.summary.substring(0, 110) }}...</p>
                            </div>
                        </div>
                        <div class="news-meta d-flex flex-row align-items-center justify-content-between mt-3">
                            <span class="news-date text-spacing text-smaller text-grey text-bold">{{ n.date | date: 'shortDate' }}</span>
                            <a [href]="n.url" [target]="'_blank'">
                                <button type="button" class="btn btn-main angle-bottom-sx px-2 py-0">
                                    <span class="text-capitalize text-smaller">Leggi</span>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>

    <!-- NESSUN RISULTATO -->
    <div *ngIf="news?.length === 0">
        <h1 class="text-heading text-bold text-blue text-bigger mb-3 text-center" data-aos="fade-up" data-aos-delay="250">La ricerca non ha prodotto risultati.</h1>
    </div>

    <h3 class="text-blue text-heading text-center mt-5">
        Se vuoi sapere <span class="font-underliner"> come inserire le tue offerte</span>, contattaci usando i riferimenti in basso
    </h3>
</div>
