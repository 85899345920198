// @angular
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

// External libs
import { BehaviorSubject, Subscription } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

// App Models
import { Cooperation } from 'src/app/models/cooperation.model';
import { CompanySearch } from 'src/app/models/company-search.model';
import { Company } from 'src/app/models/company.model';
import { Product } from 'src/app/models/product.model';
import { Certification } from 'src/app/models/certification.model';
import { Province } from 'src/app/models/province.model';
import { Territory } from 'src/app/models/territory.model';
import { Employees } from 'src/app/models/employees.model';
import { Revenuee } from 'src/app/models/revenuee.model';

// App Services
import { CooperationService } from 'src/app/services/resources/cooperation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { CompanyService } from 'src/app/services/resources/company.service';
import { FileService } from 'src/app/services/resources/file.service';

@Component({
    selector: 'widget-cooperation-company',
    templateUrl: './widget-cooperation-company.component.html',
    styleUrls: ['./widget-cooperation-company.component.scss'],
})
export class WidgetCooperationCompanyComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input('cooperationInfo') cooperation: Cooperation;
    @Input('cooperationId') cooperationId: string;
    @Input('companies') companies?: Company[];
    @Input('ownedCompany') ownedCompanyId: string;
    @Input() products: Product[];
    @Input() certifications: Certification[];
    @Input() provinces: Province[];
    @Input() territories: Territory[];
    @Input() employees: Employees[];
    @Input() revenuee: Revenuee[];

    addCompany: boolean = true;
    searchCompanies: Company[];

    searchForm: FormGroup;
    selectedProductService = [];
    productService$ = new BehaviorSubject<Product[]>([]);

    private subscription: Subscription = new Subscription();

    constructor(
        private router: Router,
        private activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private notification: NotificationService,
        private cooperationService: CooperationService,
        private companyService: CompanyService,
        private fileService: FileService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        //console.log('WidgetCooperationCompanyComponent - ngOnInit!!!');
        this.searchForm = new FormGroup(
            {
                id: new FormControl(null),
                productServices: new FormControl(null),
                keyword: new FormControl(null),
                idProvince: new FormControl(null),
                idRevenue: new FormControl(null),
                idEmployees: new FormControl(null),
                idCertification: new FormControl(null),
                idTerritory: new FormControl(null),
                selectedCompanies: new FormArray([]),
            },
            { updateOn: 'change' }
        );

        console.log(this.companies);

        if (this.companies && this.companies.length > 0) {
            let array = this.searchForm.get('selectedCompanies') as FormArray;
            this.companies.forEach((c) => {
                array.push(new FormControl(c as Company));
            });
        }
    }

    ngAfterViewInit(): void {
        //console.log('WidgetCooperationCompanyComponent - ngAfterViewInit!!!');
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        //console.log('WidgetCooperationCompanyComponent - ngOnDestroy!!!');
        this.subscription.unsubscribe();
    }

    public closeAction(reason: string) {
        this.activeModal.dismiss(reason);
    }

    showSearch() {
        this.addCompany = !this.addCompany;
    }

    onSearch(criteria: CompanySearch) {
        const sub = this.companyService.searchToScroll(criteria).subscribe((result) => {
            this.searchCompanies = result.filter((c) => c.id != this.ownedCompanyId);
            this.addCompany = true;
        });
        this.subscription.add(sub);
    }

    saveCompanies() {
        let select: Company[] = this.searchForm.get('selectedCompanies').value as Company[];
        console.log(select);
        const cooperationSub = this.cooperationService.updatePartnerCompanies(this.cooperationId, select).subscribe(
            (data) => {
                console.log(data);
                this.addCompany = false;
                this.notification.success('Invitate nuove cooperativa a collaborare.', 'Invito Cooperative');
                this.activeModal.close();
            },
            (response: HttpErrorResponse) => {
                console.log(response);
                this.notification.error(response.error.message, 'Errore aggiornamento collaborazione');
            }
        );
        this.subscription.add(cooperationSub);
    }

    // onDeleted(companyid: string) {
    //     let index = this.companies.findIndex(c => c.id == companyid);
    //     if (index){
    //         this.companies.splice(index,1);
    //     }
    //     this.changeDetectorRef.detectChanges();
    // }

    onCheckboxChange(e, c: Company) {
        const checkArray: FormArray = this.searchForm.get('selectedCompanies') as FormArray;
        if (e.target.checked) {
            checkArray.push(new FormControl(c));
        } else {
            let i: number = 0;
            checkArray.controls.forEach((item: FormControl) => {
                if (item.value.id === c.id) {
                    checkArray.removeAt(i);
                    return;
                }
                i++;
            });
        }
        console.log('array', checkArray);
    }

    isSelected(id: string): boolean {
        return this.companies?.some((sc) => sc.id == id);
    }
}
