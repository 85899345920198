<!-- SLIDER NEWS HOMEPAGE -->
<!--<section id="news"></section>-->
<!--<home-page-news></home-page-news>-->

<!-- RICERCA PRODOTTO O SERVIZIO -->
<!-- <search-widget-company></search-widget-company> -->
<!--<section id="productservices"></section>-->
<!--<widget-search-product (search)="onSearch($event)"></widget-search-product>-->

<!--<product-service-list></product-service-list>-->

<!-- RICERCA SU MAPPA -->
<!--<section id="aroundme"></section>-->
<!--<widget-search-map></widget-search-map>-->

<!--&lt;!&ndash; SEZIONE INIZIATIVA &ndash;&gt;-->
<!--<section id="initiative"></section>-->
<!--<initiative></initiative>-->

<!--&lt;!&ndash; SEZIONE COLLABORAZIONE &ndash;&gt;-->
<!--<section id="network"></section>-->
<!--<network></network>-->

<!-- SEZIONE PRODOTTI PIU VISTI -->

<div class="container">
    <div class="row mt-5 mt-lg-0 mx-3 mx-lg-0">
        <div class="col-12 home_image px-0">
            <img src="assets/images/ImmagineBgBs2023.png" class="w-100">
            <div>
                <h1 class="text-white text-bold ">INTRECCI COOPERATIVI </h1>
            </div>
        </div>
    </div>
    <div class="row my-5 mx-3 mx-lg-0">
        <div class="col-12 px-0">
            <h1 class="text-blue text-bold text-uppercase">
                LA PIATTAFORMA DELLE RETI COOPERATIVE NELLA CORNICE DI BERGAMO-BRESCIA CAPITALE DELLA CULTURA 2023</h1>
        </div>
        <div class="col-12 text-grey px-0">
            <p class="py-4 mb-5">
                Hai un progetto da proporre e stai cercando competenze o spazi per svilupparlo e farlo crescere?
                <br />
                Oppure hai spazi che possono trovare valore grazie a progetti cooperativi?
                <br />
                Sei nel posto giusto! Qui potrai trovare tante idee, soggetti, organizzazioni che potrebbero fare al
                caso tuo!
            </p>
            <h3 class="text-blue text-uppercase"> Senza cooperazione, non si dà cultura.</h3>
            <p> Perché la cultura è gesto comunitario, non individuale. </p>
            <h3 class="text-blue text-uppercase">Senza cultura, la cooperazione perde un frammento d’anima.</h3>
            <p>Perché la cooperazione è gesto di trasformazione (anche) culturale.</p>
            <p class="mt-5">
                <a href="assets/file/Approfondimento.pdf" download>
                    <img src="assets/images/cc-pdf.svg" width="20" class="mr-2">Scarica la Brochure
                </a>
            </p>
            <p>
                <a href="assets/file/BGBS2023-Confcooperative-ManualeOperativo.pdf" download>
                    <img src="assets/images/cc-pdf.svg" width="20" class="mr-2">Scarica il Manuale Operativo
                </a>
            </p>
            <p>
                <a href="assets/file/protocollo.firmato.pdf" download>
                    <img src="assets/images/cc-pdf.svg" width="20" class="mr-2">Scarica il protocollo di collaborazione
                </a>
            </p>
        </div>
        <div class="col-md-12 px-0">
            <widget-login-button></widget-login-button>
        </div>
    </div>
</div>