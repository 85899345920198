// @angular
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

// External libs
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { debounceTime, filter, startWith, switchMap } from 'rxjs/operators';

// App Models
import { Role } from 'src/app/models/role.model';
import { UserRole } from 'src/app/models/user-role.model';
import { UserSearch } from 'src/app/models/user-search.model';
import { User } from 'src/app/models/user.model';
import { Cooperation } from 'src/app/models/cooperation.model';

// App Services
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/resources/user.service';
import { CooperationService } from 'src/app/services/resources/cooperation.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';

@Component({
    selector: 'app-widget-cooperation-authorization',
    templateUrl: './widget-cooperation-authorization.component.html',
    styleUrls: ['./widget-cooperation-authorization.component.scss'],
})
export class WidgetCooperationAuthorizationComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input('cooperationInfo') cooperation: Cooperation;
    @Input('cooperationId') cooperationId: string;
    @Input('users') users: UserRole[];
    @Input('roles') roles: Role[];

    userSearchForm: FormGroup;
    availableRoles$: BehaviorSubject<Role[]> = new BehaviorSubject<Role[]>([]);
    users$: Observable<User[]>;
    availableUsers$: BehaviorSubject<UserRole[]> = new BehaviorSubject<UserRole[]>([]);
    selectedUser: User;

    addUser: boolean = false;

    private subscriptions: Subscription = new Subscription();

    constructor(
        private activeModal: NgbActiveModal,
        private notification: NotificationService,
        private userService: UserService,
        private cooperationService: CooperationService,
        private navigationService: NavigationService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        //console.log('WidgetCooperationAuthorizationComponent - ngOnInit!!!');
        this.userSearchForm = new FormGroup(
            {
                keyword: new FormControl(null),
                roleId: new FormControl(null),
            },
            { updateOn: 'change' }
        );

        this.availableRoles$ = new BehaviorSubject<Role[]>(this.roles);
        this.availableUsers$ = new BehaviorSubject<UserRole[]>(this.users);
    }

    ngAfterViewInit(): void {
        //console.log('WidgetCooperationAuthorizationComponent - ngAfterViewInit!!!');
        const emptyList = of([] as User[]);
        this.users$ = this.userSearchForm.get('keyword').valueChanges.pipe(
            startWith(null),
            debounceTime(300),
            filter((value) => value && value.length > 2 && typeof value === 'string'),
            switchMap((value: string) => {
                if (!value || value.length < 2) return emptyList;
                else {
                    let criteria: UserSearch = new UserSearch();
                    criteria.keyword = value;
                    return this.userService.quickSearch(criteria);
                }
            })
        );
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        //console.log('WidgetCooperationAuthorizationComponent - ngOnDestroy!!!');
        this.subscriptions.unsubscribe();
    }

    public closeAction(reason: string) {
        this.activeModal.dismiss(reason);
    }

    insertUser() {
        if (this.selectedUser) {
            let check = this.users.some((ur) => ur.userId == this.selectedUser.id && ur.roleId == this.userSearchForm.get('roleId').value.id);
            if (check) {
                this.notification.warning('Associazione già presente con il ruolo selezionato', 'Associazione Utente/cooperativa');
            } else {
                let userRole: UserRole = new UserRole(null);
                userRole.role = this.userSearchForm.get('roleId').value;
                userRole.roleId = (this.userSearchForm.get('roleId').value as Role).id;
                userRole.userId = this.selectedUser.id;
                userRole.user = this.selectedUser;

                this.users.push(userRole);
                this.changeDetectorRef.detectChanges();
                this.addUser = false;
            }
        }
        // } else {
        //     let newUser = new User(null);
        //     newUser.id = null;
        //     newUser.name = null;
        //     newUser.surname = null;
        //     newUser.fiscalCode = null;
        //     newUser.username = this.userSearchForm.get('keyword').value;
        //     newUser.email = this.userSearchForm.get('keyword').value;
        //     const userSub = this.userService.create(newUser).subscribe(
        //         (result) => {
        //             let userRole: UserRole = new UserRole(null);
        //             userRole.role = this.userSearchForm.get('roleId').value;
        //             userRole.roleId = (this.userSearchForm.get('roleId').value as Role).id;
        //             userRole.userId = result.id;
        //             userRole.user = result;

        //             let coopUser: CooperationUserRole = new CooperationUserRole();
        //             coopUser.cooperationId = this.cooperationId;
        //             coopUser.userRoleId = userRole.id;
        //             coopUser.userRole = userRole;

        //             this.users.push(coopUser);
        //             this.addUser = false;
        //         },
        //         (response: HttpErrorResponse) => {
        //             this.notification.error(response.error.message, 'Errore creazione utente');
        //         }
        //     );
        //     this.subscriptions.add(userSub);
        // }
        this.userSearchForm.get('keyword').setValue('');
        this.userSearchForm.get('roleId').setValue(null);
    }

    getUser(value: User) {
        this.selectedUser = value;
        this.userSearchForm.get('keyword').setValue(value.email);
    }

    saveUsers() {
        console.log('SaveUsers!!!!');
        const cooperationSub = this.cooperationService.updateUsers(this.cooperationId, this.users).subscribe(
            () => {
                this.notification.success('Autorizzazioni utenti della collaborazione aggiornati correttamente.', 'Aggiornamento Collaborazione');
                this.activeModal.close();
            },
            (response: HttpErrorResponse) => {
                this.notification.error(response.error.message, 'Errore aggiornamento autorizzazioni utenti della collaborazione');
            }
        );
        this.subscriptions.add(cooperationSub);
    }

    onDeleted(id: number) {
        let check = this.users.some((u) => u.id == id);
        if (check) {
            let index = this.users.findIndex((u) => u.id == id);
            this.users.splice(index, 1);
            this.changeDetectorRef.detectChanges();
        }
    }

    showForm() {
        this.addUser = !this.addUser;
    }
}
