// @angular
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

// External libs
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

// App Models
import { Company } from 'src/app/models/company.model';
import { News } from 'src/app/models/news.model';
import { NewsExtended } from 'src/app/models/news-extend.model';

// App Services
import { CompanyService } from 'src/app/services/resources/company.service';
import { NewsService } from 'src/app/services/resources/news.service';
import { FileService } from 'src/app/services/resources/file.service';

@Component({
    selector: 'widget-news',
    templateUrl: './widget-news.component.html',
    styleUrls: ['./widget-news.component.scss'],
})
export class WidgetNewsComponent implements OnInit, OnDestroy {
    @Input('companyInfo') company: Company;
    @Input('companyId') companyId: string;
    @Input('showNumber') showNumber: number;

    news: NewsExtended[];
    showNews: NewsExtended[];

    private subscription: Subscription = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private companyService: CompanyService,
        private newsService: NewsService,
        private fileService: FileService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        console.log('WidgetNewsComponent - ngOnInit!!!');

        if (!this.company) {
            if (this.companyId) {
                const sub = this.companyService
                    .getNews(this.companyId)
                    .pipe(
                        map((list: News[]) => list.map((n) => new NewsExtended(n, this.newsService, this.fileService, this.sanitizer))),
                        map((list: NewsExtended[]) =>
                            list.map((sn) => {
                                if (sn.title.length >= 120) {
                                    sn.title = sn.title.substring(0, 117).concat('...');
                                }
                                if (sn.summary.length >= 150) {
                                    sn.summary = sn.summary.substring(0, 147).concat('...');
                                }
                                return sn;
                            })
                        )
                    )
                    .subscribe((list) => {
                        this.news = list;
                        if (this.showNumber) {
                            this.showNews = this.news.slice(0, this.showNumber);
                        } else {
                            this.showNews = this.news;
                        }
                    });
                this.subscription.add(sub);
            } else {
                const sub = this.newsService
                    .getNews()
                    .pipe(
                        map((list: News[]) => list.map((n) => new NewsExtended(n, this.newsService, this.fileService, this.sanitizer))),
                        map((list: NewsExtended[]) =>
                            list.map((sn) => {
                                if (sn.title.length >= 120) {
                                    sn.title = sn.title.substring(0, 117).concat('...');
                                }
                                if (sn.summary.length >= 150) {
                                    sn.summary = sn.summary.substring(0, 147).concat('...');
                                }
                                return sn;
                            })
                        )
                    )
                    .subscribe((news) => {
                        this.news = news.filter((n) => n.origin == 'home public' || n.origin == 'home private');
                        if (this.showNumber) {
                            this.showNews = news.slice(0, this.showNumber);
                        } else {
                            this.showNews = news;
                        }
                    });
                this.subscription.add(sub);
            }
        }
    }

    ngOnDestroy(): void {
        //console.log('WidgetNewsComponent - ngOnDestroy!!!');
        this.subscription.unsubscribe();
    }

    sanitize(image: string) {
        const imageurlencoded = image.replace(/\(/g, '%28').replace(/\)/g, '%29');
        return this.sanitizer.bypassSecurityTrustStyle(`url(${imageurlencoded})`);
    }
}
