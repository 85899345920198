<div class="container mt-5 mb-5">
    <!-- TITOLO -->
    <h1 class="text-blue text-uppercase text-bold mb-5" *ngIf="!isInModal" data-aos="fade-up" data-aos-once="true">
        Ricerca cooperativa per criteri
    </h1>

    <!-- FORM RICERCA -->
    <form role="form" autocomplete="off" #ricerca id="searchForm" class="row" [formGroup]="searchForm">
        <div class="order-lg-first col-md-6 col-lg-3 mb-3" data-aos="fade-up" data-aos-once="true" data-aos-delay="50">
            <button
                type="button"
                class="btn btn-main shadow-default bg-white text-blue text-left w-100 d-flex flex-row align-items-center justify-content-between"
                (click)="openProductModal()"
            >
                <span>Prodotti e Servizi</span>
                <fa-icon prefix="fa" icon="plus" class="d-inline-block ml-2 text-smaller"></fa-icon>
            </button>
        </div>

        <div class="order-first col-lg-6 mb-3" data-aos="fade-up" data-aos-once="true" data-aos-delay="100">
            <input type="text" id="parola-chiave" formControlName="keyword" class="form-control custom-input" placeholder="Ricerca per parola chiave" />
        </div>

        <div class="col-md-6 col-lg-3 mb-3" data-aos="fade-up" data-aos-once="true" data-aos-delay="150">
            <mat-select id="provincia" formControlName="idProvince" class="form-control custom-input d-flex flex-column justify-content-center text-blue" placeholder="Provincia">
                <mat-option [value]="null">Nessun Filtro</mat-option>
                <mat-option *ngFor="let prov of provinces$ | async" [value]="prov.id">{{ prov.name }}</mat-option>
            </mat-select>
        </div>

        <div class="col-md-6 col-lg-3 mb-3" data-aos="fade-up" data-aos-once="true" data-aos-delay="200">
            <mat-select id="fatturato" formControlName="idRevenue" class="form-control custom-input d-flex flex-column justify-content-center text-blue" placeholder="Fatturato">
                <mat-option [value]="null">Nessun Filtro</mat-option>
                <mat-option *ngFor="let r of revenuee$ | async" [value]="r.id">{{ r.name }}</mat-option>
            </mat-select>
        </div>

        <div class="col-md-6 col-lg-3 mb-3" data-aos="fade-up" data-aos-once="true" data-aos-delay="250">
            <mat-select
                id="numero-addetti"
                formControlName="idEmployees"
                class="form-control custom-input d-flex flex-column justify-content-center text-blue"
                placeholder="Numero Addetti"
            >
                <mat-option [value]="null">Nessun Filtro</mat-option>
                <mat-option *ngFor="let e of employees$ | async" [value]="e.id">{{ e.name }}</mat-option>
            </mat-select>
        </div>

<!--        <div class="col-md-6 col-lg-3 mb-3" data-aos="fade-up" data-aos-once="true" data-aos-delay="300">-->
<!--            <mat-select-->
<!--                id="certificazioni"-->
<!--                formControlName="idCertification"-->
<!--                class="form-control custom-input d-flex flex-column justify-content-center text-blue"-->
<!--                placeholder="Certificazioni"-->
<!--            >-->
<!--                <mat-option [value]="null">Nessun Filtro</mat-option>-->
<!--                <mat-option *ngFor="let cert of certifications$ | async" [value]="cert.id">{{ cert.name }}</mat-option>-->
<!--            </mat-select>-->
<!--        </div>-->

<!--        <div class="col-md-6 col-lg-3 mb-3" data-aos="fade-up" data-aos-once="true" data-aos-delay="350">-->
<!--            <mat-select-->
<!--                id="territori-operativita"-->
<!--                formControlName="idTerritory"-->
<!--                class="form-control custom-input d-flex flex-column justify-content-center text-blue"-->
<!--                placeholder="Territori di operatività"-->
<!--            >-->
<!--                <mat-option [value]="null">Nessun Filtro</mat-option>-->
<!--                <mat-option *ngFor="let ter of territories$ | async" [value]="ter.id">{{ ter.name }}</mat-option>-->
<!--            </mat-select>-->
<!--        </div>-->

        <div class="input-group col-12 mx-auto mb-4 mt-4 pr-3 pr-lg-0" data-aos="fade-up" data-aos-once="true" data-aos-delay="400">
            <button type="button" class="form-control custom-input text-center text-md-left text-lg-left" id="btn-search" (click)="onSearch(searchForm)">
                <span class="text-uppercase text-green text-bold text-smaller">Avvia ricerca</span>
                <div class="input-group-append custom-label custom-label-dx angle-top-sx">
                    <fa-icon icon="search" class="text-white"></fa-icon>
                </div>
            </button>
        </div>
    </form>
</div>
