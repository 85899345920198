// @angular
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';

// External libs
import { Subscription, Observable } from 'rxjs';

// App Helpers
import { environment } from 'src/environments/environment';

// App Models
import { Company } from 'src/app/models/company.model';

// App Services
import { CompanyService } from 'src/app/services/resources/company.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';

@Component({
    selector: 'company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.css'],
})
export class CompanyComponent implements OnInit, OnDestroy {
    ID: string;
    company$: Observable<Company> = null;
    title: string;

    private subscription: Subscription = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private titleService: Title,
        private companyService: CompanyService,
        private navigationService: NavigationService
    ) {}

    ngOnInit() {
        console.log('CompanyComponent - ngOnInit!!!');
        this.navigationService.setShowMenu(true);
        const routeSub = this.route.params.subscribe((params) => {
            this.ID = params.id;
            if (this.ID) {
                this.company$ = this.companyService.getById(this.ID);

                this.titleService.setTitle(`${environment.appName} - Dettaglio Cooperativa ${this.ID}`);
            }
        });
        this.subscription.add(routeSub);

        const companySub = this.companyService.currentEditCompany$.subscribe((company) => {
            if (company && company.id) {
                this.title = `Cooperativa ID. ${company.id} - "${company.name}"`;
            }
        });
        this.subscription.add(companySub);
    }

    ngOnDestroy(): void {
        console.log('CompanyComponent - ngOnDestroy!!!');
        this.subscription.unsubscribe();
    }
}
