// @angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// External libs
import { Observable } from 'rxjs';

// App Models
import { Employees } from 'src/app/models/employees.model';

// App Services
import { BaseService } from './base.service';

@Injectable()
export class EmployeesService extends BaseService {
    constructor(private http: HttpClient) {
        super(http);
    }

    public getAll(): Observable<Employees[]> {
        const url = `${this.baseUrl}employees`;
        return this.http.get<Employees[]>(url);
    }
}
