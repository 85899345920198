<div class="d-block d-lg-none">
    <button
        (click)="toggleAccordionProduct()"
        [ngClass]="{ 'reversed-arrow': !isProdAccordionExpanded }"
        class="btn btn-main btn-toggle-accordion bg-white w-100 mb-3 mx-auto shadow-default d-flex flex-grow-1 flex-row align-items-center justify-content-center"
    >
        <span class="text-uppercase text-blue text-bold" [ngClass]="{ 'd-none': isProdAccordionExpanded }">CHIUDI FILTRI</span>
        <span class="text-uppercase text-blue text-bold" [ngClass]="{ 'd-none': !isProdAccordionExpanded }">APRI FILTRI</span>
        <fa-icon icon="chevron-down" class="text-blue d-inline-block ml-2" prefix="fa"></fa-icon>
    </button>
</div>

<ng-container *ngIf="!isPublic; else publicArea">
    <form [ngClass]="{ 'd-none': isProdAccordionExpanded }" role="form" autocomplete="off" #ricerca id="searchForm" [formGroup]="searchForm">
        <div class="container">
            <div class="row">
                <div class="col-12" data-aos="fade-left">
                    <input type="text" id="parola-chiave" formControlName="keyword" class="form-control custom-input text-blue" placeholder="Ricerca per parola chiave" />
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12" data-aos="fade-left" data-aos-delay="50">
                    <button
                        type="button"
                        class="w-100 d-flex flex-row align-items-center justify-content-between btn btn-main bg-white shadow-default custom-input"
                        (click)="openProductModal()"
                    >
                        <span class="text-blue line-normal">Prodotti e Servizi</span>
                        <fa-icon prefix="fa" icon="plus" class="text-blue"></fa-icon>
                    </button>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12" data-aos="fade-left" data-aos-delay="100">
                    <mat-select
                        id="provincia"
                        formControlName="idProvince"
                        class="form-control custom-input justify-content-center d-flex flex-column text-blue"
                        placeholder="Provincia"
                    >
                        <mat-option [value]="null">Nessun Filtro</mat-option>
                        <mat-option *ngFor="let prov of provinces$ | async" [value]="prov.id">{{ prov.name }}</mat-option>
                    </mat-select>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12" data-aos="fade-left" data-aos-delay="150">
                    <mat-select
                        id="fatturato"
                        formControlName="idRevenue"
                        class="form-control custom-input justify-content-center d-flex flex-column text-blue"
                        placeholder="Fatturato"
                    >
                        <mat-option [value]="null">Nessun Filtro</mat-option>
                        <mat-option *ngFor="let r of revenuee$ | async" [value]="r.id">{{ r.name }}</mat-option>
                    </mat-select>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12" data-aos="fade-left" data-aos-delay="200">
                    <mat-select
                        id="numero-addetti"
                        formControlName="idEmployees"
                        class="form-control custom-input justify-content-center d-flex flex-column text-blue"
                        placeholder="Numero Addetti"
                    >
                        <mat-option [value]="null">Nessun Filtro</mat-option>
                        <mat-option *ngFor="let e of employees$ | async" [value]="e.id">{{ e.name }}</mat-option>
                    </mat-select>
                </div>
            </div>

<!--            <div class="row mt-4">-->
<!--                <div class="col-12" data-aos="fade-left" data-aos-delay="250">-->
<!--                    <mat-select-->
<!--                        id="certificazioni"-->
<!--                        formControlName="idCertification"-->
<!--                        class="form-control custom-input justify-content-center d-flex flex-column text-blue"-->
<!--                        placeholder="Certificazioni"-->
<!--                    >-->
<!--                        <mat-option [value]="null">Nessun Filtro</mat-option>-->
<!--                        <mat-option *ngFor="let cert of certifications$ | async" [value]="cert.id">{{ cert.name }}</mat-option>-->
<!--                    </mat-select>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="row mt-4">-->
<!--                <div class="col-12" data-aos="fade-left" data-aos-delay="300">-->
<!--                    <mat-select-->
<!--                        id="territori-operativita"-->
<!--                        formControlName="idTerritory"-->
<!--                        class="form-control custom-input justify-content-center d-flex flex-column text-blue"-->
<!--                        placeholder="Territori di operatività"-->
<!--                    >-->
<!--                        <mat-option [value]="null">Nessun Filtro</mat-option>-->
<!--                        <mat-option *ngFor="let ter of territories$ | async" [value]="ter.id">{{ ter.name }}</mat-option>-->
<!--                    </mat-select>-->
<!--                </div>-->
<!--            </div>-->

            <div class="row mt-4">
                <div class="col-12 text-center" data-aos="fade-left" data-aos-delay="350">
                    <button type="button" class="btn btn-main angle-bottom-sx shadow-default" id="btn-search" (click)="onSearch(searchForm)">
                        <span class="text-uppercase">Avvia ricerca</span>
                        <fa-icon [icon]="['fas', 'search']" class="ml-3"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </form>
</ng-container>

<ng-template #publicArea>
    <div class="row row-parola-chiave" [ngClass]="{ 'd-none': isProdAccordionExpanded }">
        <div class="col col-12" data-aos="fade-left">
            <input type="text" id="parola-chiave" #keyword [formControl]="keywordCtrl" class="form-control custom-input" placeholder="Ricerca per parola chiave" />
        </div>
    </div>
    <div class="row row-product-wrapper mt-4" [ngClass]="{ 'd-none': isProdAccordionExpanded }">
        <div class="col-12 check-products-wrapper pb-4">
            <ul class="list-unstyled m-0" data-aos="fade-left" data-aos-delay="100">
                <li class="text-white" *ngFor="let item of data.ParentChildchecklist">
                    <div class="d-flex flex-row justify-content-between px-2 pt-2 pb-0">
                        <input
                            type="checkbox"
                            class="d-none check-item-checkbox"
                            [(ngModel)]="item.isSelected"
                            name="list_name"
                            id="id-product-checkbox-{{ item.id }}"
                            value="{{ item.id }}"
                            (ngModelChange)="parentCheck(item)"
                        />
                        <label
                            for="id-product-checkbox-{{ item.id }}"
                            class="check-item-name cursor-pointer position-relative text-uppercase pl-4 text-white text-bold text-uppercase text-bigger"
                        >
                            {{ item.value }}
                        </label>
                        <span (click)="expandCollapse(item)" class="cursor-pointer ml-2">
                            <fa-icon [icon]="['fas', 'caret-down']" *ngIf="item.isClosed; else isCollapsed"></fa-icon>
                            <ng-template #isCollapsed><fa-icon [icon]="['fas', 'caret-up']"></fa-icon></ng-template>
                        </span>
                    </div>

                    <div class="child-list" [hidden]="item.isClosed">
                        <ul class="list-unstyled m-0">
                            <li class="text-white pl-3" *ngFor="let itemChild of item.childList">
                                <input
                                    type="checkbox"
                                    class="d-none check-item-checkbox"
                                    [(ngModel)]="itemChild.isSelected"
                                    name="list_name_child"
                                    value="{{ itemChild.id }}"
                                    id="id-product-checkbox-{{ item.id }}-{{ itemChild.id }}"
                                    (ngModelChange)="childCheck(item, item.childList)"
                                />
                                <label
                                    for="id-product-checkbox-{{ item.id }}-{{ itemChild.id }}"
                                    class="cursor-pointer check-item-name position-relative text-uppercase pl-4 text-white text-bold text-uppercase"
                                    >{{ itemChild.value }}</label
                                >
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</ng-template>
