import { FileInfo } from 'src/app/models/file-info.model';

export class FileNotAllowed {
    file: FileInfo;
    reason: string;

    constructor(file: FileInfo, reason: string) {
        this.file = file;
        this.reason = reason;
    }
}
