// @angular
import { Component, AfterViewInit } from '@angular/core';
import { OnInit } from '@angular/core';

// External libs
import { Observable } from 'rxjs';

// App Services
import { LoadingService } from 'src/app/services/navigation/loading.service';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.css'],
})
export class LoadingComponent implements AfterViewInit {
    debounceTime = 200;
    loading$: Observable<boolean>;

    constructor(private loadingService: LoadingService) {}

    ngAfterViewInit(): void {
        this.loading$ = this.loadingService.loadingStatus.asObservable().pipe(debounceTime(this.debounceTime));
    }
}
