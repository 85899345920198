// @angular
import { Component, Input, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// External libs
import { Subscription } from 'rxjs';

// App Helpers
import { RolesEnum } from 'src/app/helpers/roles.enum';

// App Models
import { Role } from 'src/app/models/role.model';
import { User } from 'src/app/models/user.model';

// App Services
import { AuthService } from 'src/app/services/resources/auth.service';
import { FileService } from 'src/app/services/resources/file.service';
import { UserService } from 'src/app/services/resources/user.service';

@Component({
    selector: 'user-roles-info',
    templateUrl: './user-roles-info.component.html',
    styleUrls: ['./user-roles-info.component.scss'],
})
export class UserRolesInfoComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input('userInfo') user: User;
    @Input('role') role: Role;
    @Input('onlyAvatar') onlyAvatar?: boolean;
    @Output() isCompleted: EventEmitter<any> = new EventEmitter<any>(true);

    thumbnail: any;
    showTrash: boolean = false;

    private subscription: Subscription = new Subscription();

    constructor(
        private fileService: FileService,
        private sanitizer: DomSanitizer,
        private authService: AuthService,
        private userService: UserService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        //console.log('UserRolesInfoComponent - ngOnInit!!!');
        if (this.user) {
            if (this.user.avatarFileId) {
                const imageSub = this.fileService.getImageThumbnail(this.user.avatarFileId).subscribe((data) => {
                    let objUrl = 'data:image/jpeg;base64,' + data;
                    this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(objUrl);
                });
                this.subscription.add(imageSub);
            }
        }

        if (this.userService.currentUser$.getValue()) {
            if (this.isInRole(RolesEnum.Author) || this.isInRole(RolesEnum.Editor) || this.isInRole(RolesEnum.Cooperator) || this.isInRole(RolesEnum.Administrator)) {
                this.showTrash = true;
            }
        } else {
            this.showTrash = false;
        }
    }

    ngAfterViewInit(): void {
        //console.log('UserRolesInfoComponent - ngAfterViewInit!!!');
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        //console.log('UserRolesInfoComponent - ngOnDestroy!!!');
        this.subscription.unsubscribe();
    }

    public isInRole(role: string): boolean {
        let result = false;
        result = this.userService.currentUser$.getValue().roles.some((r) => r.role.name === role);
        return result;
    }
}
