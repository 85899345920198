// @angular
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
    selector: 'not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.css'],
})
export class NotFoundComponent implements OnInit {
    routeParams;

    constructor(private activatedRoute: ActivatedRoute, private location: Location) {}

    ngOnInit() {
        console.log('NotFoundComponent ngOnInit!');
        this.routeParams = this.activatedRoute.snapshot.queryParams;
    }

    goBack() {
        this.location.back();
    }
}
