<div class="modal-header">
    <h2 class="modal-title text-heading text-blue">Prodotti e Servizi disponibili</h2>
    <button type="button" class="close modal-state-close" aria-label="Chiudi" (click)="closeAction('Cross click')">
        <span class="text-grey" aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-3 p-md-2 p-lg-3 modal-product-picker-wrapper">
    <div class="product-list" *ngFor="let product of option; let i = index">
        <mat-checkbox
            class="product-main d-flex flex-row justify-content-start align-items-center mb-2"
            [checked]="allSelected[i]"
            [indeterminate]="someComplete(i)"
            (change)="setAll($event.checked, i)"
        >
            <div class="product-image-wrapper mr-2">
                <img class="d-block" [src]="'/assets/images/products/' + replace(product.name) + '/' + replace(product.name) + '.svg'" [alt]="product.name" />
                <fa-icon icon="check" class="text-success icon-checked"></fa-icon>
            </div>
            <p class="text-blue text-bold text-uppercase text-bigger mb-0">{{ product.name }}</p>
        </mat-checkbox>
        <div class="product-sub-wrapper d-flex flex-column pl-0 pl-lg-4 mb-4">
            <mat-checkbox
                *ngFor="let productService of product.productServices"
                class="product-item product-sub-item"
                [(ngModel)]="productService.selected"
                [checked]=""
                (ngModelChange)="updateAllComplete(i)"
            >
                <div class="product-image-wrapper mr-2">
                    <product-service-image [ps]="productService"></product-service-image>
                    <fa-icon icon="check" class="text-success icon-checked"></fa-icon>
                </div>
                <p class="text-blue text-bold text-uppercase mb-0">{{ productService.name }}</p>
            </mat-checkbox>
        </div>
        <hr />
    </div>
</div>
<div class="modal-footer">
    <div class="d-flex flex-row justify-content-between w-100">
        <button type="button" class="btn btn-main bg-grey shadow-default text-uppercase" aria-label="Chiudi" (click)="closeAction('Cross click')">
            <fa-icon icon="times" class="text-white mr-3"></fa-icon>
            <span class="text-uppercase text-white">Annulla</span>
        </button>
        <button type="button" (click)="select()" class="btn btn-main angle-bottom-sx shadow-default">
            <span class="text-uppercase d-inline-block mr-3">Conferma selezionati</span>
            <fa-icon icon="check"></fa-icon>
        </button>
    </div>
    <!--    <p *ngIf="alert" class="w-100 text-smaller text-danger text-center mt-1 mb-2">-->
    <!--        Scegli almeno un prodotto o un servizio.-->
    <!--    </p>-->
</div>
