// @angular
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

// External Libs
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

// App Models
import { Product } from 'src/app/models/product.model';
import { Province } from 'src/app/models/province.model';
import { Revenuee } from 'src/app/models/revenuee.model';
import { Territory } from 'src/app/models/territory.model';
import { Certification } from 'src/app/models/certification.model';
import { CompanySearch } from 'src/app/models/company-search.model';
import { Employees } from 'src/app/models/employees.model';

// App Services
import { CertificationService } from 'src/app/services/resources/certification.service';
import { EmployeesService } from 'src/app/services/resources/employees.service';
import { ProductService } from 'src/app/services/resources/product.service';
import { ProvinceService } from 'src/app/services/resources/province.service';
import { RevenueeService } from 'src/app/services/resources/revenuee.service';
import { TerritoryService } from 'src/app/services/resources/territory.service';
import { AuthService } from 'src/app/services/resources/auth.service';

// App Component
import { ProductServicePickerComponent } from '../product-service-picker/product-service-picker.component';

@Component({
    selector: 'widget-search-sidebar',
    templateUrl: './widget-search-sidebar.component.html',
    styleUrls: ['./widget-search-sidebar.component.scss'],
})
export class WidgetSearchSidebarComponent implements OnInit, OnDestroy {
    @Input('criteria') criteria: CompanySearch;
    @Output() search: EventEmitter<CompanySearch> = new EventEmitter<CompanySearch>();

    @ViewChild('keyword') keyword: any;
    keywordCtrl = new FormControl(null);

    searchForm: FormGroup;
    selectedProductService = [];
    productService$ = new BehaviorSubject<Product[]>([]);
    isPublic: boolean;
    isProdAccordionExpanded: boolean = true;

    data: any;
    products: Product[] = [];
    allSelected: { [id: number]: boolean } = {};

    products$: Observable<Product[]>;
    // certifications$: Observable<Certification[]>;
    provinces$: Observable<Province[]>;
    // territories$: Observable<Territory[]>;
    employees$: Observable<Employees[]>;
    revenuee$: Observable<Revenuee[]>;

    private subscription: Subscription = new Subscription();

    constructor(
        private modalService: NgbModal,
        private productService: ProductService,
        private certificationService: CertificationService,
        private provinceService: ProvinceService,
        private territoryService: TerritoryService,
        private employeesService: EmployeesService,
        private revenueeService: RevenueeService,
        private authService: AuthService
    ) {
        this.data = {};
        this.data.isAllSelected = false;
        this.data.isAllCollapsed = true;
    }

    ngOnInit() {
        console.log('WidgetSearchSidebarComponent - ngOnInit!!!!');

        this.products$ = this.productService.getAll();
        // this.certifications$ = this.certificationService.getAll();
        this.provinces$ = this.provinceService.getAll();
        // this.territories$ = this.territoryService.getAll();
        this.employees$ = this.employeesService.getAll();
        this.revenuee$ = this.revenueeService.getAll();

        this.searchForm = new FormGroup(
            {
                productServices: new FormControl(null),
                keyword: new FormControl(null),
                idProvince: new FormControl(null),
                idRevenue: new FormControl(null),
                idEmployees: new FormControl(null),
                // idCertification: new FormControl(null),
                // idTerritory: new FormControl(null),
                idProduct: new FormControl(null),
                idProductService: new FormControl(null),
            },
            { updateOn: 'change' }
        );

        let modesite = this.authService.currentMode$?.getValue();
        if (modesite) {
            this.isPublic = modesite == 'public';
        } else {
            this.isPublic = true;
        }

        console.log('criteria sidebar', this.criteria);

        let sub = this.productService.getAll().subscribe((products) => {
            this.productService$.next(products);
            this.products = products;

            this.products.forEach((x, i) => {
                this.allSelected[i] = false;
                x.productServices.forEach((x) => {
                    if (this.criteria) {
                        if (this.criteria.productServices?.includes(x.id)) {
                            x.selected = true;
                        }
                    }
                });
            });

            console.log(this.allSelected);

            this.data.ParentChildchecklist = products.map((p) => {
                return {
                    id: p.id,
                    value: p.name,
                    isSelected: p.productServices.every((ps) => this.criteria?.productServices?.includes(ps.id)),
                    isClosed: true,
                    childList: p.productServices.map((s) => {
                        return {
                            id: s.id,
                            parent_id: p.id,
                            value: s.name,
                            isSelected: !!this.criteria?.productServices?.find((i) => i == s.id),
                        };
                    }),
                };
            });
        });
        this.subscription.add(sub);

        if (this.criteria) {
            this.searchForm.patchValue({
                productServices: this.criteria.productServices,
                keyword: this.criteria.keyword,
                idProvince: this.criteria.provinceId,
                idRevenue: this.criteria.revenueId,
                idEmployees: this.criteria.employeesId,
                // idCertification: this.criteria.certificationId,
                // idTerritory: this.criteria.territoryId,
            });
        }

        sub = this.keywordCtrl.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe((value) => {
            if (value.length >= 3) {
                this.onSearch(this.searchForm);
            }
        });
        this.subscription.add(sub);
    }

    ngOnDestroy(): void {
        console.log('WidgetSearchSidebarComponent - ngOnDestroy!!!!');
        this.subscription.unsubscribe();
    }

    onSearch(form: FormGroup) {
        if (this.isPublic) {
            let selected: any[] = this.data.ParentChildchecklist.map((x) => {
                return x.childList.filter((s) => s.isSelected);
            }).reduce((a, b) => a.concat(b));

            this.criteria.productServices = [];

            selected.forEach((p) => {
                this.criteria.productServices.push(p.id);
            });

            this.criteria.keyword = this.keywordCtrl.value;
            this.search.emit(this.criteria);
        } else {
            let companySearch: CompanySearch = new CompanySearch(form);

            if (this.selectedProductService.length > 0) companySearch.productServices = this.selectedProductService;

            this.search.emit(companySearch);
        }
    }

    openProductModal() {
        const modalRef = this.modalService.open(ProductServicePickerComponent, { backdrop: 'static', size: 'xl' });
        modalRef.componentInstance.itemSelected = this.selectedProductService.map((x) => x.id);
        modalRef.componentInstance.productService$ = this.productService$;
        modalRef.componentInstance.passEntry.subscribe((result) => {
            if (result) {
                this.selectedProductService = result.map((x) => x.id);
            }
        });
    }

    //Click event on parent checkbox
    public parentCheck(parentObj) {
        for (let i = 0; i < parentObj.childList.length; i++) {
            parentObj.childList[i].isSelected = parentObj.isSelected;
        }
        this.onSearch(null);
    }

    //Click event on child checkbox
    public childCheck(parentObj, childObj) {
        parentObj.isSelected = childObj.every((itemChild: any) => {
            return itemChild.isSelected == true;
        });
        this.onSearch(null);
    }

    //Expand/Collapse event on each parent
    public expandCollapse(obj) {
        obj.isClosed = !obj.isClosed;
    }

    toggleAccordionProduct() {
        // console.log(this.isProdAccordionExpanded)
        if (window.innerWidth <= 991) {
            this.isProdAccordionExpanded = !this.isProdAccordionExpanded;
        }
    }
}
