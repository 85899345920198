<div class="modal-header">
    <h1 class="mx-auto text-center text-blue">Ciao, fai la tua <span class="text-lightblue h1">scelta</span>!</h1>
</div>

<div class="modal-body">
    <div class="container mt-3 mb-2" id="company-picker">
        <div class="text-center mb-3">
            <a class="navbar-brand logo-wrapper pt-2 cursor-pointer logobgbs" [routerLink]="['/']"><img
                src="assets/images/LogoBg_bs2022.png" alt="Bergamo Brescia 2022" width="150"/></a>
        </div>
        <div class="row mt-3">
            <p class="text-grey text-uppercase text-bold text-center w-100 mt-4" *ngIf="isAdmin">Accedi come amministratore della piattaforma</p>
            <div
                class="
                    cursor-pointer
                    mb-4
                    single-coop-login-selection
                    input-group
                    mb-3
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-between
                    shadow-default
                    border-round-pill
                    pl-3
                "
                *ngIf="isAdmin"
                (click)="setAdmin()"
                [routerLink]="['/area-riservata/cooperations']"
            >
                <h3 class="name-coop-login text-blue text-uppercase mb-0 pl-2">bgbs2023.confcooperative.it</h3>
                <div class="button-coop-login text-center bg-lightblue border-0 rounded-circle d-flex flex-row align-items-center justify-content-center">
                    <fa-icon icon="chevron-right" prefix="fa" class="d-inline-block text-white line-normal"></fa-icon>
                </div>
            </div>
            <p class="text-grey text-uppercase text-bold text-center w-100 mt-5">Seleziona una Cooperativa</p>
            <p class="company-search-wrapper">
                <label for="company-search">Ricerca rapida</label>
                <input id="company-search" class="form-control custom-input" type="text" (input)="filterCompany($event, 3)" placeholder="Digita almeno 3 caratteri" />
            </p>
            <div class="company-list-wrapper">
                <div
                    class="
                        cursor-pointer
                        mb-4
                        single-coop-login-selection
                        input-group
                        mb-3
                        d-flex
                        flex-row
                        align-items-center
                        justify-content-between
                        shadow-default
                        border-round-pill
                        pl-3
                    "
                    *ngFor="let company of companies"
                    (click)="setCompanyId(company.id)"
                >
                    <h4 class="name-coop-login text-blue text-uppercase mb-0 pl-2">{{ company.name }}</h4>
                    <div class="button-coop-login text-center bg-blue border-0 rounded-circle d-flex flex-row align-items-center justify-content-center">
                        <fa-icon icon="chevron-right" prefix="fa" class="d-inline-block text-white line-normal"></fa-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<loading-spinner [isLoading]="wait"></loading-spinner>
