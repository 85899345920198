// @angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// External libs
import { Observable, of } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';

// App Models
import { Municipality } from 'src/app/models/municipality.model';

// App Services
import { BaseService } from './base.service';

@Injectable()
export class MunicipalityService extends BaseService {
    private municipalities: Observable<Municipality[]> = null;

    constructor(private http: HttpClient) {
        super(http);

        this.getAll();
    }

    public getAll(): Observable<Municipality[]> {
        if (!this.municipalities) {
            this.municipalities = this.http.get<Municipality[]>(this.baseUrl + `municipality`);
        }
        return this.municipalities;
    }

    public getByProvince(provinceID: number): Observable<Municipality[]> {
        if (!provinceID) {
            return of([]);
        }

        return this.municipalities.pipe(map((list) => list.filter((m) => m.provinceId == provinceID)));
    }

    public search(name: string): Observable<Municipality[]> {
        if (!name) {
            return of([]);
        }

        name = name.toLowerCase();
        return this.municipalities.pipe(map((list) => list.filter((m) => m.name.toLowerCase().includes(name))));
    }
}
