<div id="uploader">
    <div class="row">
        <div class="col-12" *ngIf="uploadEnabled">
            <div id="dragNDrop" class="dragNDrop dragNDropBtmPad">
                <div class="position-relative h-100">
                    <div id="afu-dragndrop-box" class="afu-dragndrop-box font-ss" (drop)="drop($event)" (dragover)="allowDrop($event)">
                        <div class="afu-dragndrop-text text-center p-3">
                            <fa-icon icon="plus" class="d-block drag-drop-plus"></fa-icon>
                            <h3 class="mb-0 font-weight-bold">Clicca o trascina qui</h3>
                            <h4 class="mb-0 font-weight-bold">i tuoi file!</h4>
                        </div>

                        <div *ngIf="config.showSelectButton">
                            <label class="custom-file-label" for="files-{{ config.formatsAllowed[0].split('.')[1] }}"></label>
                            <input
                                id="files-{{ config.formatsAllowed[0].split('.')[1] }}"
                                name="files_{{ config.formatsAllowed[0].split('.')[1] }}"
                                type="file"
                                class="custom-file-input d-none"
                                [accept]="acceptedExtensions"
                                [attr.multiple]="config.multiple ? '' : null"
                                (change)="onSelectFiles($event)"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid text-center font-ss mt-4" id="container-uploading-files" *ngIf="selectedFilesLength > 0">
                <p class="mt-4 text-dark">Coda di caricamento...</p>
                <div class="row single-item isLoading" *ngFor="let item of selectedFiles | keyvalue; let i = index">
                    <div class="col-1 col-lg-2 item-format">
                        <fa-icon prefix="far" [icon]="item.value.icon"></fa-icon>
                    </div>
                    <div class="col-6 col-lg-6 text-left text-xs-center text-sm-center item-title">
                        {{ item.value.name }}
                    </div>
                    <div class="col-3 col-lg-3 text-center item-size">
                        {{ item.value.size | filesize }}
                    </div>
                    <div class="col-2 col-lg-1 item-delete text-danger" *ngIf="config.permission.cancelUpload" (click)="removeSelectedFile(item.value)">
                        <span class="trash">
                            <span></span>
                            <i></i>
                        </span>
                    </div>
                </div>
            </div>

            <div class="container-fluid text-center font-ss" id="container-rejected-files" *ngIf="rejectedFilesLength > 0">
                <p class="text-left row text-danger mt-4 mb-1">File scartati:</p>
                <div class="row single-item isRemoved" *ngFor="let item of rejectedFiles | keyvalue; let i = index">
                    <div class="col-1 col-lg-1 item-format"></div>
                    <div class="col-6 col-md-7 col-lg-7 text-left item-title text-smaller">
                        {{ item.value.file.name }}
                    </div>
                    <div class="col-3 col-md-2 col-lg-2 item-size text-grey">
                        {{ item.value.file.size | filesize }}
                    </div>
                </div>
            </div>

            <button type="button" class="btn btn-wave-text mx-auto" [disabled]="disableUploadButton" *ngIf="config.showUploadButton" (click)="upload()">
                <fa-icon prefix="far" icon="cloud-upload-alt" class="mr-3"></fa-icon>
                <span>SALVA</span>
            </button>
        </div>
    </div>

    <div class="container-fluid file-list mt-4" id="container-uploaded-files" *ngIf="uploadedFiles && uploadedFiles.length > 0">
        <p class="row mt-4 text-blue mb-1 pt-2 pb-2">File caricati:</p>
        <div class="row single-item isLoaded" *ngFor="let file of uploadedFiles; let i = index">
            <div class="col-1 col-lg-1 item-format text-grey">
                <fa-icon prefix="far" [icon]="file.icon"></fa-icon>
            </div>
            <div class="col-5 col-md-7 col-lg-7 text-left item-title text-dark">
                {{ file.name }}
            </div>
            <div class="col-3 col-md-2 col-lg-2 item-size text-dark">
                {{ file.size | filesize }}
            </div>
            <div class="col-1 col-md-1 col-lg-1 pl-1 item-download cursor-pointer text-blue" *ngIf="config.permission.download" (click)="download($event, file.ID, file.name)">
                <fa-icon icon="cloud-download-alt"></fa-icon>
            </div>

            <ng-container *ngIf="isAvatar; else noAvatar">
                <div class="col-2 col-lg-1 item-delete text-danger" *ngIf="config.permission.delete" (click)="delete($event, file)">
                    <span class="trash desc-trash">
                        <span></span>
                        <i></i>
                    </span>
                </div>
            </ng-container>
            <ng-template #noAvatar>
                <div class="col-10" *ngIf="config.hasDescription">
                    <textarea
                        id="filedesc-{{ i }}"
                        name="filedesc_{{ i }}"
                        cols="20"
                        rows="2"
                        class="desc-input"
                        placeholder="Descrizione file"
                        (change)="changeFileDescription($event, i)"
                        >{{ filesDescription.length > 0 ? filesDescription[i] : null }}</textarea
                    >
                </div>
                <div class="col-2 col-lg-1 item-delete text-danger" *ngIf="config.permission.delete" (click)="delete($event, file)">
                    <span class="trash desc-trash">
                        <span></span>
                        <i></i>
                    </span>
                </div>
            </ng-template>
        </div>
    </div>
</div>
