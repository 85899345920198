// @angular
import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// External libs
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/share';

// App Models
import { CustomError } from 'src/app/models/custom-error.model';
import { User } from 'src/app/models/user.model';
import { ResetPassword } from 'src/app/models/reset-password.model';
import { UserRole } from 'src/app/models/user-role.model';
import { UserSearch } from 'src/app/models/user-search.model';

// App Services
import { BaseService } from './base.service';
import { RegisterInvitation } from 'src/app/models/register-invitation';

@Injectable()
export class UserService extends BaseService {
    private observableCache: { [id: string]: Observable<User> } = {};
    private userCache: { [id: string]: User } = {};

    @Output() currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

    @Output() public currentEditUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

    constructor(private http: HttpClient) {
        super(http);
    }

    public current(): Observable<User> {
        const url = `${this.baseUrl}user/current`;
        return this.http
            .get<User>(url)
            .map((u) => {
                this.currentUser$.next(u);
                this.userCache[u.id] = u;
                return u;
            })
            .share();
    }

    public quickSearch(criteria: UserSearch): Observable<User[]> {
        const url = `${this.baseUrl}user/quicksearch`;
        return this.http.post<User[]>(url, criteria);
    }

    public getById(id: string): Observable<User> {
        if (this.userCache[id]) {
            return of(this.userCache[id]);
        } else if (this.observableCache[id]) {
            return this.observableCache[id];
        } else {
            this.observableCache[id] = this.fetchById(id);
        }

        return this.observableCache[id];
    }

    public fetchById(id: string): Observable<User> {
        return this.http
            .get<User>(this.baseUrl + `user/${id}`)
            .map((u) => {
                this.userCache[u.id] = u;
                return u;
            })
            .share();
    }

    public register(user: RegisterInvitation): Observable<boolean> {
        return this.http.post<boolean>(this.baseUrl + 'user/registerInvitation', user).pipe(
            tap({
                next: (r: boolean) => {
                    //console.log('UserService register -> tap ', r);
                },
            })
        );
    }

    public create(user: User): Observable<User> {
        console.log(user);
        if (!user || !(user instanceof User)) {
            //return Observable.throwError(new CustomError(400, `Informazioni sull'utente non valide.`));
        }

        return this.http.post<User>(this.baseUrl + 'user/new', user).pipe(
            tap((newUser: User) => {
                this.userCache[newUser.id] = newUser;
                this.currentEditUser$.next(newUser);
            })
        );
    }

    public sendConfirmEmail(userId: string): Observable<void> {
        if (!userId) {
            return Observable.throwError(new CustomError(400, `Riferimento all'Utente non valido.`));
        }

        return this.http.get<void>(this.baseUrl + `user/${userId}/sendconfirm`);
    }

    public confirmEmail(userId: string, code: string): Observable<void> {
        if (!userId) {
            return Observable.throwError(new CustomError(400, `Riferimento all'Utente non valido.`));
        }
        if (!code) {
            return Observable.throwError(new CustomError(400, 'Codice di conferma non valido.'));
        }

        return this.http.post<void>(this.baseUrl + `user/${userId}/confirm`, { code });
    }

    public sendResetPassword(username: string): Observable<void> {
        if (!username) {
            return Observable.throwError(new CustomError(400, `Riferimento all'Utente non valido.`));
        }
        username = encodeURIComponent(username);

        return this.http.get<void>(this.baseUrl + `user/reset/${username}/`);
    }

    public resetPassword(userId: string, model: ResetPassword): Observable<void> {
        if (!userId) {
            return Observable.throwError(new CustomError(400, `Riferimento all'Utente non valido.`));
        }
        if (!model || !(model instanceof ResetPassword)) {
            return Observable.throwError(new CustomError(400, 'Informazioni non valide.'));
        }

        return this.http.post<void>(this.baseUrl + `user/${userId}/reset`, model);
    }

    public forceResetPassword(userId: string): Observable<void> {
        if (!userId) {
            return Observable.throwError(new CustomError(400, `Riferimento all'Utente non valido.`));
        }

        return this.http.get<void>(this.baseUrl + `user/${userId}/forcereset`);
    }

    public update(user: User): Observable<User> {
        if (!user || !(user instanceof User)) {
            return Observable.throwError(new CustomError(400, `Informazioni sull'utente non valide.`));
        }

        console.log('userService update: ', user);
        return this.http.post<User>(this.baseUrl + `user/${user.id}`, user).pipe(
            tap((model) => {
                this.userCache[model.id] = model;

                this.currentEditUser$.next(model);
                const loggedUser = this.currentUser$.getValue();
                if (loggedUser && loggedUser.id === model.id) {
                    this.currentUser$.next(model);
                }
                console.log('UserService update -> tap: ', user, this.currentEditUser$, 'loggedUser: ', loggedUser);
            })
        );
    }

    public delete(id: string) {
        return this.http.delete(this.baseUrl + `user/${id}`).pipe(
            tap(() => {
                delete this.userCache[id];

                this.currentEditUser$.next(null);
                //console.log('UserService delete -> tap: ', id, this.currentEditUser$);
            })
        );
    }

    public getAssociatedCompanies(id: string): Observable<UserRole[]> {
        const url = `${this.baseUrl}user/${id}/associatedcompanies`;
        return this.http.get<UserRole[]>(url);
    }

    public updateAssociatedCompanies(userId: string, userRoles: UserRole[]): Observable<UserRole[]> {
        const url = `${this.baseUrl}user/${userId}/associatedcompanies`;
        return this.http.post<UserRole[]>(url, userRoles);
    }

    public deleteAvatar(userId: string, fileId: number): Observable<void> {
        const url = `${this.baseUrl}user/${userId}/${fileId}`;
        return this.http.get<void>(url);
    }

    public sendChangePassword(username: string): Observable<void> {
        if (!username) {
            return Observable.throwError(new CustomError(400, `Riferimento all'Utente non valido.`));
        }
        username = encodeURIComponent(username);

        return this.http.get<void>(this.baseUrl + `user/changePassword/${username}/`);
    }
}
