<div class="container-fluid">
    <div class="row">
        <div class="container mt-0 pt-5 mb-5 pb-5">
            <div class="row">
                <div class="col-lg-6">
                    <img src="/assets/images/IllustrazioneHome_Iniziativa.svg" class="img-fluid image-description" data-aos="fade-down" alt="Immagine Iniziativa" />
                </div>

                <div class="col-lg-6">
                    <h1 class="text-heading text-bold text-blue text-bigger mt-2 mb-4" data-aos="fade-up" data-aos-delay="200">
                        Un progetto <br />
                        di cooperazione
                    </h1>
                    <p data-aos="fade-up" data-aos-delay="300">
                        INSIEME.coop ha la finalità di promuovere e valorizzare le eccellenze del territorio e prodotti/servizi offerti dalle Cooperative Associate. A tal fine, la
                        piattaforma mira ad incentivare nuovi progetti di filiera e creare una virtual community che aiuti a mantenere salda la rete delle imprese.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
