<!-- TITOLO MODALE -->
<div class="modal-header">
    <h2 class="modal-title text-heading text-blue">Autorizzazioni utenti alla collaborazione</h2>
    <button type="button" class="close modal-state-close modal-close-company" aria-label="Close" (click)="closeAction('Cross click')">
        <span class="text-grey" aria-hidden="true">&times;</span>
    </button>
</div>
<!-- CORPO MODALE -->
<div class="modal-body">
    <!-- TASTO AGGIUNGI -->
    <div class="d-flex flex-row align-items-center justify-content-end">
        <span class="add-element-label text-uppercase text-smaller text-blue d-inline-block mr-2">Aggiungi</span>
        <button type="button" class="btn border-blue bg-white p-0 rounded-circle text-blue btn-add-users" (click)="showForm()">
            <fa-icon icon="plus"></fa-icon>
        </button>
    </div>

    <!-- MODULO PER AGGIUNTA USERS -->
    <div>
        <div *ngIf="addUser" class="d-flex flex-row justify-content-between align-items-center mt-4">
            <h1 class="text-heading text-blue mx-auto mb-3">Aggiungi utente</h1>
            <fa-icon prefix="fa" (click)="addUser = false" icon="times" class="cursor-pointer text-bigger text-grey"></fa-icon>
        </div>
        <form role="form" autocomplete="off" [formGroup]="userSearchForm" *ngIf="addUser" class="add-news-user-role-form w-100">
            <div class="d-flex flex-row align-items-center justify-content-between mt-3">
                <!-- RICERCA UTENTE -->
                <div class="search-user-associate input-group mb-4 position-relative" matAutocompleteOrigin #origin="matAutocompleteOrigin">
                    <div class="input-group-prepend custom-label custom-label-sx">
                        <fa-icon icon="user"></fa-icon>
                    </div>
                    <input
                        type="text"
                        id="input-search-user"
                        formControlName="keyword"
                        class="text-blue form-control custom-input position-relative"
                        placeholder="Ricerca utente"
                        matInput
                        [matAutocomplete]="auto"
                        [matAutocompleteConnectedTo]="origin"
                    />
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="getUser($event.option.value)">
                        <mat-option class="text-blue" *ngFor="let u of users$ | async" [value]="u"> {{ u.fullName }} - {{ u.email }} </mat-option>
                    </mat-autocomplete>
                </div>

                <!-- SELEZIONE RUOLO -->
                <div class="select-role-associate input-group mb-4">
                    <div class="input-group-prepend custom-label custom-label-sx">
                        <fa-icon icon="user-shield"></fa-icon>
                    </div>
                    <mat-select id="ruoli-utente" formControlName="roleId" class="form-control custom-input text-blue pt-2" placeholder="Seleziona il ruolo">
                        <mat-option class="text-blue add-associate-role" *ngFor="let r of availableRoles$ | async" [value]="r">{{ r.name }}</mat-option>
                    </mat-select>
                </div>
            </div>

            <!-- CONFERMA AGGIUNTA -->
            <button type="button" class="btn btn-main angle-bottom-sx shadow-default mb-4 d-block mx-auto" (click)="insertUser()">
                <fa-icon icon="check" class="mr-2"></fa-icon>
                <span class="text-uppercase text-smaller">Conferma</span>
            </button>
        </form>
        <hr class="w-100" />
    </div>

    <!-- LISTA USERS -->
    <div id="users-list" class="d-flex flex-wrap align-items-center justify-content-between flex-row mt-4">
        <div class="single-user input-group mb-3 d-flex flex-row align-items-center justify-content-start border-round-pill pl-0" *ngFor="let u of users">
            <a class="trash-icon btn btn-main bg-transparent d-inline-block px-2 p-0 mt-n3" (click)="onDeleted(u.id)">
                <fa-icon icon="trash" class="text-smaller text-danger"></fa-icon>
            </a>
            <user-roles-info [userInfo]="u.user" [role]="u.role" class="user-info-modal"></user-roles-info>
        </div>
    </div>

    <!-- TASTI -->
    <div class="d-flex flex-row justify-content-between align-items-center mt-4 mb-3">
        <button type="button" aria-label="Close" class="btn btn-main angle-bottom-sx bg-light shadow-default" (click)="closeAction('Cross click')">
            <fa-icon icon="times" class="mr-3"></fa-icon>
            <span class="text-uppercase">Annulla</span>
        </button>
        <button type="button" class="btn btn-main angle-bottom-sx shadow-default" (click)="saveUsers()">
            <fa-icon icon="check" class="mr-3"></fa-icon>
            <span class="text-uppercase">Salva</span>
        </button>
    </div>
</div>
