// @angular
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

// External libs
import {combineLatest, Observable, Subscription} from 'rxjs';
import {filter, map, share} from 'rxjs/operators';

// App Helpers
import {smoothCollapse} from 'src/app/helpers/animations';
import {RolesEnum} from 'src/app/helpers/roles.enum';

// App Models
import {User} from 'src/app/models/user.model';
import {CooperationType} from 'src/app/models/cooperation-type.model';

// App Components
// App Services
import {AuthService} from 'src/app/services/resources/auth.service';
import {NavigationService} from 'src/app/services/navigation/navigation.service';
import {UserService} from 'src/app/services/resources/user.service';
import {CooperationTypeService} from 'src/app/services/resources/cooperation-type.service';
import {CooperationTypeImageEnum} from 'src/app/helpers/cooperation-type.enum';
import {CompanyService} from 'src/app/services/resources/company.service';
import {FileService} from 'src/app/services/resources/file.service';
import {CompanyDetail} from 'src/app/models/company-detail.model';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

export class MenuItem {
    title: string;
    code?: string;
    route: string;
    image?: string;
    fragment?: string;
    roles?: string[];
    children?: Array<MenuItem>;
    clickFn?: any;
    clickFnParams?: string;
    showAdmin?: boolean;
}

@Component({
    selector: 'menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css'],
    animations: [smoothCollapse],
})
export class MenuComponent implements OnInit, OnDestroy {
    collapsed = true;
    isCollapsed = true;
    currentUser$: Observable<User>;
    filteredMenuItems: MenuItem[];
    isPublic = true;
    isAdmin: boolean;
    oldValue: string = 'Scheda Cooperativa';
    triggerTogglerMenu: HTMLElement = document.querySelector('#navbar .navbar-toggler');

    currentUser: User = null;
    cooperationTypeImage = CooperationTypeImageEnum;
    customChildNav: boolean = false;
    wait: boolean = false;

    // Subscriptions
    private subscriptions: Subscription = new Subscription();

    navigations = [
        {
            title: 'Scheda Cooperativa',
            code: 'schedacooperativa',
            route: '',
            roles: [RolesEnum.Administrator, RolesEnum.Editor, RolesEnum.Cooperator, RolesEnum.Associate, RolesEnum.Author],
            children: [],
        },
        {
            title: 'Crea nuova cooperativa',
            code: 'nuovacooperativa',
            route: '/area-riservata/company/create',
            roles: [RolesEnum.Administrator],
            children: [],
        },
        // {
        //     title: 'Campagne',
        //     code: 'campaign',
        //     route: '/area-riservata/campaign',
        //     roles: [RolesEnum.Administrator, RolesEnum.Editor, RolesEnum.Cooperator, RolesEnum.Author, RolesEnum.Associate],
        //     children: [],
        // },
        // {
        //     title: 'Convenzioni',
        //     code: 'convention',
        //     route: '/area-riservata/convention',
        //     roles: [RolesEnum.Administrator, RolesEnum.Editor, RolesEnum.Cooperator, RolesEnum.Author, RolesEnum.Associate],
        //     children: [],
        // },
        {
            title: 'Cerca Cooperativa',
            code: 'collaborazioni',
            route: '/area-riservata/cooperations',
            roles: [RolesEnum.Administrator, RolesEnum.Editor, RolesEnum.Cooperator, RolesEnum.Author, RolesEnum.Associate],
            children: [],
        },
        // {
        //     title: 'News',
        //     code: 'news',
        //     route: '/area-riservata/news',
        //     roles: [RolesEnum.Administrator, RolesEnum.Editor, RolesEnum.Cooperator, RolesEnum.Associate, RolesEnum.Author],
        //     children: [],
        // },
        {
            title: 'Servizi',
            code: 'service',
            route: '',
            roles: [RolesEnum.Administrator, RolesEnum.Editor, RolesEnum.Cooperator, RolesEnum.Associate, RolesEnum.Author],
            children: [
                {
                    title: 'Richiesta Info',
                    code: 'inforequest',
                    route: '/area-riservata/info',
                    roles: [RolesEnum.Editor, RolesEnum.Cooperator, RolesEnum.Associate, RolesEnum.Author],
                }
            //     {
            //         title: 'Richiesta Mascherine',
            //         code: 'masksrequest',
            //         route: '/area-riservata/masks',
            //         roles: [RolesEnum.Administrator, RolesEnum.Editor, RolesEnum.Cooperator, RolesEnum.Associate, RolesEnum.Author],
            //     },
            ],
        },
        {
            title: 'Home',
            route: '/home',
            roles: [],
            children: [],
        },
        {
            title: 'News',
            route: '/news',
            roles: [],
            children: [],
        },
        {
            title: 'Prodotti e Servizi',
            route: '/home',
            fragment: 'productservices',
            roles: [],
            children: [],
        },
        {
            title: 'Intorno a me',
            route: '/home',
            fragment: 'aroundme',
            roles: [],
            children: [],
        },
        {
            title: `L'iniziativa`,
            route: '/home',
            fragment: 'initiative',
            roles: [],
            children: [],
        },
        {
            title: `La Rete`,
            route: '/home',
            fragment: 'network',
            roles: [],
            children: [],
        },
        {
            title: 'Contatti',
            route: '/home',
            fragment: 'contacts',
            roles: [],
            children: [],
        },
    ];

    activeFragment = this.route.fragment.pipe(share());
    constructor(
        public route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        public navigationService: NavigationService,
        private cooperationTypeService: CooperationTypeService,
        private userService: UserService,
        private fileService: FileService,
        private companyService: CompanyService,
        private sanitizer: DomSanitizer
    ) {
        // this.router.events.subscribe((value) => {
        //     if (value instanceof NavigationEnd) {
        //         // this.setCampaignMenu();
        //         // this.setConventionMenu();
        //         // this.setNewsMenu();
        //     }
        // });
    }

    ngOnInit(): void {
        console.log('MenuComponent ngOnInit!');

        let sub = combineLatest([this.userService.currentUser$, this.authService.currentMode$, this.authService.isAdministrator$]).subscribe(([user, mode, checkrole]) => {
            if (user) {
                this.currentUser = user;
            }

            if (user && mode === 'public') {
                this.isPublic = true;
                this.filteredMenuItems = this.navigations.filter((x) => x.roles.length == 0);
            } else if (user && mode === 'private') {
                this.isPublic = false;
                this.filteredMenuItems = [];
                const roles = user.roles || [];
                const roleNames = roles.map((r) => r.role.name);
                this.filteredMenuItems = this.navigations.filter((l) => l.roles.some((rn) => roleNames.some((ru) => rn === ru)));

                if (checkrole == RolesEnum.Administrator) {
                    this.isAdmin = true;
                    console.log('isAdmin:', checkrole);
                    this.filteredMenuItems = this.filteredMenuItems.filter((m) => m.code != 'schedacooperativa' && m.code != 'service');
                } else {
                    this.isAdmin = false;
                }

                const cooperationItem = this.filteredMenuItems.find((x) => x.code === 'collaborazioni');
                if (cooperationItem) {
                    const sub1 = this.cooperationTypeService
                        .getAll()
                        .pipe(
                            map((types: CooperationType[]) =>
                                types.map((t) => {
                                    return {
                                        title: t.name,
                                        route: '/area-riservata/cooperations/search/type/' + t.id,
                                        image: '/assets/images/cooperations/' + this.cooperationTypeImage[t.id] + '.svg',
                                        fragment: null,
                                        roles: [],
                                        children: [],
                                    };
                                })
                            )
                        )
                        .subscribe((types) => {
                            cooperationItem.children = types;
                        });
                    this.subscriptions.add(sub1);
                }
            } else {
                this.isPublic = true;
                this.filteredMenuItems = this.navigations.filter((x) => x.roles.length === 0);
                this.wait = false;
            }
        });
        this.subscriptions.add(sub);

        sub = this.authService.companyId$.subscribe((companyId) => {
            const route = `/area-riservata/company/${companyId}/info`;
            const schedaCooperativaItem = this.navigations.find((x) => x.code === 'schedacooperativa');
            const schedaCooperativaItem2 = this.filteredMenuItems.find((x) => x.code === 'schedacooperativa');
            if (schedaCooperativaItem) {
                schedaCooperativaItem.route = route;
            }
            if (schedaCooperativaItem2) {
                schedaCooperativaItem2.route = route;
            }
        });
        this.subscriptions.add(sub);

        if (this.currentUser) {
            sub = this.authService.companyId$
                .asObservable()
                .pipe(filter((id) => !!id))
                .subscribe((ic) => {
                    this.wait = true;
                    let handler = this.companyService.getByUser(this.currentUser.id);
                    let isAdminRole = this.currentUser.roles.some((ur) => ur.role.name === RolesEnum.Administrator);

                    if (isAdminRole) {
                        handler = this.companyService.getAll();
                    }
                    const cl = combineLatest([handler, this.companyService.getById(ic)]).subscribe(([userCompanies, chosedCompany]) => {
                        if (userCompanies.length > 0) {
                            const companyItem = this.filteredMenuItems.find((x) => x.code === 'schedacooperativa');
                            if (companyItem) {
                                companyItem.route = `/area-riservata/company/${ic}`;
                                companyItem.title = chosedCompany.name;
                                companyItem.children = userCompanies
                                    .map((c) => new CompanyDetail(c, null, this.fileService))
                                    .filter((c, index, self) => index === self.findIndex((r) => r.id === c.id))
                                    .map((c) => {
                                        return {
                                            title: c.name,
                                            route: `/area-riservata/company/${c.id}`,
                                            image: this.getImage(c.logoBase64$.getValue()) as string,
                                            clickFn: this.changeCompanyFn,
                                            clickFnParams: c.id,
                                        };
                                    });
                                this.wait = false;
                            }
                        }
                    });
                    this.subscriptions.add(cl);
                });
            this.subscriptions.add(sub);
        }
    }

    ngOnDestroy(): void {
        console.log('MenuComponent ngOnDestroy!');
        this.subscriptions.unsubscribe();
    }

    searchCompany() {
        this.router.navigate(['/area-riservata/cooperations']);
    }

    smoothScrollMenu(ancora) {
        document.getElementById(ancora)?.scrollIntoView({behavior: 'smooth', block: 'start'});
        if (ancora === 'contacts') {
            setTimeout(() => {
                document.getElementById('contacts').classList.add('focused');
            }, 1000);
            setTimeout(() => {
                document.getElementById('contacts').classList.remove('focused');
            }, 10000);
        }
    }

    onClickChildItem(item: MenuItem) {
        document.getElementById('dropdown-split').classList.remove('show');
        try {
            (<HTMLInputElement> document.getElementById('menu-company-search')).value = '';
        } catch (e) {
        }
        console.log('called onClickChildItem: ', item);
        if (item.clickFn) {
            item.clickFn(this, item.clickFnParams, item);
        }
    }

    changeCompanyFn(cmp: MenuComponent, companyId: string, item: MenuItem) {
        console.log('called changeCompanyFn: ', companyId);
        let schedaItem = cmp?.filteredMenuItems.find((x) => x.code === 'schedacooperativa');
        schedaItem.title = item.title;
        schedaItem.route = item.route;
        cmp?.authService.setCompanyId(companyId);
    }

    navigateTo(event: any, item: MenuItem) {
        if (item) {
            if (item.route) {
                this.router.navigate([item.route]);
            } else {
                this.router.navigate([this.router.url]);
            }
        }
    }

    navigateToPublic() {
        if (window.innerWidth <= 991) {
            this.triggerTogglerMenu = document.querySelector('#navbar .navbar-toggler');
            this.triggerTogglerMenu.click();
        }

        this.authService.setModalitaSito('public');
        this.router.navigate(['/home']);
    }

    navigateToDashboard() {
        this.authService.setModalitaSito('private');
        this.router.navigate([`/area-riservata/dashboard`]);
    }

    routeToCompany() {
        const isAdmin = this.authService.isAdministrator$.getValue();
        const companyId = this.authService.companyId$.getValue();
        if (companyId) {
            this.router.navigate([`/area-riservata/company/${companyId}/info`]);
        } else if (isAdmin) {
            this.router.navigate([`/area-riservata/cooperations`]);
        }
    }

    // setNewsMenu() {
    //     const pageUrl = window.location.href;
    //     const companyUrlId = pageUrl.substr(pageUrl.lastIndexOf('/company/') + 1).split('/')[1];
    //     const companyId = this.authService.companyId$.getValue();
    //     const id = companyId ? companyId : companyUrlId;
    //
    //     const companySub = this.companyService.getById(id).subscribe((company) => {
    //         console.log(company.feedRSS);
    //         if (company.feedRSS == 'self') {
    //             let newsItem = this.navigations.find((x) => x.code === 'news');
    //             newsItem.children = [];
    //             if (id != 'create') {
    //                 this.customChildNav = true;
    //                 const id = companyId ? companyId : companyUrlId;
    //                 const routeNewsCreate = `/area-riservata/company/${id}/news/create`;
    //                 newsItem.children.push({
    //                     title: 'Nuova news',
    //                     code: 'newscreate',
    //                     route: routeNewsCreate,
    //                     roles: [RolesEnum.Administrator, RolesEnum.Editor, RolesEnum.Author],
    //                 });
    //             } else {
    //                 this.customChildNav = false;
    //             }
    //         }
    //     });
    //     this.subscriptions.add(companySub);
    // }

    // setCampaignMenu() {
    //     const campaignItem = this.navigations.find((x) => x.code === 'campaign');
    //     const isAdmin = (this.isAdmin = this.navigationService.isAdministrator());
    //     campaignItem.children = [];
    //     if (isAdmin) {
    //         document.getElementsByClassName('navbar-collapse')[0].setAttribute('style', 'min-width: 110% !important');
    //         this.customChildNav = true;
    //         campaignItem.children.push({
    //             title: 'Nuova Campagna',
    //             code: 'newcampaign',
    //             route: `/area-riservata/campaign/create`,
    //             roles: [RolesEnum.Administrator],
    //         });
    //     } else {
    //         this.customChildNav = false;
    //     }
    // }

    setConventionMenu() {
        const conventionItem = this.navigations.find((x) => x.code === 'convention');
        const isAdmin = (this.isAdmin = this.navigationService.isAdministrator());
        if (conventionItem) {
            conventionItem.children = [];
            if (isAdmin) {
                this.customChildNav = true;
                conventionItem.children.push({
                    title: 'Nuova Convenzione',
                    code: 'newconvention',
                    route: `/area-riservata/convention/create`,
                    roles: [RolesEnum.Administrator],
                });
            } else {
                this.customChildNav = false;
            }
        }
    }

    getImage(url: string): SafeUrl {
        if (url && url != '') {
            return this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + url);
        } else {
            return '';
        }
    }

    editUser() {
        this.collapsed = true;
        this.router.navigate(['/area-riservata/user/' + this.currentUser.id + '/edit']);
    }

    getUserFullName() {
        if (this.currentUser?.displayName) {
            return this.currentUser.displayName;
        } else {
            return this.currentUser.email;
        }
    }

    filterCompany(ev, minlength) {
        const filter = ev.target.value.toUpperCase();
        const els = document.querySelectorAll<HTMLElement>('.menu-company-item');
        if (filter.length >= minlength || filter == '') {
            for (let i = 0; i < els.length; i++) {
                let name = els[i].getElementsByTagName('span')[0].innerHTML;
                if (name.toUpperCase().indexOf(filter) !== -1) {
                    els[i].classList.remove('hide');
                } else {
                    els[i].classList.add('hide');
                }
            }
        }
    }
}
