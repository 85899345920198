// @angular
import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// External libs
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

// App Model
import { CampaignMasks } from 'src/app/models/campaign-masks.model';
import { User } from 'src/app/models/user.model';
import { CustomError } from 'src/app/models/custom-error.model';
import { UserRole } from 'src/app/models/user-role.model';

// App Services
import { BaseService } from './base.service';

@Injectable()
export class CampaignMasksService extends BaseService {
    private campaignMasksCache: { [id: string]: CampaignMasks } = {};

    @Output() currentEditCampaignMasks$: BehaviorSubject<CampaignMasks> = new BehaviorSubject<CampaignMasks>(null);

    constructor(private http: HttpClient) {
        super(http);
    }

    public getAll(): Observable<CampaignMasks[]> {
        const url = `${this.baseUrl}campaign/masks`;
        return this.http.get<CampaignMasks[]>(url);
    }

    public getById(id: string): Observable<CampaignMasks> {
        const url = `${this.baseUrl}campaign/masks/${id}`;
        return this.http.get<CampaignMasks>(url).pipe(
            tap((c) => {
                this.currentEditCampaignMasks$.next(c);
            })
        );
    }

    public fetchById(id: string): Observable<CampaignMasks> {
        const url = `${this.baseUrl}campaign/masks/${id}`;
        return this.http
            .get<CampaignMasks>(url)
            .map((c) => {
                this.currentEditCampaignMasks$.next(c);
                this.campaignMasksCache[c.id] = c;
                return c;
            })
            .share();
    }

    public getByCompany(companyId: string): Observable<CampaignMasks[]> {
        const url = `${this.baseUrl}campaign/masks/company/${companyId}`;
        return this.http.get<CampaignMasks[]>(url);
    }
    /*
    public getByUser(userid: string): Observable<CampaignMasks[]> {
        const url = `${this.baseUrl}campaign/masks/user/${userid}`;
        return this.http.get<CampaignMasks[]>(url);
    }
    */
    public update(campaign: CampaignMasks): Observable<CampaignMasks> {
        if (!campaign) {
            throw Observable.throw('Informazioni sulla campagna non valide');
        }
        const url = `${this.baseUrl}campaign/masks/update`;
        return this.http.post<CampaignMasks>(url, campaign);
    }

    public create(campaign: CampaignMasks): Observable<CampaignMasks> {
        if (!campaign) {
            throw Observable.throw('Informazioni sulla campagna non valide');
        }
        const url = `${this.baseUrl}campaign/masks/create`;
        return this.http.post<CampaignMasks>(url, campaign);
    }

    public delete(id: string): Observable<boolean> {
        if (!id) {
            throw Observable.throw('ID della campagna non valido');
        }
        const url = `${this.baseUrl}campaign/masks/delete/${id}`;        
        return this.http.get<boolean>(url);
    }
}
