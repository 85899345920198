//@angular
import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';

// External libs
import { Subscription, Subject, Observable, of } from 'rxjs';

// App Models
import { Certification } from 'src/app/models/certification.model';
import { CompanyAddress } from 'src/app/models/company-address.model';
import { Company } from 'src/app/models/company.model';
import { News } from 'src/app/models/news.model';
import { Tag } from 'src/app/models/tag.model';
import { Territory } from 'src/app/models/territory.model';
import { User } from 'src/app/models/user.model';
import { Consortium } from 'src/app/models/consortium.model';

// App Services
import { CompanyService } from 'src/app/services/resources/company.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { FileService } from 'src/app/services/resources/file.service';
import { UserService } from 'src/app/services/resources/user.service';
import { AuthService } from 'src/app/services/resources/auth.service';
import { RolesEnum } from 'src/app/helpers/roles.enum';

@Component({
    selector: 'company-detail',
    templateUrl: './company-detail.component.html',
    styleUrls: ['./company-detail.component.css'],
    animations: [
        trigger('expandCollapse', [
            state('open', style({ display: 'block', opacity: 1 })),
            state('closed', style({ display: 'none', opacity: 0 })),
            transition('open => closed', [animate('300ms ease-in')]),
            transition('closed => open', [animate('300ms ease-out')]),
        ]),
    ],
})
export class CompanyDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() isOtherInfoOpen = false;
    ID: string;
    company$: Observable<Company> = null;
    mainAddress: CompanyAddress;
    territories: Territory[];
    addresses: CompanyAddress[];
    tags: Tag[];
    news: News[];
    certifications: Certification[];
    consortia: Consortium[];
    isEditable$: Subject<Boolean> = new Subject();
    isEditable: Boolean;
    user: User;
    isPublic: boolean = false;

    thumbnail: any;
    thumbnailUser: any;

    private subscription: Subscription = new Subscription();
    sanitizedDescription: SafeHtml;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private navigationService: NavigationService,
        private companyService: CompanyService,
        private authService: AuthService,
        private userService: UserService,
        private fileService: FileService,
        private sanitizer: DomSanitizer,
        private location: Location,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        console.log('CompanyDetailComponent - ngOnInit!!!');
        const sub = this.companyService.currentEditCompany$.subscribe((company) => {
            this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(company.description);
            this.company$ = of(company);

            if (company) {
                this.ID = company.id;

                if (company.logoFileId) {
                    const fileSub = this.fileService.getImageThumbnail(company.logoFileId).subscribe((data) => {
                        let objURl = 'data:image/jpeg;base64,' + data;
                        this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(objURl);
                    });
                    this.subscription.add(fileSub);
                }

                let modesite = this.authService.currentMode$?.getValue();

                if (modesite) {
                    this.isPublic = modesite == 'public';
                } else {
                    this.isPublic = true;
                }

                const userSub = this.userService.currentUser$.asObservable().subscribe((user) => {
                    if (user) {
                        this.user = user;
                        if (user.avatarFileId) {
                            const fileSub = this.fileService.getImageThumbnail(user.avatarFileId).subscribe((data) => {
                                let objURl = 'data:image/jpeg;base64,' + data;
                                this.thumbnailUser = this.sanitizer.bypassSecurityTrustUrl(objURl);
                            });
                            this.subscription.add(fileSub);
                        }

                        const isCompanyEdit =
                            this.user.roles.find((r) => r.role.name == RolesEnum.Administrator) != null ||
                            this.user.roles.some(
                                (r) =>
                                    r.companies.some((c) => c == company.id) &&
                                    (r.role.name === RolesEnum.Author || r.role.name === RolesEnum.Cooperator || r.role.name === RolesEnum.Editor)
                            );
                        if (isCompanyEdit) this.isEditable = isCompanyEdit;
                        else this.isEditable = false;
                    }
                });
                this.subscription.add(userSub);
            }
        });
        this.subscription.add(sub);

        const addressSub = this.companyService.getAddresses(this.ID).subscribe((data) => {
            this.mainAddress = data.find((a) => a.isMain);
            this.addresses = data;
        });
        this.subscription.add(addressSub);

        const tagSub = this.companyService.getTags(this.ID).subscribe((data) => (this.tags = data));
        this.subscription.add(tagSub);
    }

    ngAfterViewInit(): void {
        console.log('CompanyDetailComponent - ngAfterViewInit!!!');
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        console.log('CompanyDetailComponent - ngOnDestroy!!!');
        this.subscription.unsubscribe();
    }

    goBack() {
        this.location.back();
    }

    editCompany() {
        this.router.navigate(['/area-riservata/company', this.ID, 'edit']);
    }

    renderHTML(testo: string) {
        return this.sanitizer.bypassSecurityTrustHtml(testo);
    }

    getImage(avatar: Observable<string>): SafeUrl {
        let result: SafeUrl = null;
        let objURl = 'data:image/jpeg;base64,' + avatar;
        result = this.sanitizer.bypassSecurityTrustUrl(objURl);
        console.log('Stringa Image:', result);
        return result;
    }

    downloadFile($event: MouseEvent, id: number, name: string) {
        $event.stopImmediatePropagation();
        this.fileService.download(id, name);
    }

    goToWebSite(website: string): string {
        let url: string = '';
        if (!/^http[s]?:\/\//.test(website)) {
            url += 'http://';
        }
        return (url += website);
    }

    goToSendMail() {
        this.router.navigate(['/area-riservata/company', this.ID, 'contact']);
    }
}
