// @angular
import { Component, Input, OnInit } from '@angular/core';

// App Models
import { ProductService, ProductServicePathsEnum } from 'src/app/models/product-service.model';

@Component({
    selector: 'product-service-image',
    templateUrl: './product-service-image.component.html',
    styleUrls: ['./product-service-image.component.css'],
})
export class ProductServiceImageComponent implements OnInit {
    @Input() ps: ProductService;

    constructor() {}

    ngOnInit(): void {}

    get enumImage() {
        return ProductServicePathsEnum;
    }

    getUrl(): string {
        return (
           this.enumImage[this.ps.id]
        );
    }
}
