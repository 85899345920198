<ng-container *ngIf="company || companyId; else elseTemplate">
    <div id="prodotti-piu-visti" class="container mt-5 mb-5">
        <h2 class="text-blue mb-4 ml-n3" data-aos="fade-up" data-aos-delay="150">SERVIZI E PRODOTTI OFFERTI</h2>

        <div class="mt-n3 mb-5 row" *ngIf="editMode">
            <button type="button" class="btn btn-main angle-top-sx shadow-default px-3" (click)="openProductModal()" data-aos="fade-up" data-aos-delay="250">
                <span class="text-uppercase"> GESTISCI PRODOTTI E SERVIZI </span>
            </button>
        </div>

        <div class="services-wrapper d-flex flex-column flex-md-row justify-content-between mx-2">
            <ng-container *ngFor="let ps of productservices">
                <div class="cursor-pointer shadow-default text-center " data-aos="fade-up" data-aos-delay="100">
                    <a (click)="searchProductService(ps.id)" class="d-flex flex-column h-100 justify-content-evenly px-2">
                        <product-service-image [ps]="ps"></product-service-image>
                        <p class="text-blue text-uppercase text-center mb-0">{{ ps.name }}</p>
                    </a>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-template #elseTemplate></ng-template>
