// @angular
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

// External libs
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

// App Models
import { Product } from 'src/app/models/product.model';

// App Services
import { ProductService as pService } from 'src/app/services/resources/product.service';

@Component({
    selector: 'product-picker',
    templateUrl: './product-service-picker.component.html',
    styleUrls: ['./product-service-picker.component.css'],
})
export class ProductServicePickerComponent implements OnInit, OnDestroy {
    public option: Product[] = [];
    private Subscription = new Subscription();
    allSelected: { [id: number]: boolean } = {};
    @Input() itemSelected: number[] = [];
    @Input('productService$') productService$: BehaviorSubject<Product[]>;
    @Output() selected$: Subject<number[]> = new Subject<number[]>();
    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    public selected = [];
    alert = false;

    constructor(private activeModal: NgbActiveModal, public productService: pService) {}

    ngOnInit(): void {
        //console.log('ProductServicePickerComponent - ngOnInit!!');

        const sub = this.productService$.asObservable().subscribe((prd) => {
            this.option = prd;
            this.option.forEach((x, i) => {
                this.allSelected[i] = false;
                x.productServices.forEach((x) => {
                    if (this.itemSelected.includes(x.id)) {
                        x.selected = true;
                    }
                });
            });
        });

        this.Subscription.add(sub);
    }

    ngOnDestroy(): void {
        //console.log('ProductServicePickerComponent - ngOnDestroy!!');
        this.Subscription.unsubscribe();
    }

    someComplete(id: number) {
        if (this.option[id].productServices == null) {
            return false;
        }

        return this.option[id].productServices.filter((t) => t.selected).length > 0 && !this.allSelected[id];
    }

    setAll(selected: boolean, id: number) {
        this.allSelected[id] = selected;
        if (this.option[id].productServices == null) {
            return;
        }

        this.option[id].productServices.forEach((t) => (t.selected = selected));
    }

    updateAllComplete(id: number) {
        this.allSelected[id] = this.option[id].productServices != null && this.option[id].productServices.every((t) => t.selected);
    }

    select() {
        this.selected = this.option
            .map((x) => {
                return x.productServices.filter((ps) => ps.selected);
            })
            .reduce((a, b) => a.concat(b));
        console.log(this.selected);
        if (this.selected.length === 0) {
            this.passEntry.emit(this.selected);
            this.activeModal.close(this.selected);
        } else {
            this.passEntry.emit(this.selected);
            this.activeModal.close(this.selected);
        }
    }

    public closeAction(reason: string) {
        this.activeModal.dismiss(reason);
    }

    replace(name: string) {
        name = name
            .toLowerCase()
            .replace(/\s/g, '-')
            .replace(/[/] = !toggleNam/g, '-')
            .replace(/[à]/, 'a');
        return name;
    }
}
