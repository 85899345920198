import { BehaviorSubject, Subscription } from 'rxjs';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/resources/auth.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { UserService } from 'src/app/services/resources/user.service';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, AfterViewInit, OnDestroy {
    isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isLoggedIn: boolean;

    private subscription = new Subscription();
    isIE: boolean;

    constructor(
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private authService: AuthService,
        private userService: UserService,
        private navigationService: NavigationService
    ) {}

    ngOnInit() {
        console.log('FooterComponent - ngOnInit!!!');
        const sub = this.userService.currentUser$.asObservable().subscribe((u) => {
            if (u) {
                this.isLoggedIn = true;
            }
        });
        this.subscription.add(sub);

        // check if user navigates with Internet Explorer in order to show the banner in the footer
        this.isIE = navigator.userAgent.indexOf('MSIE') != -1;
    }

    ngAfterViewInit(): void {
        console.log('FooterComponent - ngAfterViewInit!!!');
        const sub = this.userService.currentUser$.asObservable().subscribe((u) => {
            if (u) {
                this.isLoggedIn = true;
            }
        });
        this.subscription.add(sub);
    }

    ngOnDestroy(): void {
        console.log('FooterComponent - ngOnDestroy!!!');
        this.subscription.unsubscribe();
    }

    logOut() {
        this.authService.logout();
        this.isLoggedIn$.next(this.authService.isLoggedIn);
        // this.navigationService.init();
        this.changeDetectorRef.detectChanges();
        this.router.navigate(['/']);
    }

    smoothScrollFooter(ancoraFooter) {
        this.authService.setModalitaSito('public');
        this.authService.currentMode$.next('public');
        document.getElementById(ancoraFooter).scrollIntoView({ behavior: 'smooth', block: 'start' });
        if (ancoraFooter == 'contacts') {
            document.getElementById('contacts').classList.add('focused');
            setTimeout(function () {
                document.getElementById('contacts').classList.remove('focused');
            }, 10000);
        }
    }
}
